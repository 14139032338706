import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { Redirect } from "react-router-dom";

let addAdditionalHR = [];
var propID;
var typeHeadList;
var currentState;
class HouseRules extends Component {
  state = {
    houseRules: [],
    houseRuleAdditionals: [],
    houseRuleAdditionals2: [],
    houseRuleGuests: [],
    visible: false,
    defaultExplain: "",
    loading: true,
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
  };
  componentDidMount() {
    currentState = this;
    propID = this.state.property_id;
    this.getHouseRule();
    this.getGuestHouseRule();
  }
  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("propDetails"));
    console.log("cookies", resp);
    if (resp && resp["houseRules"]) {
      let propertyHouseRuleList = resp["houseRules"]["propertyHouseRuleList"];
      let propertyHouseRuleDetailsList =
        resp["houseRules"]["propertyHouseRuleDetailsList"];
      let propertyHouseRuleAdditionalList =
        resp["houseRules"]["propertyHouseRuleAdditionalList"];
      if (propertyHouseRuleList && propertyHouseRuleList.length > 0) {
        this.setHouseRule(propertyHouseRuleList);
      }
      if (
        propertyHouseRuleAdditionalList &&
        propertyHouseRuleAdditionalList.length
      ) {
        this.setState({
          houseRuleAdditionals2: propertyHouseRuleAdditionalList,
        });
        // let dataPush = [];
        // if (propertyHouseRuleAdditionalList.length > 0) {
        //   for (let key in propertyHouseRuleAdditionalList) {
        //     dataPush.push({
        //       customOption: true,
        //       id: propertyHouseRuleAdditionalList[key]["id"],
        //       label: propertyHouseRuleAdditionalList[key]["name"],
        //     });
        //   }
        // }
        // _this.setState({
        //   houseRuleAdditionals: dataPush,
        // });
        // let pHouseAdditional = [];
        // for (let key in propertyHouseRuleAdditionalList) {
        //   // this.addAdditional(
        //   //   propertyHouseRuleAdditionalList[key]["ruleName"]
        //   // );
        // }
      }

      if (propertyHouseRuleDetailsList && propertyHouseRuleDetailsList.length) {
        this.setHouseRuleDetails(propertyHouseRuleDetailsList);
      }
      // this.setState({loading:false})
    }
  }
  setHouseRule(propertyHouseRuleList) {
    console.log("set house Rule method", propertyHouseRuleList);
    this.setState((state) => {
      state.houseRules.map((item, j) => {
        for (var i = 0; i < propertyHouseRuleList.length; i++) {
          if (propertyHouseRuleList[i].applicable) {
            var targetId = propertyHouseRuleList[i].houseRule.id;
            console.log("if called", targetId);
            if (targetId === item.id) {
              item.applicable = true;
            }
            // else if (targetId === Number(item.id + "" + j)) {
            //   item.applicable = true;
            // }
          } else {
            item.applicable = false;
          }
        }
      });
    });
    console.log("after getting edited", this.state.houseRules);

    // for (var i = 0; i < propertyHouseRuleList.length; i++) {
    //   if (propertyHouseRuleList[i].applicable) {
    //     var targetId = propertyHouseRuleList[i].id;

    //     let stateHouseRules = this.state.houseRules;
    //     for(var j = 0; j<stateHouseRules.length; j++){
    //       if(targetId === stateHouseRules[j].id){
    //         this.setState({
    //           stateHouseRules[j].applicable = true;
    //         })
    //       }
    //     }

    //   }
    // }
    // let phouseRules = [];
    // for (let key in propertyHouseRuleList) {
    //   let hr = propertyHouseRuleList[key];
    //   phouseRules[hr["houseRule"]["id"]] = {
    //     explanation: hr["explanation"],
    //     applicable: hr["applicable"],
    //   };
    // }
    // let houseRules = propertyHouseRuleList;
    // console.log("let assigned", houseRules);
    // if (houseRules.length > 0) {
    //   console.log("entered if condtn");
    //   let _houseRules = [];
    //   for (let key in houseRules) {
    //     let id = houseRules[key]["houseRule"]["id"];
    //     console.log("let id", id);
    //     if (houseRules[id]) {
    //       _houseRules.push({
    //         id: houseRules[key]["houseRule"]["id"],
    //         name: houseRules[key]["houseRule"]["name"],
    //         ruleDetails: houseRules[key]["houseRule"]["ruleDetails"],
    //         integrationSignAllow: houseRules[key]["houseRule"]["integrationSignAllow"],
    //         explanationRequire: houseRules[key]["houseRule"]["explanationRequire"],
    //         explanationOk: houseRules[id]["explanation"],
    //         popoverVisible: false,
    //         explanation: "",
    //         applicable: houseRules[id]["applicable"],
    //         isEdit: true,
    //       });
    //     } else {
    //       _houseRules.push(houseRules[key]);
    //     }
    //   }
    //   this.setState({
    //     houseRules: _houseRules,
    //   });
    //   console.log("editedRule", this.state.houseRules);
    // }
  }
  setHouseRuleDetails(propertyHouseRuleDetailsList) {
    let hrDetails = [];
    for (let key in propertyHouseRuleDetailsList) {
      let hrd = propertyHouseRuleDetailsList[key];
      hrDetails[hrd["houseRuleDetails"]["id"]] = {
        name: hrd["houseRuleDetails"]["name"],
        description: hrd["description"],
      };
    }
    let houseRuleGuests = this.state.houseRuleGuests;
    if (houseRuleGuests && houseRuleGuests.length > 0) {
      let _houseRuleGuests = [];
      for (let key in houseRuleGuests) {
        let id = houseRuleGuests[key]["id"];
        if (hrDetails[id]) {
          _houseRuleGuests.push({
            id: id,
            name: hrDetails[id]["name"],
            description: hrDetails[id]["description"],
            isChecked: true,
          });
        } else {
          _houseRuleGuests.push(houseRuleGuests[key]);
        }
      }
      this.setState({
        houseRuleGuests: _houseRuleGuests,
      });
    }
  }
  getHouseRule() {
    let _this = this;
    apiService
      .getHouseRuleList()
      .then((response) => {
        if (response && response.status === 200) {
          console.log(response);
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
                ruleDetails: dataList[key]["ruleDetails"],
                integrationSignAllow: dataList[key]["integrationSignAllow"],
                explanationRequire: dataList[key]["explanationRequire"],
                applicable: dataList[key]["applicable"],
                explanation: "",
                explanationOk: "",
                popoverVisible: false,
                isEdit: false,
                isSelected: 0,
              });
            }
          }
          _this.setState({
            houseRules: dataPush,
          });
          if (this.state.property_id !== undefined) {
            this.getEditData();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getGuestHouseRule() {
    let _this = this;
    apiService
      .getHouseRuleDetails()
      .then((response) => {
        if (response && response.status === 200) {
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
                description: "",
                isChecked: false,
              });
            }
          }
          _this.setState({
            houseRuleGuests: dataPush,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  addAdditional = (val2) => {
    let val = document.getElementById("additionalPolicyID").value;
    if (val) {
      this.state.houseRuleAdditionals.push(val);
      this.setState({
        houseRuleAdditionals: this.state.houseRuleAdditionals,
      });

      console.log(this.state.houseRuleAdditionals);
    }
  };
  onChangeGuest = (key) => (e) => {
    let checked = e.target.checked;
    this.state.houseRuleGuests[key]["isChecked"] = checked;
    this.setState({
      houseRuleGuests: this.state.houseRuleGuests,
    });

    // var targetId = Number(e.target.id);
    // var targetValue = e.target.value;

    // if(checked){
    //   this.setState((state) => {
    //     const list = state.houseRuleGuests.map((item, j) => {
    //       if (targetId === item.id) {
    //         item.isChecked = true
    //       }
    //     });
    //   });
    // }

    console.log("guest rule", this.state.houseRuleGuests);
  };

  onChangeGuestDes = (key) => (e) => {
    e.persist();
    let val = e.target.value;
    if (val) {
      this.state.houseRuleGuests[key]["description"] = val;
      this.setState({
        houseRuleGuests: this.state.houseRuleGuests,
      });
    }
    console.log("guest rule", this.state.houseRuleGuests);
  };

  setAction = (event) => {
    let _this = this;
    var targetId = Number(event.target.id);
    var targetValue = event.target.value;

    this.setState((state) => {
      const list = state.houseRules.map((item, j) => {
        // console.log("sample", targetId);
        // console.log("itemid", item.id + "" +j);
        if (targetId === item.id) {
          // item.value = item.value + 1;
          // document.getElementById(item.id).value = item.value;
          if (targetValue === "YES") {
            item.isSelected = 1;
          } else {
            item.isSelected = 0;
          }
        } else if (targetId === Number(item.id + "" + j)) {
          if (targetValue === "YES") {
            item.isSelected = 1;
          } else {
            item.isSelected = 0;
          }
        }
      });
    });

    console.log("houserule", this.state.houseRules);
  };

  setAdditional(selectedOptions) {
    console.log("label", selectedOptions);
    typeHeadList = selectedOptions;
    

    let dataPush = [];
    if (typeHeadList.length > 0) {
      for (let key in typeHeadList) {
        dataPush.push({
          ruleName: typeHeadList[key]["label"],
        });
      }
    }
    currentState.setState({
      houseRuleAdditionals2: dataPush,
    });

    // console.log("typeH", typeHeadList);
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    let _this = this;
    let property_id = this.state.property_id;
    let url = "properties/" + property_id + "/house-rules";

    let addAdditionalHR = [];
    let houseRuleAdditionals = typeHeadList; //this.state.houseRuleAdditionals;

    if (houseRuleAdditionals.length) {
      for (var i = 0; i < houseRuleAdditionals.length; i++) {
        addAdditionalHR.push({
          property_id: property_id,
          ruleName: houseRuleAdditionals[i].label,
        });
      }
    }

    let detailsHR = [];
    let houseRuleGuests = this.state.houseRuleGuests;
    if (houseRuleGuests.length) {
      for (let key in houseRuleGuests) {
        if (houseRuleGuests[key]["isChecked"]) {
          detailsHR.push({
            description: houseRuleGuests[key]["name"],
            house_rule_details_id: houseRuleGuests[key]["id"],
            property_id: property_id,
          });
        }
      }
    }

    let hr = [];
    let houseRules = this.state.houseRules;
    console.log("houseR", houseRules);
    if (houseRules.length) {
      for (let key in houseRules) {
        if (houseRules[key]["isSelected"] === 1) {
          hr.push({
            applicable: true,
            explanation: houseRules[key]["explanationOk"],
            house_rule_id: houseRules[key]["id"],
            property_id: property_id,
          });
        }
      }
    }

    params["propertyHouseRuleAdditionalParamList"] = addAdditionalHR;
    params["propertyHouseRuleDetailsParamList"] = detailsHR;
    params["propertyHouseRuleParamList"] = hr;
    console.log("finalResult", params);
    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem("propDetails", JSON.stringify(response.data));
          this.setState({
            property_id: response.data.id,
            // redirect: "/propertiesmanagement/keepcalendarupdate",
            user_id: response.data.user.id,
          });
          // console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  nextBtnClick = (e) => {
    this.setState({
      redirect: "/propertiesmanagement/keepcalendarupdate",
      property_id: this.state.property_id,
      user_id: this.state.user_id,
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/guestrequirements",
      user_id: this.state.user_id
    });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    const houseRules = this.state.houseRules;

    const houseRuleAdditionals = this.state.houseRuleAdditionals;
    const houseRuleAdditionals2 = this.state.houseRuleAdditionals2;
    console.log("render", houseRuleAdditionals);
    const houseRuleGuests = this.state.houseRuleGuests;
    let additional;
    if (houseRuleAdditionals.length > 0) {
      additional = (
        <table style={{ width: "100%" }}>
          <tbody>
            {houseRuleAdditionals.map((item, key) => {
              return (
                <tr key={key}>
                  <td>{item.ruleName}</td>
                  <td
                    // onClick={() => this.removeAdditional(key)}
                    style={{
                      float: "right",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-plus"></i>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / House Rules" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Mention your house policies</h5>
                  <p>
                    Guest must agree to the policies you set before they book.
                  </p>
                </div>
                <div className="card-body">
                  <form>
                    {/* <div className="form-group row">
                      <div className="col-sm-12">
                        <ul>
                          {houseRules.length > 0 &&
                            houseRules.map((item, key) => {
                              return (
                                <div className="row" key={key}>
                                  <div className="col-sm-6">
                                    <label
                                      htmlFor="ApartmentDropDown"
                                      className="col-sm-6 col-form-label"
                                    >
                                      {item["name"]}
                                    </label>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="media p-20">
                                      <div className="radio radio-success mr-3 ml-2">
                                        <input
                                          id={item.id}
                                          type="radio"
                                          name={item.id + "" + key}
                                          value="NO"
                                          onChange={this.setAction}
                                        />
                                        <label htmlFor={item.id}>No</label>
                                      </div>
                                      <div className="radio radio-info mr-3 ml-2">
                                        <input
                                          id={item.id + "" + key}
                                          type="radio"
                                          name={item.id + "" + key}
                                          value="YES"
                                          checked={
                                            item.applicable ? true : false
                                          }
                                          onChange={this.setAction}
                                        />
                                        <label htmlFor={item.id + "" + key}>
                                          Yes
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </ul>
                      </div>
                    </div> */}
                    <div className="form-group row">
                      <label className="col-sm-6 col-form-label">
                        Added policies
                      </label>
                      <div className="col-sm-12">
                        <div id="bloodhound">
                          <div className="list-group">
                            {houseRuleAdditionals2.length > 0 &&
                              houseRuleAdditionals2.map((item, key) => {
                                return (
                                  <li
                                    className="list-group-item list-group-item-action active"
                                    key={key}
                                  >
                                    {item.ruleName}
                                  </li>
                                );
                              })}
                          </div>
                        </div>
                        {/* {additional} */}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-6 col-form-label">
                        You can add more policies
                      </label>
                      <div className="col-sm-12">
                        <div id="bloodhound">
                          <form className="theme-form">
                            <div className="form-group">
                              <Typeahead
                                id="custom-typeahead"
                                allowNew
                                multiple
                                newSelectionPrefix="Add a new item: "
                                options={this.state.houseRuleAdditionals}
                                placeholder="Type anything..."
                                onChange={this.setAdditional}
                              />
                            </div>
                          </form>
                        </div>
                        {/* {additional} */}
                      </div>
                    </div>

                    <div className="form-group row">
                      <p className="text-bold">
                        Details guest must know about your home
                      </p>
                      <div className="col-sm-4">
                        <ul>
                          {houseRuleGuests.length > 0 &&
                            houseRuleGuests.map((item, key) => {
                              return (
                                <div key={key}>
                                  <input
                                    className="checkbox_animated"
                                    type="checkbox"
                                    id={item.id}
                                    value={item.val}
                                    onChange={this.onChangeGuest(key)}
                                    checked={item["isChecked"] ? true : false}
                                  />
                                  <span className="label-text">
                                    {item["name"]}{" "}
                                  </span>
                                  {item["isChecked"] && (
                                    <div>
                                      <li>
                                        Describe the noise and when it’s likely
                                        to take place
                                      </li>
                                      <li>
                                        <input
                                          style={{
                                            marginBottom: "20px !important",
                                          }}
                                          id={item.id + "" + key}
                                          value={item["description"]}
                                          placeholder="Add your description"
                                          onChange={this.onChangeGuestDes(key)}
                                          className="mb-4"
                                        />
                                      </li>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default HouseRules;
