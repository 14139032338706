import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
// import { ToastContainer, toast } from "react-toastify";
// import apiService from "../../apiservices/apiservice";
import { Redirect } from "react-router-dom";

class AddCalendar extends Component {
  state = {
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // let property_id = this.state.property_id;
    this.setState({
      property_id:this.state.property_id,
      // redirect: "/propertiesmanagement/availabilityquestion",
      user_id:this.state.user_id
    });
  };
  nextBtnClick = (e) => {
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/availabilityquestion",
      user_id:this.state.user_id
    });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom : this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Add Calendar" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>
                    Do you want to connect another calendar with your Hurraayy
                    calendar?
                  </h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label className="d-block" htmlFor="addCalendar">
                          <input
                            className="radio_animated"
                            id="addCalendar"
                            type="radio"
                            name="addCalendar"
                            defaultChecked
                            value={1}
                          />
                          No
                        </label>
                        
                      </div>
                      <div className="col-sm-3">
                      <label className="d-block" htmlFor="addCalendar1">
                          <input
                            className="radio_animated"
                            id="addCalendar1"
                            type="radio"
                            name="addCalendar"
                            value={2}
                          />
                          Yes, connect another calendar
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AddCalendar;
