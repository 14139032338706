import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router-dom";
import apiService from "../../../src/apiservices/apiservice";

const detail_icon = (
  <svg
    width="25"
    height="24"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 500 500"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="249.9"
      cy="250.4"
      r="204.7"
      stroke="#000000"
      strokeMiterlimit="10"
    />
    <circle
      cx="249.9"
      cy="247.4"
      fill="#FFFFFF"
      r="181.8"
      stroke="#000000"
      strokeMiterlimit="10"
    />
    <circle cx="148.5" cy="247.5" r="31.5" />
    <circle cx="250.5" cy="250.5" r="31.5" />
    <circle cx="351.5" cy="249.5" r="31.5" />
  </svg>
);
const edit_icon = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4376 4H4.15952C3.60802 4 3.07911 4.21071 2.68913 4.58579C2.29916 4.96086 2.08008 5.46957 2.08008 6V20C2.08008 20.5304 2.29916 21.0391 2.68913 21.4142C3.07911 21.7893 3.60802 22 4.15952 22H18.7156C19.2671 22 19.7961 21.7893 20.186 21.4142C20.576 21.0391 20.7951 20.5304 20.7951 20V13"
      stroke="#343434"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.2354 2.50023C19.6491 2.1024 20.2101 1.87891 20.795 1.87891C21.38 1.87891 21.941 2.1024 22.3546 2.50023C22.7682 2.89805 23.0006 3.43762 23.0006 4.00023C23.0006 4.56284 22.7682 5.1024 22.3546 5.50023L12.4772 15.0002L8.31836 16.0002L9.35808 12.0002L19.2354 2.50023Z"
      stroke="#343434"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export class Datatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: [],
      myData: this.props.myData,
      selectedValue: "",//this.props.fullData,
      redirect: null,
      selectedRowId: null,
      callFrom: this.props.callFrom,
      hostId: null,
      approvalFlag: [
        {
          id: "LISTED",
          name: "Approve",
        },
        {
          id: "PENDING",
          name: "Reject",
        },
      ],
      approvalAction: -1,
    };

    // console.log(this.state.callFrom);
  }

  selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues: this.state.checkedValues.filter((item, j) => i !== item),
      });
    } else {
      this.state.checkedValues.push(i);
      this.setState({
        checkedValues: this.state.checkedValues,
      });
    }
  };

  handleRemoveRow = () => {
    const selectedValues = this.state.checkedValues;
    const updatedData = this.state.myData.filter(function (el) {
      return selectedValues.indexOf(el.id) < 0;
    });
    this.setState({
      myData: updatedData,
    });
    toast.success("Successfully Deleted !");
  };

  renderEditable = (cellInfo) => {
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          const data = [...this.state.myData];
          data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          this.setState({ myData: data });
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.myData[cellInfo.index][cellInfo.column.id],
        }}
      />
    );
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  onchangeApprovalAction = (e) => {
    this.setState({
      approvalAction: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    let _this = this;
    let property_id = e.target.value;

    let url = "properties/" + property_id + "/update-approval";
    params["approvalStatus"] = this.state.approvalAction;

    console.log("param", url, params);
    // if(this.state.callFrom==="PropertyList"){
    //   console.log("right method called");
    //   apiService
    //   .update_method(params, url)
    //   .then((response) => {
    //     console.log("response", response);
    //     if (response && response.status === 200) {
    //       toast.success("Updated Successfully.", {
    //         position: toast.POSITION.BOTTOM_RIGHT,
    //       });
    //       localStorage.setItem("propDetails", JSON.stringify(response.data));

    //       // console.log(response);
    //     } else {
    //       toast.error(response.error_description, {
    //         position: toast.POSITION.BOTTOM_RIGHT,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     // loginState.closeLoader();
    //     toast.error("Exception Occured!!", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //     });
    //     // console.log(error);
    //   });
    // }
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.selectedId,
              user_id: this.state.selectedId,
              callFrom: this.state.callFrom,
              hostId: this.state.hostId,
              selected_data: this.state.selectedValue,
            },
          }}
        />
      );

      // return <AddAdminUser cutomprop='1'/>
    }
    const { pageSize, myClass, multiSelectOption, pagination } = this.props;
    const { myData } = this.state;
    const { approvalFlag } = this.state;

    const columns = [];
    for (var key in myData[0]) {
      let editable = this.renderEditable;
      if (key === "image") {
        editable = null;
      }
      if (key === "status") {
        editable = null;
      }
      if (key === "avtar") {
        editable = null;
      }
      if (key === "vendor") {
        editable = null;
      }
      if (key === "skill") {
        editable = null;
      }
      if (key === "user") {
        editable = null;
      }

      columns.push({
        Header: <b>{this.Capitalize(key.toString())}</b>,
        accessor: key,
        Cell: editable,
        style: {
          textAlign: "center",
        },
      });
    }

    if (multiSelectOption === true) {
      columns.push({
        Header: (
          <button
            className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
            onClick={(e) => {
              if (window.confirm("Are you sure you wish to delete this item?"))
                this.handleRemoveRow();
            }}
          >
            Delete
          </button>
        ),
        id: "delete",
        accessor: (str) => "delete",
        sortable: false,
        style: {
          textAlign: "center",
        },
        Cell: (row) => (
          <div>
            <span>
              <input
                type="checkbox"
                name={row.original.id}
                defaultChecked={this.state.checkedValues.includes(
                  row.original.id
                )}
                onChange={(e) => this.selectRow(e, row.original.id)}
              />
            </span>
          </div>
        ),
      });
    } else {
      columns.push({
        Header: <b>Action</b>,
        id: "delete",
        accessor: (str) => "delete",
        Cell: (row) => (
          <div>
            {/* <span onClick={() => {
                                if (window.confirm('Are you sure you wish to delete this item?')) {
                                    let data = myData;
                                    data.splice(row.index, 1);
                                    this.setState({ myData: data });
                                }
                                toast.success("Successfully Deleted !")

                            }}>
                                <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                ></i>
                            </span> */}

            <span
              onClick={() => {
                var targetValue = row.original.id;
                console.log("selected data", row.original);
                var vRedirect = "";
                if (this.state.callFrom === "AdminUserList") {
                  vRedirect = "/adminuser/addadminuser";
                } else if (this.state.callFrom === "ExperienceList") {
                  vRedirect = "/experiencemanagement/city";
                } else if (this.state.callFrom === "AddExperienceForUserList") {
                  vRedirect = "/experiencemanagement/city";
                } else if (this.state.callFrom === "AdventureUserList") {
                  vRedirect = "/adventuremanagement/city";
                } else if (this.state.callFrom === "AdventureList") {
                  vRedirect = "/adventuremanagement/city";
                } else if (this.state.callFrom === "AdventureReservationList") {
                  vRedirect = "/reservationmanagement/advdetails";
                }else if (this.state.callFrom === "ExperienceReservationList") {
                  vRedirect = "/reservationmanagement/expdetails";
                }
                else if (this.state.callFrom === "PropertiesReservationList") {
                  vRedirect = "/reservationmanagement/propdetails";
                }
                else if (this.state.callFrom === "PropertiesMessagingList") {
                  vRedirect = "/messagingmanagement/msgdetails";
                }
                else if (this.state.callFrom === "PropertiesBookingReqList") {
                  vRedirect = "/reservationmanagement/propdetails";
                }
                 else {
                  vRedirect = "/propertiesmanagement/placesetting";
                }

                if(this.props.fullData !== undefined){
                  let dataList = this.props.fullData;
                  let dataPush = [];
                  if (dataList.length > 0){
                    for (let key in dataList){
                      if(Number(row.original.id) === Number(dataList[key]["id"])){
                        dataPush.push({
                          vData: dataList[key]
                        });
                      } 
                    }
                  }
                  this.setState({
                    selectedValue: dataPush
                  });
                }
                

                this.setState({
                  redirect: vRedirect,
                  selectedId: targetValue,
                  hostId: row.original.hostId,
                });
              }}
              value={row.original.id}
            >
              {/* <i
                className="fa fa-pencil btn"
                style={{
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
              ></i> */}
              {this.state.callFrom !== "AdventureReservationList" &&
              this.state.callFrom !== "ExperienceReservationList" &&
              this.state.callFrom !== "PropertiesReservationList" &&
              this.state.callFrom !== "PropertiesMessagingList"
                ? edit_icon
                : detail_icon}
            </span>
          </div>
        ),
        style: {
          textAlign: "center",
        },
        sortable: false,
      });

      if (this.state.callFrom === "PropertyList" || this.state.callFrom ===  "ExperienceList" 
      || this.state.callFrom ===  "AdventureList") {
        columns.push({
          Header: <b>Approval Action</b>,
          id: "approval",
          accessor: (str) => "approval",
          Cell: (row) => (
            <div>
              <span>
                <select
                  value={this.state.approvalAction}
                  className="form-control digits"
                  id="approval_drop_down"
                  onChange={this.onchangeApprovalAction}
                >
                  <option value="-1">Please select action</option>
                  {approvalFlag.length > 0 &&
                    approvalFlag.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </span>
            </div>
          ),
          style: {
            textAlign: "center",
          },
          sortable: false,
        });
        columns.push({
          Header: <b>Submit</b>,
          id: "approvalSubmit",
          accessor: (str) => "approvalSubmit",
          Cell: (row) => (
            <div>
              <span
                onClick={() => {
                  var targetValue = row.original.id;
                  var targetAction = this.state.approvalAction;
                  console.log("action", targetValue, targetAction);
                  let params = {};
                  // let property_id = targetValue;

                  
                  params["approvalStatus"] = targetAction;
                  let url="";
                  
                  if (this.state.callFrom === "PropertyList") {
                    url = "properties/" + targetValue + "/update-approval";
                  }
                  else if(this.state.callFrom === "ExperienceList"){
                    url = "experience/" + targetValue + "/update-approval";
                  }
                  else if(this.state.callFrom === "AdventureList"){
                    url = "adventure/" + targetValue + "/update-approval";
                  }
                  console.log("param", url, params);
                  apiService
                      .update_method(params, url)
                      .then((response) => {
                        console.log("response", response);
                        if (response && response.status === 200) {
                          toast.success("Updated Successfully.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                          });
                          if (this.state.callFrom === "PropertyList") {
                            localStorage.setItem(
                              "propDetails",
                              JSON.stringify(response.data)
                            );
                          }
                          else if(this.state.callFrom === "ExperienceList"){
                            localStorage.setItem(
                              "experienceDetails",
                              JSON.stringify(response.data)
                            );
                          }
                          else if(this.state.callFrom === "AdventureList"){
                            localStorage.setItem(
                              "adventureDetails",
                              JSON.stringify(response.data)
                            );
                          }

                          

                          // console.log(response);
                        } else {
                          toast.error(response.error_description, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                          });
                        }
                      })
                      .catch((error) => {
                        // loginState.closeLoader();
                        toast.error("Exception Occured!!", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        // console.log(error);
                      });
                }}
                // onClick={this.handleSubmit}
                value={row.original.id}
              >
                <i
                  className="fa fa-check-square btn"
                  style={{
                    width: 35,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(40, 167, 69)",
                  }}
                ></i>
              </span>
            </div>
          ),
          style: {
            textAlign: "center",
          },
          sortable: false,
        });
      }
    }

    return (
      <Fragment>
        <ReactTable
          data={myData}
          columns={columns}
          defaultPageSize={pageSize}
          className={myClass}
          showPagination={pagination}
        />
        <ToastContainer />
      </Fragment>
    );
  }
}

export default Datatable;
