import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

class AdventureGroupSize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minimumGroupSize: 18,
      adventure_id: this.props.location.state
        ? this.props.location.state.adventure_id
        : null,
      user_id: this.props.location.state
        ? this.props.location.state.user_id
        : null,
      callFrom: this.props.location.state
        ? this.props.location.state.callFrom
        : null,
      hostId: this.props.location.state
        ? this.props.location.state.hostId
        : null,
    };
  }

  componentDidMount() {
    if (this.state.adventure_id !== null) {
      this.getEditData();
    }
  }

  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("adventureDetails"));
    console.log("resp", resp);
    
    if (resp["minimumGroupSize"] !== null) {
      // this.state.about = resp["aboutYourSelf"];
      _this.setState({
        minimumAge: resp["minimumGroupSize"],
      });
    }

    // console.log("saved ans", this.state.savedAnswers);
  }

  onChangeMaximumGroupSize = (e) => {
    this.setState({
      minimumGroupSize: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};

    let adventure_id = this.state.adventure_id;
    let url = "adventure/" + adventure_id + "/group_size/";

    params["minimumGroupSize"] = this.state.minimumGroupSize;

    console.log("params", params);

    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem(
            "adventureDetails",
            JSON.stringify(response.data)
          );
          this.setState({
            adventure_id: response.data.id,
            // redirect: "/experiencemanagement/ideatheme",
            user_id: response.data.host.id,
          });
          console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  nextBtnClick = (e) => {
    this.setState({
      adventure_id: this.state.adventure_id,
      redirect: "/advanturemanagement/adventureduration",
      user_id: this.state.user_id,
    });
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              adventure_id: this.state.adventure_id,
              user_id: this.state.user_id,
              hostId: this.state.hostId,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Experience Management / Group Size" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Group Size</h5>
                </div>
                <div className="card-body">
                  <form className="form theme-form" id="groupSizeForm">
                    <div className="form-group row">
                      <label
                        htmlFor="minimumGroupSize"
                        className="col-sm-3 col-form-label"
                      >
                        Maximum group size
                      </label>
                      <div className="col-sm-9">
                        <select
                          value={this.state.minimumGroupSize}
                          className="form-control digits"
                          id="minimumGroupSize"
                          onChange={this.onChangeMaximumGroupSize}
                        >
                          <option value="">
                            Please select maximum group size
                          </option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-warning mr-1"
                        // onClick={() => {
                        //   this.setState({
                        //     redirect: "/propertiesmanagement/addproperties",
                        //   });
                        // }}
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        onClick={this.handleSubmit}
                      >
                        Update
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={this.nextBtnClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default AdventureGroupSize;
