import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import apiService from "../../apiservices/apiservice";
import { GEOCODE_URL, GEOCODE_KEY } from "../../lib/constants";
import axios from "axios";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

class LocationSetup extends Component {
  state = {
    lat: "",
    long: "",
    apartment: "",
    country_id: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    countries: [],
    countryArr: {},
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    hostId: this.props.location.state.hostId,
    callFrom: this.props.location.callFrom,
  };
  componentDidMount() {
    this.getCountries();
    if (this.state.property_id !== undefined) {
      this.getEditData();
    }
  }
  getEditData() {
    let _this = this;
    var propDetails = JSON.parse(localStorage.getItem("propDetails"));
    if(propDetails['city'] && propDetails['city']['state'] && propDetails['city']['state']['country'] && propDetails['city']['state']['country']['shortName']){
      this.setState({
        country_id: propDetails['country']['name']
      });
    }
    if(propDetails['locZipCode']){
      this.setState({
        zipCode: propDetails['locZipCode']
      });
    }
    if(propDetails['locState']){
      this.setState({
        state: propDetails['locState']
      });
    }
    if(propDetails['locCity']){
      this.setState({
        city: propDetails['locCity']
      });
    }
    if(propDetails['locApartment']){
      this.setState({
        apartment: propDetails['locApartment']
      });
    }
    if(propDetails['locStreet']){
      this.setState({
        street: propDetails['locStreet']
      });
    }
    if(propDetails['langitude']){
      this.setState({
        long: propDetails['langitude']
      });
    }
    if(propDetails['latitude']){
      this.setState({
        lat: propDetails['latitude']
      });
    }
    // this.setState({loading:false})
  }
  getCurrentLocation() {
    let _this = this;
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("po", position);
      let lat = position.coords.latitude;
      let long = position.coords.longitude;
      if (lat && long) {
        _this.setState({ lat: lat });
        _this.setState({ long: long });
        let url =
          GEOCODE_URL + "?latlng=" + lat + "," + long + "&key=" + GEOCODE_KEY;
        axios.get(url).then(function (resp) {
          console.log("response", resp);
          if (resp && resp.status === 200) {
            if (resp.data.results.length > 0) {
              let parts = resp.data.results[0].address_components;
              let street_number = "";
              let route = "";
              parts.forEach((part) => {
                if (part.types.includes("country")) {
                  _this.setState({ country_id: part.long_name });
                  console.log("country", part.long_name);
                }
                /*        Division    */
                if (part.types.includes("administrative_area_level_1")) {
                  _this.setState({ state: part.long_name });
                  console.log("administrative_area_level_1", part.long_name);
                }
                /*       District    */
                if (part.types.includes("administrative_area_level_2")) {
                }
                /*          city   */
                if (part.types.includes("locality")) {
                  _this.setState({ city: part.long_name });
                  console.log("locality", part.long_name);
                }
                if (part.types.includes("route")) {
                  route = part.long_name;
                }
                if (part.types.includes("street_number")) {
                  street_number = part.long_name;
                  console.log("street_number", part.long_name);
                }
                if (part.types.includes("postal_code")) {
                  _this.setState({ zipCode: part.long_name });
                  console.log("postal_code", part.long_name);
                }
              });
              _this.setState({ street: street_number + ", " + route });
              console.log("street", street_number + ", " + route);
            }
          }
        });
      }
    });
  }
  getCountries() {
    let _this = this;
    apiService
      .countryList()
      .then((response) => {
        //this.hideLoader();
        if (response && response.status === 200) {
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["name"],
                name: dataList[key]["name"],
                country_id_api:dataList[key]["id"]
              });
              _this.state.countryArr[dataList[key]["shortName"]] =
                dataList[key]["id"];
            }
          }
          _this.setState({
            countries: dataPush,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    
    let _this = this;
    let params = {};
    
    var countryArr = this.state.countries;
    let property_id = this.state.property_id;
    let url = "properties/" + property_id + "/location";

    params["apartment"] = this.state.apartment;
    params["city"] =this.state.city;

    
    // console.log("map cID", this.state.country_id);
    // console.log("array length",countryArr);
    for(var i = 0; i<countryArr.length; i++){
      if(this.state.country_id === countryArr[i].name){
        params["country_id"] = countryArr[i].country_id_api;
      }
    }
    params["langitude"] = this.state.lat;
        params["latitude"] = this.state.long;
    params["state"] = this.state.state;
    params["street"] = this.state.street;
    params["zipCode"] = this.state.zipCode;
    console.log("params",params);

    apiService
        .update_method(params, url)
        .then((response) => {
          console.log("response", response);
          if (response && response.status === 200) {
            toast.success("Updated Successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            localStorage.setItem("propDetails",JSON.stringify(response.data));
            this.setState({
              property_id:response.data.id,
              // redirect: "/propertiesmanagement/locationconfirmation",
              user_id:response.data.user.id
            });
            // console.log(response);
          } else {
            toast.error(response.error_description, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((error) => {
          // loginState.closeLoader();
          toast.error("Exception Occured!!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // console.log(error);
        });
    
  };
  nextBtnClick = (e) => {
    this.setState({
      redirect: "/propertiesmanagement/locationconfirmation",
      property_id:this.state.property_id,
      user_id: this.state.user_id
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/bathrooms",
      user_id: this.state.user_id
    });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom : this.state.callFrom,
            },
          }}
        />
      );
    }
    const { countries } = this.state;
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Bath Rooms" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>The location of your place?</h5>
                  <p>
                    We will send the exact location to guests only when they
                    confirm a reservation.
                  </p>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <div className="col-sm-3">
                        <button
                          className="btn btn-outline-warning"
                          type="button"
                          onClick={() => this.getCurrentLocation()}
                        >
                          <i className="fa fa-location-arrow"></i>
                          &nbsp; Use current location
                        </button>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="ApartmentDropDown"
                        className="col-sm-3 col-form-label"
                      >
                        Country / Region
                      </label>
                      <div className="col-sm-9">
                        <select
                          value={this.state.country_id}
                          className="form-control digits"
                          id="ApartmentDropDown"
                          onChange={() => {
                            this.setState({
                              country_id: this.value,
                            });
                          }}
                        >
                          <option value="">Please select your country!</option>
                          {countries.length > 0 &&
                            countries.map((item, key) => {
                              return (
                                <option key={key} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="exampleFormControlSelect9"
                        className="col-sm-3 col-form-label"
                      >
                        Street address
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          id="exampleInputPassword2"
                          type="text"
                          value={this.state.street}
                          onChange={(e)=>this.setState({
                            street:e.target.value
                          })}
                          placeholder="Please enter your street address!"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="apartmentId"
                        className="col-sm-3 col-form-label"
                      >
                        Apartment, suite
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          id="apartmentId"
                          type="text"
                          value={this.state.apartment}
                          onChange={(e)=>this.setState({
                            apartment:e.target.value
                          })}
                          placeholder="Please enter your Apartment, suite!"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="cityId"
                        className="col-sm-3 col-form-label"
                      >
                        City
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          id="cityId"
                          type="text"
                          value={this.state.city}
                          onChange={(e)=>this.setState({
                            city:e.target.value
                          })}
                          placeholder="Please enter your city!"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="stateCountryId"
                        className="col-sm-3 col-form-label"
                      >
                        State/County
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          id="stateCountryId"
                          type="text"
                          value={this.state.state}
                          onChange={(e)=>this.setState({
                            state:e.target.value
                          })}
                          placeholder="Please enter your state!"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="zipcodeId"
                        className="col-sm-3 col-form-label"
                      >
                        Zip code / Post code
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          id="zipcodeId"
                          type="text"
                          value={this.state.zipCode}
                          onChange={(e)=>this.setState({
                            zipCode:e.target.value
                          })}
                          placeholder="Please enter your zip code!"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Fragment>
    );
  }
}

export default LocationSetup;
