import React, { Component, Fragment } from "react";
import { $managementAxios } from "../../../lib/unility/http-service";
import Breadcrumb from "../../common/breadcrumb";
import apiService from "../../../apiservices/apiservice";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import moment from "moment";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";

class ApplicationUserList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: [
				{
					name: "Id",
					selector: (row) => row.id,
					maxWidth: "20px",
					id: "id",
					sortable: true,
				},
				{
					name: "First name",
					selector: (row) => row.firstName,
					id: "firstName",
					sortable: true,
				},
				{
					name: "Last name",
					selector: (row) => row.lastName,
					id: "lastName",
					sortable: true,
				},
				{
					name: "Email",
					selector: (row) => row.emailAddress,
					id: "emailAddress",
					sortable: true,
				},
				{
					name: "Phone",
					selector: (row) => row.phoneNumber,
					id: "phoneNumber",
					sortable: true,
				},
				{
					name: "Date of registration",
					selector: (row) => row.createdAt,
					format: (row, index) => row.createdAt,
					id: "createdAt",
					sortable: true,
				},
				{
					name: "Date of birth",
					selector: (row) => row.dateOfBirth,
					format: (row, index) => row.dateOfBirth,
					id: "dateOfBirth",
					sortable: true,
				},
				{
					name: "Action",
					cell: (row) => (
						<div className='d-flex flex-row justify-content-center'>
							<div>
								<i
									className='btn icofont icofont-ui-delete p-2'
									onClick={() => {
										this.DeleteApplicationUser(row);
									}}
								></i>
							</div>
							<div>
								<i
									className='btn icofont icofont-eye p-2'
									onClick={() => {
										this.viewApplicationUser(row);
									}}
								></i>
							</div>
						</div>
					),
					button: true,
				},
			],
			loading: false,
			data: {},
			page: 1,
			pageSize: 10,
			sortBy: "createdAt:desc",
			emailAddress: "",
			phoneNumber: "",
			breadcrumbItems: [{ name: "User List" }],
		};
	}

	showLoader() {
		this.setState({ loading: true });
	}

	closeLoader() {
		this.setState({ loading: false });
	}

	searchUser = () => {
		this.getData(this.state.page, this.state.pageSize, "createdAt:desc",this.state.emailAddress,this.state.phoneNumber);
	};

	componentDidMount() {
		this.getData(this.state.page, this.state.pageSize, "createdAt:desc",this.state.emailAddress,this.state.phoneNumber);
	}

	getData = (pageNo, pageSize, sortBy, emailAddress, phoneNumber) => {
		this.setState({ loading: true });
		let url = "/users/accounts?validFlag=1&pageSize=" + pageSize + "&pageNo=" + pageNo + "&sortBy=" + sortBy;
		if (emailAddress) {
			url = url + "&emailAddress=" + emailAddress;
		}
		if (phoneNumber) {
			url = url + "&phoneNumber=" + phoneNumber;
		}
		$managementAxios
			.get(url)
			.then((response) => {
				response.data.content?.forEach((element) => {
					element.dateOfBirth = new moment(element.dateOfBirth).format(
						"DD-MM-YYYY"
					);
					element.createdAt = new moment(element.createdAt).format(
						"DD-MM-YYYY"
					);
				});
				this.setState({ rowData: response.data.content, data: response.data });
				this.setState({ loading: false });
			});
	};

	DeleteApplicationUser = (user) => {
		swal({
			title: "Are you sure?",
			text: "You want to delete this user?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete)
				apiService
					.deleteUser(user.id)
					.then((response) => {
						if (response && response.status === 204) {
							toast.success("Deleted Successfully", {
								position: toast.POSITION.BOTTOM_RIGHT,
							});
						}
						this.componentDidMount();
					})
					.catch((error) => {
						toast.error("Exception Occurred!", {
							position: toast.POSITION.BOTTOM_RIGHT,
						});
					});
		});
	};

	goToAddAdmin = () => {
		this.setState({ redirect: "/applicationuser/addapplicationuser" });
	};

	onChangePages = (page, totalRows) => {
		this.setState({ page: page });
		this.getData(page, this.state.pageSize, "createdAt:desc");
	};
	onChangePerPage = (currentRowsPerPage, currentPage) => {
		this.setState({ pageSize: currentRowsPerPage });
		this.getData(this.state.page, currentRowsPerPage, "createdAt:desc");
	};

	handleSort = (column, sortDirection) => {
		var sort = column.id + ":" + sortDirection;
		this.setState({ sortBy: sort });
		this.getData(this.state.page, this.state.pageSize, sort);
	};
	render() {
		if (this.state.redirect) {
			return (
				<Redirect
					to={{
						pathname: this.state.redirect,
					}}
				/>
			);
		}
		return (
			<Fragment>
				<Breadcrumb options={this.state.breadcrumbItems} />
				<div className='container-fluid'>
					<div className='d-flex justify-content-between mb-3'>
						<div>
							<h5>Application User List</h5>
						</div>
						<div>
							<button className='btn btn-primary' onClick={this.goToAddAdmin}>
								<i className='icofont icofont-plus pr-2'></i>
								Add User
							</button>
						</div>
					</div>
					<div className='d-flex flex-row justify-content-end my-2 align-items-center'>
						<div className='pr-2'>
							<input
								type='text'
								className='form-control pr-2'
								placeholder='Email'
								onChange={(e) => {
									this.setState({ emailAddress: e.target.value });
								}}
							/>
						</div>

						<div className='pr-2'>
							<input
								type='text'
								className='form-control pr-2'
								placeholder='Phone'
								onChange={(e) => {
									this.setState({ phoneNumber: e.target.value });
								}}
							/>
						</div>
						<div>
							<button
								className='btn btn-primary'
								onClick={() => this.searchUser()} // Call the search function on button click
							>
								Search
							</button>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-12'>
							<DataTable
								keyField='id'
								columns={this.state.columns}
								data={this.state.data.content}
								pagination={true}
								paginationServer={true}
								paginationTotalRows={this.state.data.totalElements}
								onChangePage={this.onChangePages}
								onChangeRowsPerPage={this.onChangePerPage}
								progressPending={this.state.loading}
								onSort={this.handleSort}
								dense
							/>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default ApplicationUserList;
