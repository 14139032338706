import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import { toast } from "react-toastify";

const amenities = [];
class Amenites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResults: true,
      amenities: [],
      propertyId: props.property_id != null ? props.property_id : null
    }
    this.onToggle = this.onToggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  componentDidUpdate() {

  }
  componentDidMount() {

    apiService.propertyAmenities('1,2,3,4,5')
      .then((response) => {
        console.log(response);
        if (response && response.status === 200) {
          this.setState({
            amenities: response.data
          });
        }
      })
      .catch((error) => {
        // toast.error(error.statusText, { position: toast.POSITION.BOTTOM_RIGHT });
      })

    if (this.state.propertyId) {
      console.log("prop id", this.state.propertyId);
      apiService.Get('properties/' + this.state.propertyId)
        .then((response) => {
          if (response && response.status === 200) {
            var amenitiesList = response.data.amenities;
            amenitiesList.forEach(element => {
              var id = element.id.toString();
              if (this.state.amenities.slice(x => x.id === element.id)) {
                document.getElementById(id).checked = true;
                amenities.push(element.id);
              }
            });
          }
        })
        .catch((error) => {
          // toast.error('Properties not found!', { position: toast.POSITION.BOTTOM_RIGHT })
        });
    }
  }

  onToggle() {
    this.setState(state => ({
      showResults: !state.showResults
    }));
  }

  handleCheckChange(event) {
    if (event.target.checked && !amenities.some(x => x === event.target.value)) {
      amenities.push(event.target.value);
    } else {
      amenities.splice(amenities.indexOf(event.target.value), 1);
    }

    console.log(amenities);
  }

  handleSubmit = (e) => {
    var amenityListObj = {
      amenity_ids: amenities
    }
    var url = 'properties/' + this.state.propertyId + '/amenities';
    apiService.update(url, amenityListObj)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Updated successfull!',
            { position: toast.POSITION.BOTTOM_RIGHT })
          localStorage.setItem("propDetails", JSON.stringify(response.data));
        }
      })
      .catch((error) => {
        toast.error('Updated faild!',
          { position: toast.POSITION.BOTTOM_RIGHT })
      })
    //console.log(spaceListObj);
  }
  render() {
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header shadow-lg" onClick={this.onToggle}>
                  <h5>What amenities do you offer?</h5>
                </div>
                {this.state.showResults ?
                  <Fragment>
                    <div className="card-body">
                      {this.state.amenities.map((amenitiy) => {
                        return (
                          <div className="form-check">
                            <label>
                              <input className="checkbox_animated"
                                type="checkbox"
                                key={amenitiy.id.toString()}
                                id={amenitiy.id}
                                value={amenitiy.id}
                                title={amenitiy.name}
                                onChange={this.handleCheckChange}
                              />
                              <span className="label-text">{amenitiy.name} </span>
                            </label>
                          </div>
                        );
                      })
                      }
                    </div>
                    <div className="card-footer">
                      <div>
                        <button onClick={this.handleSubmit} className="btn btn-primary">{this.props != null ? 'Update' : 'Save'}</button>
                      </div>
                    </div>
                  </Fragment> : null}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
};

export default Amenites;
