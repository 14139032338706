import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { Collapse, Button, CardBody, Card } from "reactstrap";

var currentState = "";
const testArray = [];
class WillProvide extends Component {
  state = {
    description: "",
    collapse: true,
    collapse2: false,
    transporting: [],
    transporting_id: 0,
    provideGroup: [],
    provideGroup_id: 0,
    isLoadedSavedData: false,
    savedData: [],
    providingAnythingForGuest: true,
    experience_id: this.props.location.state
      ? this.props.location.state.experience_id
      : null,
    user_id: this.props.location.state
      ? this.props.location.state.user_id
      : null,
    callFrom: this.props.location.state
      ? this.props.location.state.callFrom
      : null,
    hostId: this.props.location.state ? this.props.location.state.hostId : null,
  };
  componentDidMount() {
    currentState = this;
    this.getTransporting();
    if (this.state.experience_id !== null) {
      //this.getEditData();
    }
  }
  updateCheckGroup() {
    //   console.log("savedD", this.state.savedData[0].item);
    let vSavedD = this.state.savedData[0].item;
    if (this.state.isLoadedSavedData) {
      this.setState((state) => {
        state.provideGroup.map((item, key) => {
          for (var i = 0; i < vSavedD.length; i++) {
            // console.log("id", item.id, vSavedD[i]);
            if (Number(vSavedD[i]) === item.id) {
              item.checked = true;
            }
          }
        });
      });
      console.log("savedGroup", this.state.provideGroup);
    }
  }
  getEditData() {
    var resp = JSON.parse(localStorage.getItem("experienceDetails"));
    console.log("resp", resp);
    if (resp["experienceProvideJson"] !== null) {
      console.log("savedProvide", JSON.parse(resp["experienceProvideJson"]));
      let vSavedProvide = JSON.parse(resp["experienceProvideJson"]);
      // let vID = vSavedProvide[0].id;
      let vID = vSavedProvide;
      this.onChangeTransporting(vID);
      this.setState({
        isLoadedSavedData: true,
        savedData: vSavedProvide,
      });
    }
    if (resp["providingAnythingForGuest"] !== null){
        this.setState({
            providingAnythingForGuest:resp["providingAnythingForGuest"]
        })
    }
  }
  getTransporting() {
    let _this = this;
    let url = "experience_provide";
    apiService
      .get_api(url)
      .then((response) => {
        if (response && response.status === 200) {
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
              });
            }
          }
          _this.setState({
            transporting: dataPush,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onChangeTransporting(id) {
    this.setState({
      transporting_id: id,
    });
    this.getProvideGroup(id);
  }
  getProvideGroup(id) {
    let _this = this;
    let url = "experience_provide_group/" + id + "/event-list-by-provide";
    apiService
      .get_api(url)
      .then((response) => {
        if (response && response.status === 200) {
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
                checked: false,
              });
            }
          }
          _this.setState({
            provideGroup: dataPush,
          });
          _this.updateCheckGroup();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  toggle = (e) => {
    this.state.collapse
      ? this.setState({
          collapse: false,
        })
      : this.setState({
          collapse: true,
        });
  };
  toggle2 = (e) => {
    this.state.collapse2
      ? this.setState({
          collapse2: false,
        })
      : this.setState({
          collapse2: true,
        });
  };
  handleCheckChange(e) {
    //e.preventDefault();
    let isChecked = e.target.checked;
    var checkValue = e.target.value;
    if (isChecked) {
      currentState.setState((state) => {
        state.provideGroup.map((item, key) => {
          // console.log("compare", Number(checkValue), item.id);
          if (Number(checkValue) === item.id) {
            item.checked = true;
          }
        });
      });
    } else {
      currentState.setState((state) => {
        state.provideGroup.map((item, key) => {
          if (Number(checkValue) === item.id) {
            item.checked = false;
          }
        });
      });
    }
    currentState.forceUpdate();
    // console.log("testArray", currentState.state.provideGroup);
    // console.log("testArray2", testArray);
  }
  handleAnythingChange(e) {
    currentState.state.providingAnythingForGuest
      ? currentState.setState({
          providingAnythingForGuest: false,
        })
      : currentState.setState({
          providingAnythingForGuest: true,
        });
  }
  findSelectedIndex(pTcValue) {
    if (testArray.length > 0) {
      for (var i = 0; i < testArray.length; i += 1) {
        if (testArray[i]["checkedValue"] === pTcValue) {
          return i;
        }
      }
    }
    return -1;
  }
  CheckValueExist(value) {
    var isExist = false;
    for (var i = 0; i < testArray.length; i++) {
      if (value === testArray[i].checkedValue) {
        isExist = true;
        break;
      } else {
        isExist = false;
        continue;
      }
    }
    return isExist;
  }
  nextBtnClick = (e) => {
    this.setState({
      experience_id: this.state.experience_id,
      redirect: "/experiencemanagement/guestbring",
      user_id: this.state.user_id,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    let _this = this;
    let experience_id = this.state.experience_id;

    let url = "experience/" + experience_id + "/provide";

    let finalArray = [];
    for (var i = 0; i < this.state.provideGroup.length; i++) {
      if (this.state.provideGroup[i].checked) {
        finalArray.push(this.state.provideGroup[i].id);
      }
    }

    let newExpJson = "";
    for(let i = 0; i < finalArray.length; i++){
      if(newExpJson === ""){
        newExpJson = finalArray[i];
      }
      else{
        newExpJson = newExpJson + ","+finalArray[i]
      }
    }
    let pJson = [
      // this.state.transporting_id,
       finalArray
    ];
    // params["experienceProvideJson"] = JSON.stringify(pJson);
    params["experienceProvideJson"] = newExpJson;
    if (finalArray.length > 0) {
      this.setState({
        providingAnythingForGuest: true,
      });
    }
    params["providingAnythingForGuest"] = this.state.providingAnythingForGuest;
    console.log("param", params);
    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem(
            "experienceDetails",
            JSON.stringify(response.data)
          );
          this.setState({
            experience_id: response.data.id,
            // redirect: "/experiencemanagement/ideatheme",
            user_id: response.data.host.id,
          });
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              experience_id: this.state.experience_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    const { transporting } = this.state;
    const { provideGroup } = this.state;
    return (
      <Fragment>
        <Breadcrumb parent="Experience Management / Will Provide" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Add details about what you’ll provide</h5>
                </div>
                <div className="card-body">
                  <form className="form theme-form" id="willprovideForm">
                    <div className="form-group row">
                      <Button
                        color="ff6204"
                        onClick={this.toggle}
                        style={{ marginBottom: "1rem", color: "#ff6204" }}
                      >
                        Tips
                      </Button>
                      <Collapse isOpen={this.state.collapse ? true : false}>
                        <Card className="mb-0">
                          <CardBody>
                            <p>
                              <i className="fa fa-angle-double-right"></i>
                              Try to provide guest with specific details about
                              the food and drink
                            </p>
                            <p>
                              <i className="fa fa-angle-double-right"></i>
                              Tell guests which allergies and dietary
                              preferences you can accommodate when you add food
                              as an item
                            </p>
                          </CardBody>
                        </Card>
                      </Collapse>
                    </div>
                    <div className="form-group row">
                      <Button
                        color="ff6204"
                        onClick={this.toggle2}
                        style={{ marginBottom: "1rem", color: "#ff6204" }}
                        className="col-sm-2"
                      >
                        + Add an item
                      </Button>
                      <Collapse
                        isOpen={this.state.collapse2 ? true : false}
                        className=" col-sm-10"
                      >
                        <Card className="mb-0 row">
                          <CardBody>
                            <div className="form-group row">
                              <label
                                htmlFor="transporting1"
                                className="col-sm-3 col-form-label"
                              >
                                Select the transporting you’ll be providing
                              </label>
                              <div className="col-sm-9">
                                <div className="card-body btn-showcase">
                                  <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    {transporting.length > 0 &&
                                      transporting.map((item, key) => {
                                        return (
                                          <div
                                            className="radio radio-primary"
                                            key={key}
                                          >
                                            <input
                                              id={item.id}
                                              type="radio"
                                              name="transporting"
                                              checked={
                                                this.state.transporting_id ===
                                                item.id
                                                  ? true
                                                  : false
                                              }
                                              onChange={this.onChangeTransporting.bind(
                                                this,
                                                item.id
                                              )}
                                            />
                                            <label
                                              className="mb-0"
                                              htmlFor={item.id}
                                            >
                                              {item.name}
                                            </label>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="provideGroup1"
                                className="col-sm-3 col-form-label"
                              >
                                Select what you will provide
                              </label>
                              <div className="col-sm-9">
                                <div className="card-body btn-showcase">
                                  <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    {provideGroup.length > 0 &&
                                      provideGroup.map((item, key) => {
                                        return (
                                          <div
                                            className="radio radio-primary"
                                            key={key}
                                          >
                                            <input
                                              className="checkbox_animated"
                                              type="checkbox"
                                              id={item.id}
                                              value={item.id}
                                              title={item.name}
                                              checked={
                                                item.checked ? true : false
                                              }
                                              onChange={this.handleCheckChange}
                                            />
                                            <span className="label-text">
                                              {item.name}{" "}
                                            </span>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Collapse>
                    </div>
                    <div className="form-group row">
                      <input
                        className="checkbox_animated"
                        type="checkbox"
                        value={this.state.providingAnythingForGuest}
                        checked={
                          this.state.providingAnythingForGuest ? true : false
                        }
                        onChange={this.handleAnythingChange}
                      />
                      <span className="label-text">
                        Not providing anything for your guests?{" "}
                      </span>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-warning mr-1"
                        // onClick={() => {
                        //   this.setState({
                        //     redirect: "/propertiesmanagement/addproperties",
                        //   });
                        // }}
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        onClick={this.handleSubmit}
                      >
                        Update
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={this.nextBtnClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default WillProvide;
