import React, { Component, Fragment} from "react";
import Breadcrumb from "../common/breadcrumb";
// import apiService from "../../apiservices/apiservice";
import { GEOCODE_KEY } from "../../lib/constants";
// import { Row, Col, Form, Button, Icon, Spin } from "antd";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
import { Redirect } from "react-router-dom";
 
class LocationConfirmation extends Component {
  state = {
    lat: 0,
    lng: 0,
    address: "",
    loading: true,
    activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: false,
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
  };
  componentDidMount() {
    if (this.state.property_id !== undefined) {
      this.getEditData();
    }
  }
  getEditData() {
    // let _this = this;
    var resp = JSON.parse(localStorage.getItem("propDetails"));

    let countryName = "";
    if (
      resp["city"] &&
      resp["city"]["state"] &&
      resp["city"]["state"]["country"] &&
      resp["city"]["state"]["country"]["name"]
    ) {
      countryName = resp["city"]["state"]["country"]["name"];
    }
    let address =
      resp["locStreet"] +
      ", " +
      resp["locCity"] +
      ", " +
      resp["locState"] +
      ", " +
      resp["locZipCode"] +
      ", " +
      countryName;
    this.setState({
      address: address,
    });
    if (resp["langitude"]) {
      this.setState({
        lng: resp["langitude"],
        //lng: 23.7928447
        //23.792844799999997
      });
    }
    if (resp["latitude"]) {
      this.setState({
        //lat: 90.3806975
        lat: resp["latitude"],
        //90.38069759999999
      });
    }
    this.setState({ loading: false });
  }
  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
      });
  };
  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false,
    });
  onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    });
  handleSubmit = (e) => {
    e.preventDefault();
    // localStorage.setItem("propDetails",JSON.stringify(response.data));
    this.setState({
      property_id: this.state.property_id,
      // redirect: "/propertiesmanagement/nameplace",
      user_id: this.state.user_id,
    });
  };
  nextBtnClick = (e) => {
    this.setState({
      //redirect: "/propertiesmanagement/nameplace",
      redirect: "/propertiesmanagement/DescriptionMaster",
      property_id:this.state.property_id,
      user_id: this.state.user_id
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/locationsetup",
      user_id: this.state.user_id
    });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }

    const { address } = this.state;
    let lat = this.state.lat;
    let lng = this.state.lng;
    console.log("lat lon", lat, lng);
    // const mapStyles = {
    //   width: "100%",
    //   height: "280%",
    // };

    return (
      <Fragment>
        <Breadcrumb parent="Properties Management / Location Confirmation" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>The pin corresponds to the right place?</h5>
                  <p>
                    If needed, you can adjust the map so the pin is in the right
                    location. Only confirmed guests will see this, so they know
                    how to get to your place.
                  </p>
                  <span>{address}</span>
                </div>
                <div className="card-body">
                  <div className="map-js-height">
                    {/* <div id="gmap-simple" className="map-block"> */}
                    <div id="gmap-simple" className="map-block">
                      <Map
                        google={this.props.google}
                        onClick={this.onMapClicked}
                        zoom={14}
                        initialCenter={{
                          lat: lat,
                          lng: lng,
                        }}
                      >
                        <Marker
                          onClick={this.onMarkerClick}
                          name={"Current location"}
                        />
                        <InfoWindow
                          marker={this.state.activeMarker}
                          visible={this.state.showingInfoWindow}
                          onClose={this.onInfoWindowClose}
                        >
                          {/* <div>
                          <h1>{this.state.selectedPlace}</h1>
                        </div> */}
                        </InfoWindow>
                      </Map>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// const WrappedApp = Form.create({ name: "coordinated" })(LocationConfirmation);

export default GoogleApiWrapper({
  apiKey: GEOCODE_KEY,
})(LocationConfirmation);
