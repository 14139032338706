import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import apiService from "../../apiservices/apiservice";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";

class NamePlace extends Component {
  state = {
    name: "",
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
  };
  componentDidMount() {
    if (this.state.property_id !== undefined) {
      this.getEditData();
    }
  }
  getEditData() {
    // let _this = this;
    var propDetails = JSON.parse(localStorage.getItem("propDetails"));
    if (propDetails["name"]) {
      document.getElementById("name").value = propDetails["name"];
      this.setState({
        name: propDetails["name"],
      });
    }
  }
  onChangeTitle(id) {
    this.setState({
      name: id,
    });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    let property_id = this.state.property_id;
    let url = "properties/" + property_id + "/titles";
    params["name"] = document.getElementById("name").value;
    apiService
        .update_method(params, url)
        .then((response) => {
          console.log("response", response);
          if (response && response.status === 200) {
            toast.success("Updated Successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            localStorage.setItem("propDetails",JSON.stringify(response.data));
            this.setState({
              property_id:response.data.id,
              // redirect: "/propertiesmanagement/DescriptionMaster",
              user_id:response.data.user.id
            });
            // console.log(response);
          } else {
            toast.error(response.error_description, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((error) => {
          // loginState.closeLoader();
          toast.error("Exception Occured!!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // console.log(error);
        });
  };
  nextBtnClick = (e) => {
    this.setState({
      // redirect: "/propertiesmanagement/DescriptionMaster",
      redirect: "/propertiesmanagement/guestrequirements",
      property_id:this.state.property_id,
      user_id: this.state.user_id
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/locationconfirmation",
      user_id: this.state.user_id
    });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom : this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Properties Management / Name Place" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Give the listing a title</h5>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label htmlFor="name" className="col-sm-3 col-form-label">
                      Please enter name your place
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        placeholder="Listing title"
                        // value={this.state.name}
                        // onChange={this.onChangeTitle.bind(this, this.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Fragment>
    );
  }
}

export default NamePlace;
