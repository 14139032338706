import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

class IdeaTheme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      experienceTheme: [],
      experiencetheme_id: 7,
      natureAndOutdoors: [],
      natureAndOutdoors_id: 0,
      groupId: 0,
      experienceThemeEvents: [],
      loading: true,
      experience_id: this.props.location.state
        ? this.props.location.state.experience_id
        : null,
      user_id: this.props.location.state
        ? this.props.location.state.user_id
        : null,
      callFrom: this.props.location.state
        ? this.props.location.state.callFrom
        : null,
      hostId: this.props.location.state
        ? this.props.location.state.hostId
        : null,
    };
  }
  componentDidMount() {
    // console.log(
    //   "called",
    //   this.state.experience_id,
    //   this.state.user_id,
    //   this.state.callFrom,
    //   this.state.hostId
    // );
    this.getTheme();
    //this.getNatureAndOutdoors();
    if (this.state.experience_id !== null) {
      this.getEditData();
    }
  }
  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("experienceDetails"));
    console.log("resp", resp);
    if (resp) {
      if (resp["experienceTheme"]) {
        this.onChangeTheme(resp["experienceTheme"]["id"]);
      }
      if (resp["experienceThemeEvents"]) {
        let pEvents = resp["experienceThemeEvents"];
        var vEvents = [];
        vEvents = pEvents.split(",");
        console.log("events", vEvents);
        this.onChangeNatureOutdoors(Number(vEvents[0]), "");
      }
    }
  }

  getTheme() {
    let _this = this;
    let url = "experience_themes";
    apiService
      .get_api(url)
      .then((response) => {
        if (response && response.status === 200) {
          let dataList = response.data;
          console.log("res", response.data);
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
              });
            }
          }
          _this.setState({
            experienceTheme: dataPush,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getNatureAndOutdoors() {
    let _this = this;
    let url = "experience_theme_events";
    apiService
      .get_api(url)
      .then((response) => {
        if (response && response.status === 200) {
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
              });
            }
          }
          _this.setState({
            natureAndOutdoors: dataPush,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onChangeTheme(id) {
    this.setState({
      experiencetheme_id: id,
    });
    this.getEventListByTheme(id);
  }
  getEventListByTheme(id) {
    let _this = this;
    let url = "experience_theme_events/" + id + "/event-list-by-theme";
    apiService
      .get_api(url)
      .then((response) => {
        if (response && response.status === 200) {
          let dataList = response.data;
          console.log("event list", response.data);
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
                groupId: dataList[key]["experienceThemeGroupVO"]["id"],
              });
            }
          }
          _this.setState({
            natureAndOutdoors: dataPush,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onChangeNatureOutdoors(id, item) {
    console.log("item", item);
    this.setState({
      natureAndOutdoors_id: id,
      groupId: item.groupId,
    });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    let _this = this;
    let experience_id = this.state.experience_id;

    let url = "experience/" + experience_id + "/main-theme/";
    if (this.state.natureAndOutdoors_id !== 0) {
      
      let newexperienceThemeEvents = [];
      newexperienceThemeEvents.push({
        groupId:this.state.groupId,
        eventId:this.state.natureAndOutdoors_id
      })
      params["experienceThemeEvents"] = JSON.stringify(newexperienceThemeEvents) ;
      params["themeId"] = this.state.experiencetheme_id;

      console.log("param", params);
      apiService
        .update_method(params, url)
        .then((response) => {
          console.log("response", response);
          if (response && response.status === 200) {
            toast.success("Updated Successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            localStorage.setItem(
              "experienceDetails",
              JSON.stringify(response.data)
            );
            this.setState({
              experience_id: response.data.id,
              // redirect: "/experiencemanagement/ideatheme",
              user_id: response.data.host.id,
            });
          } else {
            toast.error(response.error_description, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((error) => {
          // loginState.closeLoader();
          toast.error("Exception Occured!!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // console.log(error);
        });
    } else {
      toast.warn("Please select nature and outdoors", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  nextBtnClick = (e) => {
    this.setState({
      experience_id: this.state.experience_id,
      redirect: "/experiencemanagement/language",
      user_id: this.state.user_id,
    });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              experience_id: this.state.experience_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    const { experienceTheme } = this.state;
    const { natureAndOutdoors } = this.state;
    return (
      <Fragment>
        <Breadcrumb parent="Experience Management / Theme" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>What type of experience will you host?</h5>
                </div>
                <div className="card-body">
                  <form className="form theme-form" id="themeForm">
                    <div className="form-group row">
                      <label
                        htmlFor="ApartmentDropDown"
                        className="col-sm-3 col-form-label"
                      >
                        Select a primary theme
                      </label>
                      <div className="col-sm-9">
                        <div className="card-body btn-showcase">
                          <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                            {experienceTheme.length > 0 &&
                              experienceTheme.map((item, key) => {
                                return (
                                  <div
                                    className="radio radio-primary"
                                    key={key}
                                  >
                                    <input
                                      id={item.id}
                                      type="radio"
                                      name="category"
                                      checked={
                                        this.state.experiencetheme_id ===
                                        item.id
                                          ? true
                                          : false
                                      }
                                      onChange={this.onChangeTheme.bind(
                                        this,
                                        item.id
                                      )}
                                    />
                                    <label className="mb-0" htmlFor={item.id}>
                                      {item.name}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="natureAndOutdoors"
                        className="col-sm-3 col-form-label"
                      >
                        What specifically in nature and outdoors?
                      </label>
                      <div className="col-sm-9">
                        <div className="card-body btn-showcase">
                          <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                            {natureAndOutdoors.length > 0 &&
                              natureAndOutdoors.map((item, key) => {
                                return (
                                  <div
                                    className="radio radio-primary"
                                    key={key}
                                  >
                                    <input
                                      id={item.id}
                                      type="radio"
                                      name="natureAndOutdoors"
                                      checked={
                                        this.state.natureAndOutdoors_id ===
                                        item.id
                                          ? true
                                          : false
                                      }
                                      onChange={this.onChangeNatureOutdoors.bind(
                                        this,
                                        item.id,
                                        item
                                      )}
                                    />
                                    <label className="mb-0" htmlFor={item.id}>
                                      {item.name}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-warning mr-1"
                        // onClick={() => {
                        //   this.setState({
                        //     redirect: "/propertiesmanagement/addproperties",
                        //   });
                        // }}
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        onClick={this.handleSubmit}
                      >
                        Update
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={this.nextBtnClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default IdeaTheme;
