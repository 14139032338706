import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      licenseType: "2",
      startTime: "11:30",
      experience_id: this.props.location.state
        ? this.props.location.state.experience_id
        : null,
      user_id: this.props.location.state
        ? this.props.location.state.user_id
        : null,
      callFrom: this.props.location.state
        ? this.props.location.state.callFrom
        : null,
      hostId: this.props.location.state
        ? this.props.location.state.hostId
        : null,
    };
  }

  componentDidMount() {
    if (this.state.experience_id !== null) {
      this.getEditData();
    }
  }

  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("experienceDetails"));
    console.log("resp", resp);

    if (resp["licenseType"] !== null) {
      // this.state.about = resp["aboutYourSelf"];
      _this.setState({
        licenseType: resp["licenseType"],
      });
    }
    
  }

  onChangelicenseType = (e) => {
    this.setState({
      licenseType: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};

    let experience_id = this.state.experience_id;
    let url = "experience/" + experience_id + "/review/";

    params["licenseType"] = this.state.licenseType;

    console.log("params", params);

    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem(
            "experienceDetails",
            JSON.stringify(response.data)
          );
          this.setState({
            experience_id: response.data.id,
            // redirect: "/experiencemanagement/ideatheme",
            user_id: response.data.host.id,
          });
          console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  nextBtnClick = (e) => {
    this.setState({
      experience_id: this.state.experience_id,
      redirect: "/experiencemanagement/experiencelist",
      user_id: this.state.user_id,
    });
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              experience_id: this.state.experience_id,
              user_id: this.state.user_id,
              hostId: this.state.hostId,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Experience Management / Review" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Last step to review</h5>
                  <h2>Just a few more questions before you submit</h2>
                </div>
                <div className="card-body">
                  <form className="form theme-form" id="reviewForm">
                    <div className="form-group row">
                      <label
                        htmlFor="licenseType"
                        className="col-sm-12 col-form-label"
                      >
                        Are you complying with local tourism laws and location
                        recquirements?
                      </label>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="licenseType"
                        className="col-sm-3 col-form-label"
                      >
                        Which of the following applies to you and the
                        experience?
                      </label>
                      <div className="col-sm-9">
                        <div className="form-group m-t-15 custom-radio-ml">
                          <div className="radio radio-primary row">
                            <input
                              id="radio11"
                              type="radio"
                              name="licenseType"
                              value={"1"}
                              checked={
                                this.state.licenseType === "1" ? true : false
                              }
                              onChange={this.onChangelicenseType}
                            />
                            <label htmlFor="radio11">
                              I have all necessary licenses, permits, or
                              permissions that apply to our guiding or tourist
                              services.
                            </label>
                          </div>
                          <div className="radio radio-secondary row">
                            <input
                              id="radio22"
                              type="radio"
                              name="licenseType"
                              value={"2"}
                              checked={
                                this.state.licenseType === "2" ? true : false
                              }
                              onChange={this.onChangelicenseType}
                            />
                            <label htmlFor="radio22">
                              No license, permit or permissions applying to tour
                              guideing or tourist services is recquired for me
                              to operate the experience.
                            </label>
                          </div>
                          <div className="radio radio-danger row">
                            <input
                              id="radio33"
                              type="radio"
                              name="licenseType"
                              value={"3"}
                              checked={
                                this.state.licenseType === "3" ? true : false
                              }
                              onChange={this.onChangelicenseType}
                            />
                            <label htmlFor="radio33">
                              I do not have the necessary licenses, permits, or
                              permissions recquired to conduct the experience.{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Review our policies before you submit to Hurraayy
                      </label>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Minimum guests
                      </label>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="verifyId"
                        className="col-sm-3 col-form-label"
                      >
                        By submitting, I confirm the following is true:
                      </label>
                      <div className="col-sm-9">
                        <div className="form-group m-t-15 ml-1">
                          <div className="checkbox">
                            <input id="checkbox1" type="checkbox" />
                            <label htmlFor="checkbox1">
                              I agree to comply with all applicable local food
                              safety recquirements. I understand that if
                              Hurraayy is made aware that my experience does not
                              comply with these guidelines and/or local food
                              safety recquirements, it may be removed from
                              Hurraayy.
                            </label>
                          </div>
                          <div className="checkbox">
                            <input id="checkbox2" type="checkbox" />
                            <label htmlFor="checkbox2">
                              I have read and understand Hurraay Food Safety
                              Guidelines, and I confirm that my experience
                              complies with them. When hosting, I agree to:
                              <ul>
                                <li>
                                  - Keep myself, my work area, and equipment
                                  clean
                                </li>
                                <li>- Separate raw and cook foods</li>
                                <li>
                                  - Cook meat and poultry to the appropriate
                                  temperature
                                </li>
                                <li>- Keep food at safe temperatures</li>
                                <li>- Use safe water and raw materials</li>
                              </ul>
                            </label>
                          </div>
                          <div className="checkbox">
                            <input
                              id="checkbox3"
                              type="checkbox"
                              defaultChecked
                            />
                            <label htmlFor="checkbox3">
                              My experience complies with local news.Learn more
                              about other laws (like business licensing) that
                              may apply
                            </label>
                          </div>
                          <div className="checkbox">
                            <input
                              id="checkbox4"
                              type="checkbox"
                              defaultChecked
                            />
                            <label htmlFor="checkbox4">
                              I confirm that my descriptions and photos are my
                              own and accurately reflect my experience.
                            </label>
                          </div>
                          <div className="checkbox">
                            <input
                              id="checkbox5"
                              type="checkbox"
                              defaultChecked
                            />
                            <label htmlFor="checkbox5">
                              I agree to Hurraayy Experience Additional Terms of
                              Service and Guest Refund Policy.
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-warning mr-1"
                        // onClick={() => {
                        //   this.setState({
                        //     redirect: "/propertiesmanagement/addproperties",
                        //   });
                        // }}
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        onClick={this.handleSubmit}
                      >
                        Update
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={this.nextBtnClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Review;
