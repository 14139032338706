import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import FullPageLoader from "../common/FullPageLoader/fullpageloader";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";

class PlaceSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyTypes: [],
      cities: [],
      placeAccommodatesCount: "",
      place_type_id: "",
      country_city_id: "",
      loading: false,
      property_id: this.props.location.state.property_id,
      user_id: this.props.location.state.user_id,
      callFrom: this.props.location.state.callFrom,
      hostId: this.props.location.state.hostId,
    };
  }
  showLoader() {
    this.setState({ loading: true });
  }
  hideLoader() {
    this.setState({ loading: false });
  }
  componentDidMount() {
    this.getPropertyTypes();
    this.getCities();
    if (this.state.callFrom === "AddPropertyForUserList") {
      this.props.location.state.property_id = 0;
      this.setState({property_id:0});
    }
    if (this.state.property_id != null && this.state.property_id !== 0) {
      this.getPropertyDetails();
    }
  }
  getPropertyTypes() {
    let _this = this;
    _this.showLoader();
    apiService
      .PropertyTypes()
      .then((response) => {
        //this.hideLoader();
        if (response && response.status === 200) {
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
              });
            }
          }
          _this.setState({
            propertyTypes: dataPush,
            //loading: false,
          });
          _this.hideLoader();
        }
      })
      .catch((error) => {
        _this.hideLoader();
      });
  }
  getCities() {
    let _this = this;
    apiService
      .cities()
      .then((response) => {
        //this.hideLoader();
        let dataList = response.data;
        let dataPush = [];
        if (dataList.length > 0) {
          for (let key in dataList) {
            let city_id = dataList[key]["id"];
            let country_id = dataList[key]["state"]["country"]["id"];
            let city = dataList[key]["name"];
            let country = dataList[key]["state"]["country"]["name"];
            dataPush.push({
              id: city_id + "_" + country_id,
              name: city + ", " + country,
            });
          }
        }
        _this.setState({
          cities: dataPush,
        });
      })
      .catch((error) => {});
  }
  getPropertyDetails() {
    let _this = this;
    _this.showLoader();
    apiService
      .getPropertyDetailsById(_this.state.property_id)
      .then((response) => {
        _this.closeLoader();
        if (response && response.status === 200) {
          localStorage.setItem("propDetails",JSON.stringify(response.data));
          this.setState({
            place_type_id: response.data.placeType.id,
            placeAccommodatesCount: response.data.placeAccommodatesCount,
            country_city_id:
              response.data.city.id + "_" + response.data.country.id,
          });
        }
      })
      .catch((error) => {});
  }
  resetForm = () => {
    document.getElementById("placeSettingForm").reset();
    //this.setState({ buttonNameChanged: false });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    let country_city_id = document
      .getElementById("country_city_id")
      .value.split("_");

    params["city_id"] = Number(country_city_id[0]);
    params["country_id"] = Number(country_city_id[1]);
    params["placeAccommodatesCount"] = document.getElementById(
      "placeAccommodatesCount"
    ).value;
    params["place_type_id"] = Number(
      document.getElementById("place_type_id").value
    );
    if (this.state.callFrom === "AddPropertyForUserList") {
      params["user_id"] = this.state.user_id;
      apiService
        .createProperty(params)
        .then((response) => {
          if (response && response.status === 201) {
            toast.success("Save Successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            localStorage.setItem("propDetails",JSON.stringify(response.data));
            this.setState({
              property_id:response.data.id,
              redirect: "/propertiesmanagement/placetype",
            });
          } else {
            toast.error(response.error_description, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error("Exception Occured!!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    } else {
      params["user_id"] = this.state.hostId;
      apiService
        .updateProperty(params, this.state.property_id)
        .then((response) => {
          if (response && response.status === 200) {
            toast.success("Updated Successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.setState({
              property_id:response.data.id,
              redirect: "/propertiesmanagement/placetype",
            });
          } 
          else if(response && response.status === 422){
            toast.warn("property already existing, name taken", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          else {
            toast.error(response.error_description, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((error) => {
          // loginState.closeLoader();
          toast.error(error.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
  };

  closeLoader() {
    this.setState({ loading: false });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              hostId: this.state.hostId,
              callFrom : this.state.callFrom,
            },
          }}
        />
      );
    }
    const propertyTypes = this.state.propertyTypes;
    const { cities } = this.state;
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Place Setting" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Choose What Kind of Place</h5>
                </div>
                <div className="card-body">
                  <form className="form theme-form" id="placeSettingForm">
                    <div className="form-group row">
                      <label
                        htmlFor="place_type_id"
                        className="col-sm-3 col-form-label"
                      >
                        Apartment
                      </label>
                      <div className="col-sm-9">
                        <select
                          value={this.state.place_type_id}
                          className="form-control digits"
                          id="place_type_id"
                          onChange={() => {
                            this.setState({
                              place_type_id: this.value,
                            });
                          }}
                        >
                          <option value="">Please select your property</option>
                          {propertyTypes.length > 0 &&
                            propertyTypes.map((item, key) => {
                              return (
                                <option key={key} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="placeAccommodatesCount"
                        className="col-sm-3 col-form-label"
                      >
                        No of Guest
                      </label>
                      <div className="col-sm-9">
                        <select
                          value={this.state.placeAccommodatesCount}
                          className="form-control digits"
                          id="placeAccommodatesCount"
                          onChange={() => {
                            this.setState({
                              placeAccommodatesCount: this.value,
                            });
                          }}
                        >
                          <option value="">Number of Guests</option>
                          {[...new Array(16)].map((item, key) => {
                            return (
                              <option key={key} value={key + 1}>
                                for {key + 1} {key === 0 ? "guest" : "guests"}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="country_city_id"
                        className="col-sm-3 col-form-label"
                      >
                        Select Location
                      </label>
                      <div className="col-sm-9">
                        <select
                          value={this.state.country_city_id}
                          className="form-control digits"
                          id="country_city_id"
                          onChange={() => {
                            this.setState({
                              country_city_id: this.value,
                            });
                          }}
                        >
                          <option value="">Please select location</option>
                          {cities.length > 0 &&
                            cities.map((item, key) => {
                              return (
                                <option key={key} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="card-footer">
                  <div className="col-sm-9 offset-sm-3">
                    <input
                      className="btn btn-light"
                      type="reset"
                      defaultValue="Cancel"
                      onClick={this.resetForm}
                    />
                    &nbsp;
                    <button
                      type="submit"
                      className="btn btn-primary mr-1"
                      onClick={this.handleSubmit}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
                <ToastContainer />
                {this.state.loading ? (
                  <FullPageLoader loading={this.state.loading} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PlaceSetting;
