import React, { Fragment,useEffect,useState } from 'react';
import man from '../../../assets/images/dashboard/user.png'
// import man from '../../../assets/images/habib-logo.jpg'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import {useSelector} from 'react-redux'
const UserPanel = () => {
    const [url, setUrl] = useState("");
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const user = useSelector((state) => state.Auth.profile);
    useEffect(() => {
        setUserName(`${user.firstName} ${user.lastName}`);
        setEmail(user.emailAddress);
        setUrl(user.profilePhotoPath);
    }, [])
    
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={url ? url : man} alt="#" />
                    <div className="profile-edit">
                        <Link to={`${process.env.PUBLIC_URL}/users/userEdit`}>
                            <Edit />
                        </Link>
                    </div>
                </div>
                <h6 className="mt-3 f-14">{userName}</h6>
                <p>{email}</p>
            </div>
        </Fragment>
    );
};

export default UserPanel;