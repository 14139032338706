import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

class AdventureBookingSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      howMuchAdvanceNoticeForAlreadyBook: "4",
      howMuchAdvanceNoticeForNoBook: "4",
      adventure_id: this.props.location.state
        ? this.props.location.state.adventure_id
        : null,
      user_id: this.props.location.state
        ? this.props.location.state.user_id
        : null,
      callFrom: this.props.location.state
        ? this.props.location.state.callFrom
        : null,
      hostId: this.props.location.state
        ? this.props.location.state.hostId
        : null,
    };
  }

  componentDidMount() {
    if (this.state.adventure_id !== null) {
      this.getEditData();
    }
  }

  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("adventureDetails"));
    console.log("resp", resp);

    if (resp["howMuchAdvanceNoticeForAlreadyBook"] !== null) {
      // this.state.about = resp["aboutYourSelf"];
      _this.setState({
        howMuchAdvanceNoticeForAlreadyBook: resp["howMuchAdvanceNoticeForAlreadyBook"],
      });
    }
    if (resp["howMuchAdvanceNoticeForNoBook"] !== null) {
      // this.state.about = resp["aboutYourSelf"];
      _this.setState({
        howMuchAdvanceNoticeForNoBook: resp["howMuchAdvanceNoticeForNoBook"],
      });
    }
  }

  onChangeAlreadyBook = (e) => {
    this.setState({
        howMuchAdvanceNoticeForAlreadyBook: e.target.value,
    });
  };
  onChangeNoBook = (e) => {
    this.setState({
        howMuchAdvanceNoticeForNoBook: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};

    let adventure_id = this.state.adventure_id;
    let url = "adventure/" + adventure_id + "/booking_setting/";

    params["howMuchAdvanceNoticeForAlreadyBook"] = this.state.howMuchAdvanceNoticeForAlreadyBook;
    params["howMuchAdvanceNoticeForNoBook"] = this.state.howMuchAdvanceNoticeForNoBook;

    console.log("params", params);

    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem(
            "adventureDetails",
            JSON.stringify(response.data)
          );
          this.setState({
            adventure_id: response.data.id,
            // redirect: "/experiencemanagement/ideatheme",
            user_id: response.data.host.id,
          });
          console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  nextBtnClick = (e) => {
    this.setState({
      adventure_id: this.state.adventure_id,
      redirect: "/advanturemanagement/adventurereview",
      user_id: this.state.user_id,
    });
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              adventure_id: this.state.adventure_id,
              user_id: this.state.user_id,
              hostId: this.state.hostId,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Adventure Management / Booking Settings" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Set your cutoff time</h5>
                </div>
                <div className="card-body">
                  <form className="form theme-form" id="groupSizeForm">
                    <div className="form-group row">
                      <label
                        htmlFor="howMuchAdvanceNoticeForAlreadyBook"
                        className="col-sm-3 col-form-label"
                      >
                        How much advance notice do you need for additional guest
                        booking when someone has already booked your adventure?
                      </label>
                      <div className="col-sm-9">
                        <select
                          value={this.state.howMuchAdvanceNoticeForAlreadyBook}
                          className="form-control digits"
                          id="howMuchAdvanceNoticeForAlreadyBook"
                          onChange={this.onChangeAlreadyBook}
                        >
                          <option value="">Please select time</option>
                          <option value="4">4 hour before start time </option>
                          <option value="8">8 hour before start time </option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="howMuchAdvanceNoticeForNoBook"
                        className="col-sm-3 col-form-label"
                      >
                         How much advance notice do you need for a new booking when no
                one has booked it yet?
                      </label>
                      <div className="col-sm-9">
                        <select
                          value={this.state.howMuchAdvanceNoticeForNoBook}
                          className="form-control digits"
                          id="howMuchAdvanceNoticeForNoBook"
                          onChange={this.onChangeNoBook}
                        >
                          <option value="">Please select time</option>
                          <option value="4">4 hour before start time </option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-warning mr-1"
                        // onClick={() => {
                        //   this.setState({
                        //     redirect: "/propertiesmanagement/addproperties",
                        //   });
                        // }}
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        onClick={this.handleSubmit}
                      >
                        Update
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={this.nextBtnClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default AdventureBookingSettings;
