import React, { Fragment, Component } from "react";
import Bedrooms from "./bedrooms";

class Basics extends Component {
  state={
    bedroomList: []
  }

  render() {
    return (
      <Fragment>
        <br/>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Bedrooms</label>
                    <div className="col-sm-9">
                      <fieldset className="qty-box">
                        <div className="input-group">
                          
                          <input
                            type="text"
                            name="quantity"
                            value={this.state.bedroomList.length}
                            readOnly={true}
                            className="form-control input-number text-center"
                          />
                          <span className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-primary mr-1"
                              //   onClick={() => incrementQty(item, 1)}
                              data-type="plus"
                              data-field=""
                              onClick={e => this.setState({
                                bedroomList: [ ...this.state.bedroomList, 'asdf']})}
                                >
                                  <i className="fa fa-plus-square"></i>
                            </button>
                          </span>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  
                  {
                    this.state.bedroomList.length>0 ? 
                      <div className="card">
                        <div className="card-header">
                            <h5>Bedroom List</h5>
                        </div>
                        <div className="card-body">
                          {
                            this.state.bedroomList.map((bedroom, index)=>{
                                return <div className="form-group row">
                                          <Bedrooms 
                                          key={index} />
                                        </div>
                                
                              })
                          }
                        </div>
                      </div> : null
                  }
                  
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Common Space
                    </label>
                    <div className="col-sm-9">
                      <button className="btn-primary">Add Beds</button>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Bathrooms</label>
                    <div className="col-sm-9">
                      <fieldset className="qty-box">
                        <div className="input-group">
                          <span className="input-group-prepend">
                            <button
                              type="button"
                              className="btn quantity-left-minus"
                              //   onClick={() => decrementQty(item.id)}
                              data-type="minus"
                              data-field=""
                            >
                              <i className="fa fa-angle-left"></i>
                            </button>
                          </span>
                          <input
                            type="text"
                            name="quantity"
                            value="1"
                            readOnly={true}
                            className="form-control input-number"
                          />
                          <span className="input-group-append">
                            <button
                              type="button"
                              className="btn quantity-right-plus"
                              //   onClick={() => incrementQty(item, 1)}
                              data-type="plus"
                              data-field=""
                            >
                              <i className="fa fa-angle-right"></i>
                            </button>
                          </span>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Are any of the Bathrooms private?
                    </label>
                    <div className="col-sm-9">
                      <div className="col-sm-9">
                        <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                          <div className="radio radio-primary">
                            <input
                              id="radioinline1"
                              type="radio"
                              name="radio3"
                              value="option1"
                            />
                            <label className="mb-0" for="radioinline1">
                              Yes
                            </label>
                          </div>
                          <div className="radio radio-primary">
                            <input
                              id="radioinline2"
                              type="radio"
                              name="radio3"
                              value="option1"
                            />
                            <label className="mb-0" for="radioinline2">
                              No, they're shared
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="exampleFormControlSelect9"
                      className="col-sm-3 col-form-label"
                    >
                      Property Type
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control digits"
                        id="exampleFormControlSelect9"
                        defaultValue="1"
                      >
                        <option>Admin</option>
                        <option>Sub-Admin</option>
                        <option>Accountant</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col">
                <div className="form-group row">
                    <label
                      htmlFor="exampleFormControlSelect9"
                      className="col-sm-3 col-form-label"
                    >
                      Room Type
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control digits"
                        id="exampleFormControlSelect9"
                        defaultValue="1"
                      >
                        <option>Admin</option>
                        <option>Sub-Admin</option>
                        <option>Accountant</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="exampleFormControlSelect9"
                      className="col-sm-3 col-form-label"
                    >
                      Accomodations
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control digits"
                        id="exampleFormControlSelect9"
                        defaultValue="1"
                      >
                        <option>Admin</option>
                        <option>Sub-Admin</option>
                        <option>Accountant</option>
                      </select>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Basics;
