import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import FullPageLoader from "../common/FullPageLoader/fullpageloader";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Collapse, Button, CardBody, Card } from "reactstrap";

class ExperienceTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
        title:"",
      loading: false,
      collapse: true,
      experience_id: this.props.location.state
        ? this.props.location.state.experience_id
        : null,
      user_id: this.props.location.state
        ? this.props.location.state.user_id
        : null,
      callFrom: this.props.location.state
        ? this.props.location.state.callFrom
        : null,
      hostId: this.props.location.state
        ? this.props.location.state.hostId
        : null,
    };
  }

  componentDidMount() {
    if (this.state.experience_id !== null) {
       this.getEditData();
    }
  }
  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("experienceDetails"));
    console.log("resp", resp);
    if (resp["title"] !== null) {
        this.setState({
          title: resp["title"],
        });
      }
  }
  showLoader() {
    this.setState({ loading: true });
  }

  closeLoader() {
    this.setState({ loading: false });
  }

  toggle = (e) => {
    this.state.collapse
      ? this.setState({
          collapse: false,
        })
      : this.setState({
          collapse: true,
        });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    let _this = this;
    let experience_id = this.state.experience_id;

    let url = "experience/" + experience_id + "/title";
    
    params["title"] = this.state.title;

    console.log("param", params);
    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem(
            "experienceDetails",
            JSON.stringify(response.data)
          );
          this.setState({
            experience_id: response.data.id,
            // redirect: "/experiencemanagement/ideatheme",
            user_id: response.data.host.id,
          });
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  nextBtnClick = (e) => {
    this.setState({
      experience_id: this.state.experience_id,
      redirect: "/experiencemanagement/experiencephotos",
      user_id: this.state.user_id,
    });
  };
  onChangeTitle = (e) => {
    this.setState({
      title: e.target.value,
    });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              experience_id: this.state.experience_id,
              user_id: this.state.user_id,
              hostId: this.state.hostId,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Experience Management / Language" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Give your experience a title</h5>
                  <p>Give it short, descriptive, and exciting</p>
                </div>
                <div className="card-body">
                  <form className="form theme-form" id="locationForm">
                    <div className="form-group row">
                      <Button
                        color="ff6204"
                        onClick={this.toggle}
                        style={{ marginBottom: "1rem", color: "#ff6204" }}
                      >
                        Tips
                      </Button>
                      <Collapse isOpen={this.state.collapse ? true : false}>
                        <Card className="mb-0">
                          <CardBody>
                            <p>
                              <i className="fa fa-angle-double-right"></i>
                              Think about writing a title that describes your
                              main activity so guests get a sense of what
                              they’ll be doing. Consider using action verbs and
                              making it unique to set your experience apart.
                            </p>
                            <p>
                              <i className="fa fa-angle-double-right"></i>
                              Heighlight the culture, heritage, or region of the
                              dish you’re cooking together.
                            </p>
                          </CardBody>
                        </Card>
                      </Collapse>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="title"
                        className="col-sm-3 col-form-label"
                      >
                        What’s the title of your experience?
                      </label>
                      <div className="col-sm-9 input-group">
                        <input
                          className="form-control"
                          id="title"
                          type="text"
                          value={this.state.title}
                          placeholder="Title here..."
                          autoComplete="off"
                          onChange={this.onChangeTitle}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-warning mr-1"
                        // onClick={() => {
                        //   this.setState({
                        //     redirect: "/propertiesmanagement/addproperties",
                        //   });
                        // }}
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        onClick={this.handleSubmit}
                      >
                        Update
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={this.nextBtnClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {this.state.loading ? (
            <FullPageLoader loading={this.state.loading} />
          ) : null}
        </div>
      </Fragment>
    );
  }
}
export default ExperienceTitle;
