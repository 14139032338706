import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

var currentState = "";
class Notes extends Component {
  state = {
    additional: "",
    shine: false,
    experience_id: this.props.location.state
      ? this.props.location.state.experience_id
      : null,
    user_id: this.props.location.state
      ? this.props.location.state.user_id
      : null,
    callFrom: this.props.location.state
      ? this.props.location.state.callFrom
      : null,
    hostId: this.props.location.state ? this.props.location.state.hostId : null,
  };

  componentDidMount() {
    currentState = this;
    if (this.state.experience_id !== null) {
      this.getEditData();
    }
  }

  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("experienceDetails"));
    console.log("resp", resp);
    if (resp["anythingShouldKnow"] !== null) {
      // this.state.about = resp["aboutYourSelf"];
      _this.setState({
        shine: resp["anythingShouldKnow"],
      });
    }
    if (resp["additionalNote"] !== null) {
        // this.state.about = resp["aboutYourSelf"];
        _this.setState({
          additional: resp["additionalNote"],
        });
      }

    // console.log("saved ans", this.state.savedAnswers);
  }

  onChangeAdditional = (e) => {
    let targetValue = e.target.value;
    this.setState({
      additional: targetValue,
    });
    // console.log("about", this.state.about);
  };

  handleShineChange(e) {
    currentState.state.shine
      ? currentState.setState({
          shine: false,
        })
      : currentState.setState({
          shine: true,
        });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};

    let experience_id = this.state.experience_id;
    let url = "experience/" + experience_id + "/notes/";

    params["additionalNote"] = this.state.additional;
    params["anythingShouldKnow"] = this.state.shine;
    params["guestKnowBeforebook"] = "";

    console.log("params", params);

    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem(
            "experienceDetails",
            JSON.stringify(response.data)
          );
          this.setState({
            experience_id: response.data.id,
            // redirect: "/experiencemanagement/ideatheme",
            user_id: response.data.host.id,
          });
          console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  nextBtnClick = (e) => {
    this.setState({
      experience_id: this.state.experience_id,
      redirect: "/experiencemanagement/guestrequirements",
      user_id: this.state.user_id,
    });
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              experience_id: this.state.experience_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    const { additional } = this.state;
    return (
      <Fragment>
        <Breadcrumb parent="Experience Management / Notes" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>What else do guests need to know before they book?</h5>
                </div>
                <div className="card-body">
                  <form className="form theme-form" id="aboutyouForm">
                    <div className="form-group row">
                      {/* <label className="col-sm-3 col-form-label">
                        Describe yourself and your qualifications to guests
                      </label> */}
                      <div className="col-sm-12">
                        <textarea
                          className="form-control"
                          rows="5"
                          cols="5"
                          placeholder="This is your time to shine. Don’t be afraid to brag."
                          value={this.state.additional}
                          onChange={this.onChangeAdditional}
                        ></textarea>
                      </div>
                    </div>
                    <div className="form-group row">
                      <h6 className="col-sm-6 col-form-label">
                        <small>
                          Is there anything else guests should know?{" "}
                        </small>
                      </h6>
                    </div>
                    <div className="form-group row">
                      <input
                        className="checkbox_animated"
                        type="checkbox"
                        value={this.state.shine}
                        checked={this.state.shine ? true : false}
                        onChange={this.handleShineChange}
                      />
                      <span className="label-text">
                        I have no additional notes for my guests{" "}
                      </span>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-warning mr-1"
                        // onClick={() => {
                        //   this.setState({
                        //     redirect: "/propertiesmanagement/addproperties",
                        //   });
                        // }}
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        onClick={this.handleSubmit}
                      >
                        Update
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={this.nextBtnClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Notes;
