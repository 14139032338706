import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import apiService from "../../apiservices/apiservice";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

class PriceSpace extends Component {
  state = {
    price: "",
    currency_id: "",
    currencies: [],
    visible: false,
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
  };
  componentDidMount() {
    this.getCurrency();
    console.log("propid", this.state.property_id);
    if (this.state.property_id !== undefined) {
      this.getEditData();
    }
  }
  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("propDetails"));
    if (resp) {
      if (resp["price"]) {
        this.setState({
          price: resp["price"],
        });
      }
      if (resp["currency"] && resp["currency"]["id"]) {
        this.setState({
          currency_id: resp["currency"]["id"],
        });
      }
    }
  }
  getCurrency() {
    let _this = this;
    apiService
      .getCurrencyList()
      .then((response) => {
        if (response && response.status === 200) {
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
              });
            }
          }
          _this.setState({
            currencies: dataPush,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  nextBtnClick = (e) => {
    this.setState({
      // redirect: "/propertiesmanagement/specialdiscount",
      redirect: "/propertiesmanagement/propertiescompletion",
      property_id: this.state.property_id,
      user_id: this.state.user_id,
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/calendar",
      user_id: this.state.user_id
    });
  }
  onChangePrice = (e) => {
    this.setState({
      price: e.target.value,
    });
  };
  onChangeCurrency = (e) => {
    this.setState({
      currency_id: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    let _this = this;
    let property_id = this.state.property_id;
    let url = "properties/" + property_id + "/price";
    params["currency_id"] = this.state.currency_id;
    params["price"] = this.state.price;
    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem("propDetails", JSON.stringify(response.data));
          this.setState({
            property_id: response.data.id,
            // redirect: "/propertiesmanagement/propertylist",
            user_id: response.data.user.id,
          });
          // console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    const currencies = this.state.currencies;
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Pricing" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Let's set the price for your space</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <label
                        htmlFor="exampleFormControlSelect9"
                        className="col-sm-3 col-form-label"
                      >
                        Standard price
                      </label>
                      <div className="col-sm-9 input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            $
                          </span>
                        </div>
                        <input
                          className="form-control"
                          id="exampleInputPassword2"
                          type="number"
                          value={this.state.price}
                          placeholder={15}
                          onChange={this.onChangePrice}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="currency_id"
                        className="col-sm-3 col-form-label"
                      >
                        Currency
                      </label>
                      <div className="col-sm-9">
                        <select
                          value={this.state.currency_id}
                          className="form-control digits"
                          id="currency_id"
                          onChange={this.onChangeCurrency}
                        >
                          <option value="">Select One</option>
                          {currencies.length > 0 &&
                            currencies.map((item, key) => {
                              return (
                                <option key={key} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Fragment>
    );
  }
}

export default PriceSpace;
