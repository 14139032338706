import React, { Component, Fragment } from "react";
import Lightbox from "react-image-lightbox";
import { $axios } from "../../apiservices/apiservice";
import { toast } from "react-toastify";
class Photos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PhotoObject: [],
      isOpen: false,
      photoIndex: 0,
      propertyId: props.property_id != null ? props.property_id : null,
      id: 0,
    };
    this.onDrop = this.onDrop.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }

  componentDidMount() {
    let property_id = this.state.propertyId;
    let url = "properties_photo/property/" + property_id;
    $axios
      .get(url)
      .then((response) => {
        this.setState({
          PhotoObject: response.data,
        });
      })
      .catch((error) => { });
  }
  onDrop(e) {
    var SelectedFiles = e.target.files;
    let imageObject = [];
    for (var i = 0; i < SelectedFiles.length; i++) {
      var obj = {
        id: null,
        photo: SelectedFiles[i],
        captions: "",
        photoPath: URL.createObjectURL(SelectedFiles[i]),
        property_id: null,
      };
      imageObject.push(obj);
    }
    this.setState({
      PhotoObject: this.state.PhotoObject.concat(imageObject),
    });
  }

  removeImage = async (index, id) => {
    if (id > 0) {
      let res = await this.imgDelte(id);
      if (res) {
        this.setState({
          PhotoObject: this.state.PhotoObject.filter((el) => el.id !== id),
        });
        toast.warn("Image deleted!");
      }
    } else {
      this.setState({
        PhotoObject: this.state.PhotoObject.filter(
          (el) => el.photoPath !== index
        ),
      });
      toast.warn("Image removed!");
    }
  };

  imgDelte = async (id) => {
    let url = "properties_photo/" + id;
    return await $axios.delete(url);
  };

  handleUpload = async (e) => {
    e.preventDefault();
    for (var i = 0; i < this.state.PhotoObject.length; i++) {
      try {
        let uploaded = await this.imageUpload(this.state.PhotoObject[i], i);
        console.log(uploaded);
      } catch (err) {
        toast.error("Faild to save image");
      }
    }
    toast.success("Success!");
  };

  imageUpload = async (data, i) => {
    let property_id = this.state.propertyId;
    let formData = new FormData();
    if (!data.photo) {
      await fetch(data.photoPath, { mode: "no-cors" })
        .then((res) => res.blob())
        .then((blob) => {
          var file = new File([blob], "Image");
          formData.append("files", file);
        });
    } else {
      formData.append("files", data.photo);
    }

    formData.append("captions", data.captions);
    formData.append("isCover", i === 0 ? true : false);
    formData.append("id", this.state.id);

    let url = "";
    

    if (data.id > 0) {
      url = "properties_photo/" + data.id + "/property/" + property_id;
      //return await $axios.put(url, formData);
    } else {
      url = "properties_photo/property/" + property_id;
      //return await $axios.post(url, formData);
    }
    return await $axios.post(url, formData);
  };


  handaleChanges = (index, e) => {
    var caption = e.target.value;
    let images = this.state.PhotoObject;
    images[index].captions = caption;
    this.setState({
      PhotoObject: images,
    });
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Image Upload</h5>
                </div>
                <div className="card-body">
                  <div className="imageContainer mt-4 mb-5">
                    <input
                      type="file"
                      className="btnU"
                      id="upload"
                      hidden
                      multiple
                      onChange={this.onDrop}
                    />
                    <label
                      className="btn btn-primary p-2 pr-4 pl-4 btnU"
                      for="upload"
                    >
                      <i class="fa fa-upload" aria-hidden="true"></i>
                      <br></br>Upload Image
                    </label>
                  </div>

                  <div className="my-gallery card-body row gallery-with-description">
                    {this.state.PhotoObject.map((obj, index) => {
                      return (
                        <figure className="col-xl-3 col-sm-6 imageContainer">
                          <div className="">
                            <button
                              onClick={() =>
                                this.removeImage(obj.photoPath, obj.id)
                              }
                              className="btnx"
                            >
                              <i className="icofont icofont-trash "></i>
                            </button>
                          </div>
                          <img
                            src={obj.photoPath}
                            alt="Gallery"
                            className="img-thumbnail"
                            onClick={() =>
                              this.setState({ photoIndex: index, isOpen: true })
                            }
                          />
                          <div className="caption">
                            <textarea
                              className="form-control"
                              key={index.toString()}
                              value={obj.captions}
                              id={index}
                              onChange={(e) => {
                                this.handaleChanges(index, e);
                              }}
                            >
                              {obj.captions}
                            </textarea>
                          </div>
                        </figure>
                      );
                    })}
                  </div>
                </div>
                <div class="card-footer">
                  <div>
                    <button onClick={this.handleUpload} class="btn btn-primary">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={this.state.PhotoObject[photoIndex].photoPath}
            nextSrc={
              this.state.PhotoObject[
                (photoIndex + 1) % this.state.PhotoObject.length
              ].photoPath
            }
            prevSrc={
              this.state.PhotoObject[
                (photoIndex + this.state.PhotoObject.length - 1) %
                this.state.PhotoObject.length
              ].photoPath
            }
            imageTitle={photoIndex + 1 + "/" + this.state.PhotoObject.length}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + this.state.PhotoObject.length - 1) %
                  this.state.PhotoObject.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.state.PhotoObject.length,
              })
            }
          />
        )}
      </Fragment>
    );
  }
}

export default Photos;
