import React, { Component } from "react";
import logo from "../../assets/images/hurraayy-logo.png";
import { Route, Switch, Redirect } from "react-router-dom";
import apiService from "../../apiservices/apiservice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FullPageLoader from "../../components/common/FullPageLoader/fullpageloader";
import { setLoginToken, setProfile } from "../../store/actions/auth.actions";
import { connect } from "react-redux";
import axios from "axios";
import { APP_API_BASE_URL } from "../../lib/constants";
var loginState = "";
class LoginForm extends Component {
    
	constructor(props) {
		super(props);
		this.state = {
			hidden: true,
			loading: false,
			loggedIn: false,
		};
		this.toggleShow = this.toggleShow.bind(this);
		loginState = this;
	}

	showLoader() {
		this.setState({ loading: true });
	}
	closeLoader() {
		this.setState({ loading: false });
	}

	handleSubmit=(e)=> {
		e.preventDefault();
		let email = document.getElementById("email").value; //values["email"];
		let password = document.getElementById("password").value; //values["password"];

		loginState.showLoader();
		apiService
			.signin(email, password)
			.then((response) => {
				loginState.closeLoader();
				if (response && response.status === 200) {
					const authorities = response.data.authorities;
					// if (authorities && authorities.length > 0) {
					// 	var roles = ["admin", "management"];
					// }
					var result = authorities.some((ele) => {return ele.authority === "management";});
                    if (result) {
                        var token = response.data.access_token;
                        var _axios = axios.create();
                        _axios({
                            method: "GET",
                            url: APP_API_BASE_URL + "account/current-user",
                            headers: {
                                "content-type": "application/json",
                                Authorization:
                                    "bearer " + response.data.access_token,
                            },
                        }).then((response) => {
                            // console.log(response);
                            var obj = { profile: response.data, token: token };
                            this.props.dispatch(setProfile(obj));
                            localStorage.setItem(
                                "appID",
                                response.data.access_token
                            );
                            //this.props.setToken(response.data.access_token);

                            toast.success("Login Successfully", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
							loginState.setState({ loggedIn: true });
                        });
                       
                        //this.props.dispatch(setLoginToken(response.data.access_token));
					} else {
						toast.warn("You don't have permission to access this site", {
							position: toast.POSITION.BOTTOM_RIGHT,
						});
					}
					
				}
			})
			.catch((error) => {
				loginState.closeLoader();
				// toast.error(error.response.data.error_description, {
				//   position: toast.POSITION.BOTTOM_RIGHT,
				// });
				// console.log(error.response.data.error_description);
			});
	}

	toggleShow() {
		this.setState({ hidden: !this.state.hidden });
	}

	render() {
		if (loginState.state.loggedIn) {
			return (
				<Switch>
					<Redirect to='/dashboard' />
				</Switch>
			);
		}
		return (
			<div>
				<div className='page-wrapper'>
					<div className='container-fluid p-0'>
						{/* <!-- login page start--> */}
						<div className='authentication-main'>
							<div className='row'>
								<div className='col-md-12'>
									<div className='auth-innerright'>
										<div className='authentication-box'>
											{/* <div className="text-center">
                        <img className="img-60" src={logo} alt="" />
                        </div> */}
											<div className='card mt-4'>
												<div className='card-body'>
													<div className='text-center'>
														<img className='img-60' src={logo} alt='' />
														<h4>LOGIN</h4>
														<h6>Enter your User Name and Password </h6>
														<ToastContainer />
													</div>
													<form
														className='theme-form'
														onSubmit={this.handleSubmit}
													>
														<div className='form-group'>
															<label className='col-form-label pt-0'>
																User Name
															</label>
															<input
																className='form-control'
																type='text'
																id='email'
																required
															/>
														</div>
														<div className='form-group'>
															<label className='col-form-label'>Password</label>
															<div className='input-group'>
																<input
																	className='form-control'
																	required
																	id='password'
																	type={this.state.hidden ? "password" : "text"}
																/>
																<div
																	className='input-group-prepend'
																	onClick={this.toggleShow}
																>
																	<span
																		className='input-group-text'
																		id='inputGroupPrepend'
																	>
																		<i
																			className={
																				this.state.hidden
																					? "fa fa-eye-slash"
																					: "fa fa-eye"
																			}
																		></i>
																	</span>
																</div>
															</div>
														</div>
														{this.state.loading ? (
															<FullPageLoader loading={this.state.loading} />
														) : null}
														<div className='form-group form-row mt-3 mb-0'>
															<button
																className='btn btn-primary btn-block'
																type='submit'
															>
																Login
															</button>
														</div>
														{/* <div className="login-divider"></div> */}
														{/* <div className="social mt-3">
                                <div className="form-group btn-showcase d-flex">
                                <button className="btn social-btn btn-fb d-inline-block">
                                    {" "}
                                    <i className="fa fa-facebook"></i>
                                </button>
                                <button className="btn social-btn btn-twitter d-inline-block">
                                    <i className="fa fa-google"></i>
                                </button>
                                <button className="btn social-btn btn-google d-inline-block">
                                    <i className="fa fa-twitter"></i>
                                </button>
                                <button className="btn social-btn btn-github d-inline-block">
                                    <i className="fa fa-github"></i>
                                </button>
                                </div>
                            </div> */}
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* <!-- login page end--> */}
					</div>
				</div>
			</div>
		);
	}
}

// const mapState = (state) => {
//   return { userInfo: state.Login };
// };
// export default withRouter(LoginForm);
//export default connect(null, mapState)(LoginForm);
//export default LoginForm;
const mapDispatchToProps = (dispatch) => {
    return {
			setToken: (token) => dispatch(setLoginToken(token)),
		};
};
export default connect(mapDispatchToProps)(LoginForm);