import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

class PlaceType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      subcategories: [],
      propertyTypes: [],
      category_id: 7,
      sub_category_id: 8,
      property_type_id: 1,
      is_dedicated_space: 1,
      is_listing_part_of_a_company: 1,
      loading: true,
      property_id: this.props.location.state.property_id,
      user_id: this.props.location.state.user_id,
      hostId: this.props.location.state.hostId,
      callFrom: this.props.location.callFrom,
    };
    // console.log("propid",this.state.property_id);
    // console.log("user_id",this.state.user_id);
    // console.log("hostId",this.state.hostId);
  }
  componentDidMount() {
    this.getCategory();
    this.getSubCategory();
    this.getPropertyTypes();
    if (this.state.property_id !== undefined) {
      this.getPropertyDetails();
    }
    // this.getEditData();
  }
  getPropertyDetails() {
    let _this = this;
    var propDetails = JSON.parse(localStorage.getItem("propDetails"));
    // console.log("propD", propDetails);

    if (propDetails["category"] && propDetails["category"]["id"]) {
      this.setState({
        category_id: propDetails["category"]["id"],
      });
      this.onChangeCategory(propDetails["category"]["id"]);
    }
    if (propDetails["subCategory"] && propDetails["subCategory"]["id"]) {
      this.setState({
        sub_category_id: propDetails["subCategory"]["id"],
      });
    }

    if (propDetails["propertyType"] && propDetails["propertyType"]["id"]) {
      this.setState({
        property_type_id: propDetails["propertyType"]["id"],
      });
    }
    if (propDetails["isDedicatedGuestSpace"] != null) {
      this.setState({
        is_dedicated_space: propDetails["isDedicatedGuestSpace"],
      });
    }
    if (propDetails["isListingPartOfCompany"] != null) {
      this.setState({
        is_listing_part_of_a_company: propDetails["isListingPartOfCompany"],
      });
    }
  }
  getCategory() {
    let _this = this;
    apiService
      .categoryList()
      .then((response) => {
        if (response && response.status === 200) {
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
              });
            }
          }
          _this.setState({
            categories: dataPush,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getSubCategory() {
    let _this = this;
    apiService
      .subcategoryList()
      .then((response) => {
        if (response && response.status === 200) {
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
              });
            }
          }
          _this.setState({
            subcategories: dataPush,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getPropertyTypes() {
    let _this = this;
    apiService
      .PropertyTypes()
      .then((response) => {
        if (response && response.status === 200) {
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
              });
            }
          }
          _this.setState({
            propertyTypes: dataPush,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onChangeCategory(id) {
    this.setState({
      category_id: id,
    });
  }
  onChangeSubCategory(id) {
    this.setState({
      sub_category_id: id,
    });
  }
  onChangePropertyType(id) {
    this.setState({
      property_type_id: id,
    });
  }
  onChangeDedicatedSpace(id) {
    this.setState({
      is_dedicated_space: id,
    });
  }
  onChangeListPart(id) {
    this.setState({
      is_listing_part_of_a_company: id,
    });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const next = this.props.continue;
    let params = {};
    let _this = this;
    let property_id = this.state.property_id;
    let url = "properties/" + property_id + "/room";
    params["category_id"] = this.state.category_id;
    // params["is_dedicated_space"] = this.state.is_dedicated_space;
    // params[
    //   "is_listing_part_of_a_company"
    // ] = this.state.is_listing_part_of_a_company;
    params["property_type_id"] = this.state.property_type_id;
    params["sub_category_id"] = this.state.sub_category_id;

    // console.log("new prop id", this.state.property_id);
    apiService
      .updateRoom(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem("propDetails", JSON.stringify(response.data));
          this.setState({
            property_id: response.data.id,
            // redirect: "/propertiesmanagement/bedroom-design",
            user_id: response.data.user.id,
          });
          // console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  nextBtnClick = (e) => {
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/bedroom-design",
      user_id: this.state.user_id
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/placesetting",
      user_id: this.state.user_id
    });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    const { categories, subcategories, propertyTypes } = this.state;
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Place Type" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>What type of property you are listing?</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <label
                        htmlFor="ApartmentDropDown"
                        className="col-sm-3 col-form-label"
                      >
                        Please select a category!
                      </label>
                      <div className="col-sm-9">
                        <div className="card-body btn-showcase">
                          <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                            {categories.length > 0 &&
                              categories.map((item, key) => {
                                return (
                                  <div
                                    className="radio radio-primary"
                                    key={key}
                                  >
                                    <input
                                      id={item.id}
                                      type="radio"
                                      name="category"
                                      checked={
                                        this.state.category_id === item.id
                                          ? true
                                          : false
                                      }
                                      onChange={this.onChangeCategory.bind(
                                        this,
                                        item.id
                                      )}
                                    />
                                    <label className="mb-0" htmlFor={item.id}>
                                      {item.name}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="ApartmentDropDown"
                        className="col-sm-3 col-form-label"
                      >
                        Let's choose a property type
                      </label>
                      <div className="col-sm-9">
                        <div className="card-body btn-showcase">
                          <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                            {subcategories.length > 0 &&
                              subcategories.map((item, key) => {
                                return (
                                  <div
                                    className="radio radio-primary"
                                    key={key}
                                  >
                                    <input
                                      id={item.id}
                                      type="radio"
                                      name="subcategory"
                                      checked={
                                        this.state.sub_category_id === item.id
                                          ? true
                                          : false
                                      }
                                      onChange={this.onChangeSubCategory.bind(
                                        this,
                                        item.id
                                      )}
                                    />
                                    <label className="mb-0" htmlFor={item.id}>
                                      {item.name}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="ApartmentDropDown"
                        className="col-sm-3 col-form-label"
                      >
                        More precisely your guests will have:
                      </label>
                      <div className="col-sm-9">
                        <div className="card-body btn-showcase">
                          <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                            {propertyTypes.length > 0 &&
                              propertyTypes.map((item, key) => {
                                return (
                                  <div
                                    className="radio radio-primary"
                                    key={key}
                                  >
                                    <input
                                      id={item.id}
                                      type="radio"
                                      name="propertyTypes"
                                      checked={
                                        this.state.property_type_id === item.id
                                          ? true
                                          : false
                                      }
                                      onChange={this.onChangePropertyType.bind(
                                        this,
                                        item.id
                                      )}
                                    />
                                    <label className="mb-0" htmlFor={item.id}>
                                      {item.name}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group row">
                      <label
                        htmlFor="ApartmentDropDown"
                        className="col-sm-3 col-form-label"
                      >
                        Is this setup as a dedicated guest space?
                      </label>
                      <div className="col-sm-9">
                        <div className="card-body btn-showcase">
                          <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                            <div className="radio radio-primary">
                              <input
                                id="is_dedicated_space1"
                                type="radio"
                                name="is_dedicated_space"
                                checked={
                                  this.state.is_dedicated_space === 1
                                    ? true
                                    : false
                                }
                                onChange={this.onChangeDedicatedSpace.bind(
                                  this,
                                  1
                                )}
                              />
                              <label
                                className="mb-0"
                                htmlFor="is_dedicated_space1"
                              >
                                Yes, it’s primarily setup for guests
                              </label>
                            </div>
                            <div className="radio radio-primary">
                              <input
                                id="is_dedicated_space0"
                                type="radio"
                                name="is_dedicated_space"
                                checked={
                                  this.state.is_dedicated_space === 0
                                    ? true
                                    : false
                                }
                                onChange={this.onChangeDedicatedSpace.bind(
                                  this,
                                  0
                                )}
                              />
                              <label
                                className="mb-0"
                                htmlFor="is_dedicated_space0"
                              >
                                No, it’s primarily setup for guests
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group row">
                      <label
                        htmlFor="ApartmentDropDown"
                        className="col-sm-3 col-form-label"
                      >
                        Are you listing on Hurraayy as part of a company?
                      </label>
                      <div className="col-sm-9">
                        <div className="card-body btn-showcase">
                          <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                            <div className="radio radio-primary">
                              <input
                                id="is_listing_part_of_a_company1"
                                type="radio"
                                name="is_listing_part_of_a_company"
                                checked={
                                  this.state.is_listing_part_of_a_company === 1
                                    ? true
                                    : false
                                }
                                onChange={this.onChangeListPart.bind(this, 1)}
                              />
                              <label
                                className="mb-0"
                                htmlFor="is_listing_part_of_a_company1"
                              >
                                Yes, I work for or run a business
                              </label>
                            </div>
                            <div className="radio radio-primary">
                              <input
                                id="is_listing_part_of_a_company0"
                                type="radio"
                                name="is_listing_part_of_a_company"
                                checked={
                                  this.state.is_listing_part_of_a_company === 0
                                    ? true
                                    : false
                                }
                                onChange={this.onChangeListPart.bind(this, 0)}
                              />
                              <label
                                className="mb-0"
                                htmlFor="is_listing_part_of_a_company0"
                              >
                                No, that doesn’t sound like me
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Fragment>
    );
  }
}

export default PlaceType;
