
import React, { Component, Fragment } from "react";
import Description from "./desciption";
import Photos from "./photos";
import Amenites from "./amenities";
import Spaces from "./spaces";
import Breadcrumb from "../common/breadcrumb";

class DescriptionMaster extends Component {
  state = {
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
  };

  render() {
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Description" />
        <div className="row">
          <Amenites property_id={this.state.property_id} user_id={this.state.user_id} callFrom={this.state.callFrom} />
          
          <Photos property_id={this.state.property_id} user_id={this.state.user_id} callFrom={this.state.callFrom} />
          <Description property_id={this.state.property_id} user_id={this.state.user_id} callFrom={this.state.callFrom} />
          <Spaces property_id={this.state.property_id} user_id={this.state.user_id} callFrom={this.state.callFrom} />
          
          
          <div className="col-sm-6 col-md-6 col-lg-6"></div>
          <div className="col-sm-6 col-md-6 col-lg-6"></div>
        </div>
      </Fragment>
    );
  }
}

export default DescriptionMaster;
// export const DescriptionMaster = () => {
//     // const prop = props != null ? props : null;
//     return (

//     );
// };

// export default DescriptionMaster;
