import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import apiService from "../../apiservices/apiservice";
import "jspdf-autotable";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import four from "../../assets/images/avtar/3.jpg";

class ExpDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      country_city_id: "",
      loading: false,
      redirect:null,
      reservation_id: this.props.location.state
        ? this.props.location.state.property_id
        : null,
      user_id: this.props.location.state
        ? this.props.location.state.user_id
        : null,
      callFrom: this.props.location.state
        ? this.props.location.state.callFrom
        : null,
      hostId: this.props.location.state
        ? this.props.location.state.hostId
        : null,
      selectedData: this.props.location.state
        ? this.props.location.state.selected_data
        : null,
      details: "",
      guest: "",
      experienceVO: "",
      experienceCalenderVO: "",
      currency: "",
      host: "",
      hostAccount:"",
      guestAccount:""
    };
  }
  componentDidMount() {
    if (this.state.reservation_id > 0) {
      this.getDetails();
    }
  }
  getDetails() {
    let _this = this;
    let url = "booking/experience/" + this.state.reservation_id;
    apiService
      .get_mng_api(url)
      .then((response) => {
        // details = response.data.guest;
        _this.setState({
          details: response.data,
          guest: response.data.guest,
          experienceCalenderVO: response.data.experienceCalenderVO,
          experienceVO: response.data.experienceVO,
          currency: response.data.currency,
          host: response.data.host,
          guestAccount:response.data.guestAccount,
          hostAccount:response.data.hostAccount
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            _this.setState({ redirect: "/pages/authentication/login" });
            toast.error("Session Expired", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      });
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Reservation Management / Experience Reservation Details" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <div className="card custom-card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <h5>Guest & Booking Details</h5>
                    </div>
                  </div>
                </div>
                <div className="card-profile">
                  <img className="rounded-circle" src={four} alt="" />
                </div>
                <div className="text-center profile-details">
                  <h4>
                    {this.state.guest.firstName !== ""
                      ? this.state.guest.firstName +
                        " " +
                        this.state.guest.lastName
                      : this.state.guest.usr}
                  </h4>
                  <h6>
                    Number of Guest : {this.state.details.numberOfGuestsCount}
                  </h6>
                </div>
                <div className="card-footer row">
                  <div className="col-4 col-sm-3">
                    <h6>Booking Id</h6>
                    <h3 className="counter">{this.state.details.id}</h3>
                  </div>
                  
                  <div className="col-4 col-sm-3">
                    <h6>Total fees</h6>
                    <h3>
                      <span className="counter">
                        {this.state.details.feesTotal}
                      </span>
                    </h3>
                  </div>
                  <div className="col-4 col-sm-3">
                    <h6>Paid</h6>
                    <h3>
                      <span className="counter">
                        {this.state.details.amountPaid}
                      </span>
                    </h3>
                  </div>
                  <div className="col-4 col-sm-3">
                    <h6>Phone Number</h6>
                    <h5>
                      <span className="counter">{this.state.guestAccount.phoneNumber}</span>
                    </h5>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row card-header">
                    <h5>Experience Details</h5>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-sm-4 text-right">
                      <label>ID</label>
                    </div>
                    <div className="col-sm-8">:{this.state.experienceVO.id}</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 text-right">
                      <label>Title</label>
                    </div>
                    <div className="col-sm-8">
                      :{this.state.experienceVO.title}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 text-right">
                      <label>Duration</label>
                    </div>
                    <div className="col-sm-8">
                      :{this.state.experienceVO.duration}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 text-right">
                      <label>Price per guest</label>
                    </div>
                    <div className="col-sm-8">
                      :{this.state.experienceVO.pricePerGuest}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 text-right">
                      <label>Block Date</label>
                    </div>
                    <div className="col-sm-8">
                      :{this.state.experienceCalenderVO.blockDate}
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-sm-4 text-right">
                      <label>Booking Date</label>
                    </div>
                    <div className="col-sm-8">
                      :{this.state.details.bookingDate}
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card custom-card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <h5>Host Details</h5>
                    </div>
                  </div>
                </div>
                <div className="card-profile">
                  <img className="rounded-circle" src={four} alt="" />
                </div>
                <div className="text-center profile-details">
                  <h4>
                    {this.state.host.firstName !== ""
                      ? this.state.host.firstName +
                        " " +
                        this.state.host.lastName
                      : this.state.host.usr}
                  </h4>
                  <h6>DOB : {this.state.host.dateOfBirth}</h6>
                </div>
                <div className="card-footer row">
                  <div className="col-4 col-sm-3">
                    <h6>ID</h6>
                    <h3 className="counter">{this.state.host.id}</h3>
                  </div>
                  <div className="col-4 col-sm-3">
                    <h6>E-mail</h6>
                    <h5>
                      <span className="counter">{this.state.host.usr}</span>
                    </h5>
                  </div>
                  <div className="col-4 col-sm-3">
                    <h6>Description</h6>
                    <h3>
                      <span className="counter">
                        {this.state.host.description}
                      </span>
                    </h3>
                  </div>
                  <div className="col-4 col-sm-3">
                    <h6>Phone Number</h6>
                    <h5>
                      <span className="counter">{this.state.hostAccount.phoneNumber}</span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default ExpDetails;
