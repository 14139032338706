import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumb from "../common/breadcrumb";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

var val2 = 0;
let gridRowList = [];
let dataPush = [];
var currentState = "";
class BedroomDesign extends Component {
  state = {
    // guest_count: 2,
    // guestCount: 0,
    // bedroomList_dynamic: [],
    // bedCountNoArray: 0,
    
    // sleepingArrangements: [],
    
    // sleepingArrangementsForSave: null,
    // bed_room_id: 0,
    // isShowSleepingArrangement: false,
    //bed_count:0,

    bed_type: [],
    addBed_btn_action: false,
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
    totalGuest:2,
    totalBedrooms:0,
    totalBed:1,
    editCommonAreas:false,
    sleepingArrangements:[],
    editBedrooms:true,

    showBedroom1:false,
    editBedrooms1:false,
    bed_type1:[],

    showBedroom2:false,
    editBedrooms2:false,
    bed_type2:[],

    showBedroom3:false,
    editBedrooms3:false,
    bed_type3:[]
    
  };

  componentDidMount() {
    this.getBedType();
    currentState = this;
  }
  getBedType = (event) => {
    let _this = this;
    apiService
      .bedtypeList()
      .then((response) => {
        if (response && response.status === 200) {
          let dataList = response.data;
          let dataPush = [];
          console.log("bed type response",response)
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
                value: 0,
              });
            }
          }
          _this.setState({
            bed_type: dataPush,
            bed_type1:dataPush,
            bed_type2:dataPush,
            bed_type3:dataPush,
            addBed_btn_action: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  incrementBedTypeValue = (event) => {
    let _this = this;
    var targetValue = Number(event.target.value);
    var targetName = event.target.name;
    var targetId = event.target.id;

    if(!isNaN(targetValue)){
      this.setState((state) => {
        const list = state.bed_type.map((item, j) => {
          if (targetValue === item.id) {
            item.value = item.value + 1;
            document.getElementById(item.id).value = item.value;
          }
        });
        
      });
      
      var sleepingList = this.state.sleepingArrangements;
      
      if(sleepingList.length > 0){
        // const index = this.state.sleepingArrangements.findIndex((item) => item.bed_type_id == targetValue);
  
        var isExist = false;
        for(var i = 0; i < sleepingList.length; i++){
          if(targetValue == this.state.sleepingArrangements[i].bed_type_id && targetId == this.state.sleepingArrangements[i].bed_room_id){
            isExist = true;
            break;
          }
        }
  
        if(isExist){
          for(var i = 0; i < sleepingList.length; i++){
            if(targetValue == this.state.sleepingArrangements[i].bed_type_id && targetId == this.state.sleepingArrangements[i].bed_room_id){
              this.state.sleepingArrangements[i].bedCount = this.state.sleepingArrangements[i].bedCount + 1;
            }
          }
        }
        else{
          let obj = {
            bed_room_id: Number(targetId),
            bedCount: 1,
            bed_type_id: targetValue,
            name:targetName
          };
          this.state.sleepingArrangements.push(obj);
          
        }
        
      }
      else{
        let obj = {
          bed_room_id: Number(targetId),
          bedCount: 1,
          bed_type_id: targetValue,
          name:targetName
        };
        this.state.sleepingArrangements.push(obj);
      }
      
      console.log("sleepingArrangements",this.state.sleepingArrangements)
    }
    
  };
  incrementBedTypeValue1(event,bedrm) {
    let _this = currentState;
    var targetValue = Number(event.target.value);
    var targetName = event.target.name;
    var targetId = event.target.id;

    var bedroomFor = "";
    var bedserial = 0;
    var list = [];
    if(bedrm == 1){
      bedroomFor = "bed1_";
      bedserial = 1;
      list = _this.state.bed_type1;
    }
    else if(bedrm == 2){
      bedroomFor = "bed2_";
      bedserial = 2;
      list = _this.state.bed_type2;
    }
    else if(bedrm == 3){
      bedroomFor = "bed3_";
      bedserial = 3;
      list = _this.state.bed_type3;
    }
    if(!isNaN(targetValue)){
      for(var i = 0; i < list.length; i++){
        if(targetValue === list[i].id){
          var existingValue = document.getElementById(bedroomFor+list[i].id).value;
          document.getElementById(bedroomFor+list[i].id).value = Number(existingValue) + 1;
        }
      }
      
      var sleepingList = _this.state.sleepingArrangements;
      
      if(sleepingList.length > 0){
        // const index = this.state.sleepingArrangements.findIndex((item) => item.bed_type_id == targetValue);
  
        var isExist = false;
        for(var i = 0; i < sleepingList.length; i++){
          if(targetValue == _this.state.sleepingArrangements[i].bed_type_id && bedserial == _this.state.sleepingArrangements[i].bed_room_id){
            isExist = true;
            break;
          }
        }
  
        if(isExist){
          for(var i = 0; i < sleepingList.length; i++){
            if(targetValue == _this.state.sleepingArrangements[i].bed_type_id && bedserial == _this.state.sleepingArrangements[i].bed_room_id){
              _this.state.sleepingArrangements[i].bedCount = _this.state.sleepingArrangements[i].bedCount + 1;
            }
          }
        }
        else{
          let obj = {
            bed_room_id: bedserial,
            bedCount: 1,
            bed_type_id: targetValue,
            name:targetName
          };
          _this.state.sleepingArrangements.push(obj);
          
        }
        
      }
      else{
        let obj = {
          bed_room_id: bedserial,
          bedCount: 1,
          bed_type_id: targetValue,
          name:targetName
        };
        _this.state.sleepingArrangements.push(obj);
      }
      
      console.log("sleepingArrangements",_this.state.sleepingArrangements)
    }
    
  };
  decrementBedTypeValue = (event) => {
    let _this = currentState;
    var targetValue = Number(event.target.value);
    var targetId = event.target.id;
    

    this.setState((state) => {
      const list = state.bed_type.map((item, j) => {
        if (targetValue === item.id) {
          item.value = item.value - 1;
          document.getElementById(item.id).value = item.value;
        }
      });
    });
    
    var sleepingList = currentState.state.sleepingArrangements;

    if(sleepingList.length > 0){
      for(var i = 0; i < sleepingList.length; i++){
        if(targetValue == _this.state.sleepingArrangements[i].bed_type_id && targetId == _this.state.sleepingArrangements[i].bed_room_id){
          _this.state.sleepingArrangements[i].bedCount = _this.state.sleepingArrangements[i].bedCount - 1;
        }
      }
    }
    console.log("sleepingArrangements",_this.state.sleepingArrangements)
  };
  decrementBedTypeValue1(event,bedrm) {
    let _this = currentState;
    var targetValue = Number(event.target.value);
    var targetId = event.target.id;
    var bedroomFor = "";
    var bedserial = 0;
    var list = [];
    if(bedrm == 1){
      bedroomFor = "bed1_";
      bedserial = 1;
      list = _this.state.bed_type1;
    }
    else if(bedrm == 2){
      bedroomFor = "bed2_";
      bedserial = 2;
      list = _this.state.bed_type2;
    }
    if(!isNaN(targetValue)){
      for(var i = 0; i < list.length; i++){
        if(targetValue === list[i].id){
          var existingValue = document.getElementById(bedroomFor+list[i].id).value;
          document.getElementById(bedroomFor+list[i].id).value = Number(existingValue) - 1;
        }
      }
      var sleepingList = currentState.state.sleepingArrangements;

      if(sleepingList.length > 0){
        for(var i = 0; i < sleepingList.length; i++){
          if(targetValue == _this.state.sleepingArrangements[i].bed_type_id && bedserial == _this.state.sleepingArrangements[i].bed_room_id){
            _this.state.sleepingArrangements[i].bedCount = _this.state.sleepingArrangements[i].bedCount - 1;
          }
        }
      }
      console.log("sleepingArrangements",_this.state.sleepingArrangements)
    }

    // this.setState((state) => {
    //   const list = state.bed_type.map((item, j) => {
    //     if (targetValue === item.id) {
    //       item.value = item.value - 1;
    //       document.getElementById(item.id).value = item.value;
    //     }
    //   });
    // });
    
    
  };

  addToGrid = () => {
    let _this = this;
    let isBedSelected = false;
    
    
    _this.setState({
      isShowSleepingArrangement: false,
    });
    let bedroomCount = "Bedroom" + this.state.bedCountNoArray;
    console.log("bed_type", this.state.bed_type);

    for (var i = 0; i < this.state.bed_type.length; i++) {
      if (this.state.bed_type[i].value > 0) {
        isBedSelected = true;
        let previousBedCount = this.state.bed_count;
        let newBedCount = this.state.bed_type[i].value;
        let finalBedCount = (previousBedCount+newBedCount);
        this.state.bed_count = finalBedCount;

        dataPush.push({
          bed_room_id: this.state.bedCountNoArray, // this.state.bedroomList_dynamic.length,
          bed_type_id: this.state.bed_type[i].id,
          name: this.state.bed_type[i].name,
          bedCount: this.state.bed_type[i].value,
        });
        this.state.bed_type[i].value = 0;
      } else continue;
    }

    this.state.sleepingArrangementsForSave = dataPush;

    // console.log("final", this.state.sleepingArrangementsForSave);
    console.log("sleepingArrangement", dataPush);
    console.log("bed_count", this.state.bed_count);

    if (isBedSelected) {
      var target = {
        bedroom_Count: bedroomCount,
      };
      var returnedTarget = "";
      this.state.bed_type.map((item, j) => {
        var obj = {
          [item.name]: item.value,
        };
        returnedTarget = Object.assign(target, obj);
      });
      console.log(returnedTarget);
      gridRowList.push(returnedTarget);
    } else {
      toast.warning("Please select your desire Bed first", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  
  nextBtnClick = (e) => {
    this.setState({
     
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/bathrooms",
      user_id: this.state.user_id
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/placetype",
      user_id: this.state.user_id
    });
  }
  incrementTotalBed = (e)=>{
    var value = Number(e.target.value);
    console.log("vall", value);
    if(!isNaN(value)){
      if(value == -1){
        this.setState({
          totalBedrooms: this.state.totalBedrooms + 1,
        })
      }
      else if(value == 0){
        this.setState({
          showBedroom1: true,
          totalBedrooms: this.state.totalBedrooms + 1,
        })
      }
      else if(value == 1){
        this.setState({
          showBedroom2: true,
          totalBedrooms: this.state.totalBedrooms + 1,
        })
      }
      else if(value == 2){
        this.setState({
          showBedroom3: true,
          totalBedrooms: this.state.totalBedrooms + 1,
        })
      }
    }
  }
  decrementTotalBed = (e)=>{
    var value = Number(e.target.value);
    console.log("vall", value);
    if(!isNaN(value)){
      if(value == 1){
        this.setState({
          showBedroom1: false,
          totalBedrooms: this.state.totalBedrooms - 1,
        })
      }
      else if(value == 2){
        this.setState({
          showBedroom2: false,
          totalBedrooms: this.state.totalBedrooms - 1,
        })
      }
      else if(value == 3){
        this.setState({
          showBedroom3: false,
          totalBedrooms: this.state.totalBedrooms - 1,
        })
      }

      var sleepingList = this.state.sleepingArrangements;

      if(sleepingList.length > 0){
        const newList = sleepingList.filter((item) => item.bed_room_id !== value);
        this.state.sleepingArrangements = newList;
      }
      console.log("sleepingArrangements",this.state.sleepingArrangements)
    }
    

  }
  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    let _this = this;
    let property_id = this.state.property_id;
    let url = "properties/" + property_id + "/bedrooms";
    params["bed_count"] = this.state.totalBed;
    params["bedroom_count"] = this.state.totalBedrooms;
    params["guest_count"] = this.state.totalGuest;
    params["sleepingArrangements"] = this.state.sleepingArrangements;

    // let pSleepingArrangements = [];
    // let vSleepingArrangements = this.state.sleepingArrangementsForSave; //this.state.houseRuleAdditionals;

    // if (vSleepingArrangements.length) {
    //   for (var i = 0; i < vSleepingArrangements.length; i++) {
    //     pSleepingArrangements.push({
    //       bedCount: vSleepingArrangements[i].bedCount,
    //       bed_room_id: vSleepingArrangements[i].bed_room_id,
    //       bed_type_id:vSleepingArrangements[i].bed_type_id,
    //       name:vSleepingArrangements[i].name
    //     });
    //   }
    // }
    // params["sleepingArrangements"] = pSleepingArrangements;

    console.log("param", params);
    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem("propDetails", JSON.stringify(response.data));
          this.setState({
            property_id: response.data.id,
            // redirect: "/propertiesmanagement/propertylist",
            user_id: response.data.user.id,
          });
          // console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
    
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom : this.state.callFrom,
            },
          }}
        />
      );
    }
    var bedType_count = this.state.bed_type;
    var bed_type_elements = [];
    for (var j = 0; j < bedType_count.length; j++) {
      bed_type_elements.push(
        <div className="form-group row" key={j}>
          <label className="col-sm-3 col-form-label">
            {bedType_count[j].name}
          </label>
          <div className="col-sm-9">
            <fieldset className="qty-box">
              <div className="input-group">
                <span className="input-group-prepend">
                  <button
                    type="button"
                    className="btn btn-primary mr-0"
                    value={bedType_count[j].id}
                    id={0}
                    onClick={this.decrementBedTypeValue}
                  >
                    <i className="fa fa-minus-square"></i>
                  </button>
                </span>
                <input
                  type="text"
                  name="quantity"
                  id={bedType_count[j].id}
                  value={bedType_count[j].value}
                  readOnly={true}
                  className="form-control input-number text-center"
                />
                <span className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-primary mr-1"
                    value={bedType_count[j].id}
                    id={0}
                    onClick={this.incrementBedTypeValue}
                    name={bedType_count[j].name}
                  >
                    <i className="fa fa-plus-square"></i>
                  </button>
                </span>
              </div>
            </fieldset>
          </div>
        </div>
      );
      
    }

    //Bedroom 1
    var bedType_count1 = this.state.bed_type1;
    var bed_type_elements1 = [];
    for (var j = 0; j < bedType_count1.length; j++) {
      bed_type_elements1.push(
        <div className="col-sm-6" key={j}>
          <label className="col-sm-6 col-form-label">
            {bedType_count1[j].name}
          </label>
          <div className="col-sm-12">
            <fieldset className="qty-box">
              <div className="input-group">
                <span className="input-group-prepend">
                  <button
                    type="button"
                    className="btn btn-primary mr-0"
                    value={bedType_count1[j].id}
                    id={this.state.totalBedrooms}
                    // onClick={this.decrementBedTypeValue1}
                    onClick={(e) => {
                      this.decrementBedTypeValue1(e, 1);
                   }}
                  >
                    <i className="fa fa-minus-square"></i>
                  </button>
                </span>
                <input
                  type="text"
                  name="quantity"
                  // id={this.state.bed_type1[j].bed1}
                  id={"bed1_"+this.state.bed_type1[j].id}
                  value={0}
                  readOnly={true}
                  className="form-control input-number text-center"
                />
                <span className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-primary mr-1"
                    value={bedType_count1[j].id}
                    id={this.state.totalBedrooms}
                    // onClick={this.incrementBedTypeValue1(1)}
                    onClick={(e) => {
                      this.incrementBedTypeValue1(e, 1);
                   }}
                    name={this.state.bed_type1[j].name}
                  >
                    <i className="fa fa-plus-square"></i>
                  </button>
                </span>
              </div>
            </fieldset>
          </div>
        </div>
      );
      
    }
    //Bedroom 2
    var bedType_count2 = this.state.bed_type2;
    var bed_type_elements2 = [];
    for (var j = 0; j < bedType_count2.length; j++) {
      bed_type_elements2.push(
        <div className="col-sm-6" key={j}>
          <label className="col-sm-6 col-form-label">
            {bedType_count2[j].name}
          </label>
          <div className="col-sm-12">
            <fieldset className="qty-box">
              <div className="input-group">
                <span className="input-group-prepend">
                  <button
                    type="button"
                    className="btn btn-primary mr-0"
                    value={bedType_count2[j].id}
                    id={this.state.totalBedrooms}
                    // onClick={this.decrementBedTypeValue1}
                    onClick={(e) => {
                      this.decrementBedTypeValue1(e, 2);
                   }}
                  >
                    <i className="fa fa-minus-square"></i>
                  </button>
                </span>
                <input
                  type="text"
                  name="quantity"
                  // id={this.state.bed_type1[j].bed1}
                  id={"bed2_"+this.state.bed_type2[j].id}
                  value={0}
                  readOnly={true}
                  className="form-control input-number text-center"
                />
                <span className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-primary mr-1"
                    value={bedType_count2[j].id}
                    id={this.state.totalBedrooms}
                    // onClick={this.incrementBedTypeValue1(1)}
                    onClick={(e) => {
                      this.incrementBedTypeValue1(e,2);
                   }}
                    name={this.state.bed_type2[j].name}
                  >
                    <i className="fa fa-plus-square"></i>
                  </button>
                </span>
              </div>
            </fieldset>
          </div>
        </div>
      );
      
    }
    //Bedroom 3
    var bedType_count3 = this.state.bed_type3;
    var bed_type_elements3 = [];
    for (var j = 0; j < bedType_count3.length; j++) {
      bed_type_elements3.push(
        <div className="col-sm-6" key={j}>
          <label className="col-sm-6 col-form-label">
            {bedType_count3[j].name}
          </label>
          <div className="col-sm-12">
            <fieldset className="qty-box">
              <div className="input-group">
                <span className="input-group-prepend">
                  <button
                    type="button"
                    className="btn btn-primary mr-0"
                    value={bedType_count3[j].id}
                    id={this.state.totalBedrooms}
                    // onClick={this.decrementBedTypeValue1}
                    onClick={(e) => {
                      this.decrementBedTypeValue1(e, 3);
                   }}
                  >
                    <i className="fa fa-minus-square"></i>
                  </button>
                </span>
                <input
                  type="text"
                  name="quantity"
                  // id={this.state.bed_type1[j].bed1}
                  id={"bed3_"+this.state.bed_type3[j].id}
                  value={0}
                  readOnly={true}
                  className="form-control input-number text-center"
                />
                <span className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-primary mr-1"
                    value={bedType_count3[j].id}
                    id={this.state.totalBedrooms}
                    // onClick={this.incrementBedTypeValue1(1)}
                    onClick={(e) => {
                      this.incrementBedTypeValue1(e,3);
                   }}
                    name={this.state.bed_type3[j].name}
                  >
                    <i className="fa fa-plus-square"></i>
                  </button>
                </span>
              </div>
            </fieldset>
          </div>
        </div>
      );
      
    }

    //Bedroom 1
    var bedroom1elements = [];
    bedroom1elements.push(
      <div className="form-group row">
        <div className="col-sm-6">
          <label
              htmlFor="ApartmentDropDown"
              className="col-sm-3 col-form-label"
            >
              <b>Bedroom 1</b>
          </label>
            <button
              type="button"
              className="btn btn-primary mr-1"
              data-field=""
              // value={i}
              onClick={(e) =>
                this.setState({
                  editBedrooms1: !this.state.editBedrooms1,
                })
              }
            >
              {this.state.editBedrooms1 ? "Done":"Edit Beds"}
            </button>
        </div>
        <div className="col-sm-12">
          <div className="form-group row">
          {this.state.editBedrooms1?
            bed_type_elements1 : ""}
          </div>
        </div>
        
      </div>
    );
    //bedroom 2
    var bedroom2elements = [];
    bedroom2elements.push(
      <div className="form-group row">
        <div className="col-sm-6">
          <label
              htmlFor="ApartmentDropDown"
              className="col-sm-3 col-form-label"
            >
              <b>Bedroom 2</b>
          </label>
            <button
              type="button"
              className="btn btn-primary mr-1"
              data-field=""
              // value={i}
              onClick={(e) =>
                this.setState({
                  editBedrooms2: !this.state.editBedrooms2,
                })
              }
            >
              {this.state.editBedrooms2 ? "Done":"Edit Beds"}
            </button>
        </div>
        <div className="col-sm-12">
          <div className="form-group row">
          {this.state.editBedrooms2?
            bed_type_elements2 : ""}
          </div>
        </div>
        
      </div>
    );
    //bedroom 3
    var bedroom3elements = [];
    bedroom3elements.push(
      <div className="form-group row">
        <div className="col-sm-6">
          <label
              htmlFor="ApartmentDropDown"
              className="col-sm-3 col-form-label"
            >
              <b>Bedroom 3</b>
          </label>
            <button
              type="button"
              className="btn btn-primary mr-1"
              data-field=""
              // value={i}
              onClick={(e) =>
                this.setState({
                  editBedrooms3: !this.state.editBedrooms3,
                })
              }
            >
              {this.state.editBedrooms3 ? "Done":"Edit Beds"}
            </button>
        </div>
        <div className="col-sm-12">
          <div className="form-group row">
          {this.state.editBedrooms3?
            bed_type_elements3 : ""}
          </div>
        </div>
        
      </div>
    );
    

    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Bed Rooms Design" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Number of guests accommodated by the property</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Guest</label>
                      <div className="col-sm-9">
                        <fieldset className="qty-box">
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-primary mr-0"
                                data-type="plus"
                                data-field=""
                                onClick={(e) =>
                                  this.setState({
                                    totalGuest: this.state.totalGuest < 1 ? 0 : this.state.totalGuest -1,
                                  })
                                }
                              >
                                <i className="fa fa-minus-square"></i>
                              </button>
                            </span>
                            <input
                              type="text"
                              name="quantity"
                              value={this.state.totalGuest}
                              readOnly={true}
                              className="form-control input-number text-center"
                            />
                            <span className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-primary mr-1"
                                data-type="plus"
                                data-field=""
                                onClick={(e) =>
                                  this.setState({
                                    totalGuest: this.state.totalGuest + 1,
                                  })
                                }
                              >
                                <i className="fa fa-plus-square"></i>
                              </button>
                            </span>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="ApartmentDropDown"
                        className="col-sm-3 col-form-label"
                      >
                        How many bedrooms for your guests?
                      </label>
                      <div className="col-sm-9">
                        <fieldset className="qty-box">
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-primary mr-0"
                                data-type="plus"
                                data-field=""
                                value={this.state.totalBedrooms}
                                onClick={this.decrementTotalBed}
                                // onClick={(e) =>
                                //   this.setState({
                                //     totalBedrooms: this.state.totalBedrooms < 1 ? 0 : this.state.totalBedrooms - 1,
                                //   })
                                // }
                              >
                                <i className="fa fa-minus-square"></i>
                              </button>
                            </span>
                            <input
                              type="text"
                              name="quantity"
                              value={this.state.totalBedrooms}
                              readOnly={true}
                              className="form-control input-number text-center"
                            />
                            <span className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-primary mr-1"
                                data-type="plus"
                                data-field=""
                                value={this.state.totalBedrooms}
                                onClick={this.incrementTotalBed}
                                // onClick={(e) =>
                                //   this.setState({
                                //     totalBedrooms: this.state.totalBedrooms + 1,
                                //   })
                                // }
                              >
                                <i className="fa fa-plus-square"></i>
                              </button>
                            </span>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="ApartmentDropDown"
                        className="col-sm-3 col-form-label"
                      >
                        In total how many beds are there?
                      </label>
                      <div className="col-sm-9">
                        <fieldset className="qty-box">
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-primary mr-0"
                                data-type="plus"
                                data-field=""
                                onClick={(e) =>
                                  this.setState({
                                    totalBed: this.state.totalBed < 1 ? 0 : this.state.totalBed - 1,
                                  })
                                }
                              >
                                <i className="fa fa-minus-square"></i>
                              </button>
                            </span>
                            <input
                              type="text"
                              name="quantity"
                              value={this.state.totalBed}
                              readOnly={true}
                              className="form-control input-number text-center"
                            />
                            <span className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-primary mr-1"
                                data-type="plus"
                                data-field=""
                                value={this.state.totalBed}
                                onClick={(e) =>
                                  this.setState({
                                    totalBed: this.state.totalBed + 1,
                                  })
                                }
                              >
                                <i className="fa fa-plus-square"></i>
                              </button>
                            </span>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label
                            htmlFor="ApartmentDropDown"
                            className="col-sm-3 col-form-label"
                          >
                            Common areas
                        </label>
                          <button
                            type="button"
                            className="btn btn-primary mr-1"
                            data-field=""
                            onClick={(e) =>
                              this.setState({
                                editCommonAreas: !this.state.editCommonAreas,
                              })
                            }
                          >
                            {this.state.editCommonAreas ? "Done":"Edit Beds"}
                          </button>
                      </div>
                      {this.state.editCommonAreas?
                      <div className="col-sm-6">
                        {bed_type_elements}
                      </div> : ""}
                      
                    </div>
                    {this.state.showBedroom1 ? bedroom1elements:""}
                    {this.state.showBedroom2 ? bedroom2elements:""}
                    {this.state.showBedroom3 ? bedroom3elements:""}
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default BedroomDesign;
