/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import {
  OPEN_API_BASE_URL,
  API_REQUEST_TOKEN,
  MANAGEMENT_API_BASE_URL,
  APP_API_BASE_URL,
} from "../lib/constants";
import {getToken} from '../services/storage.service'

const caxios = axios.create({
	baseURL: APP_API_BASE_URL,
	timeout: 200000,
	headers: {
		// Authorization: "Bearer " + StorageService.getToken(),
		Authorization: "Bearer " + getToken(),
		"Content-Type": "multipart/form-data",
		"Content-Type": "application/json",
	},
	transformRequest: [
		function (data, headers) {
			console.log("data", data);
			return data;
		},
	],
	validateStatus: function (status) {
		console.log("status", status);
		if (
			status < 300 ||
			status === 400 ||
			status === 422 ||
			status === 302 ||
			status === 500
		) {
			return status;
		}
	},
});
caxios.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer `+getToken();
  return config;
}, (err) => {

})
caxios.interceptors.response.use((res) => {
  //console.log("res11========", res)
  return res;
}, (err) => {
  debugger;
  // const originalRequest = err.config;
  if(err.response && err.response.status === 401 ) {
    window.location='/505'
  }
});

export const $axios = caxios;

export default {
  $axios,
  signin: (email, pass) =>
    axios({
      method: "POST",
      url: OPEN_API_BASE_URL + "login",
      headers: {
        "content-type": "application/json",
        Authorization: API_REQUEST_TOKEN,
      },
      data: {
        pwd: pass,
        usr: email,
      },
    }),

  getRole: () =>
    axios({
      method: "GET",
      url: MANAGEMENT_API_BASE_URL + "roles",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  deleteRole: (roleId) =>
    axios({
      method: "DELETE",
      url: MANAGEMENT_API_BASE_URL + "roles/" + roleId,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  createUser: (data) =>
    //Prepare Request Body

    axios({
      method: "POST",
      url: MANAGEMENT_API_BASE_URL + "users",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
      data: JSON.stringify(data),
    }),

  updateUser: (data, userId) =>
    //Prepare Request Body

    axios({
      method: "PATCH",
      url: MANAGEMENT_API_BASE_URL + "users/" + userId,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
      data: JSON.stringify(data),
    }),

  getUserList: (userType) =>
    axios({
      method: "GET",
      url: MANAGEMENT_API_BASE_URL + "users?roles=" + userType,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  deleteUser: (userId) =>
    axios({
      method: "DELETE",
      url: MANAGEMENT_API_BASE_URL + "users/" + userId,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  getUserDetails: (userId) =>
    axios({
      method: "GET",
      url: MANAGEMENT_API_BASE_URL + "users/" + userId,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  getResources: () =>
    axios({
      method: "GET",
      url: MANAGEMENT_API_BASE_URL + "resources",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  PropertyTypes: () =>
    axios({
      method: "GET",
      url: APP_API_BASE_URL + "property_types",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  cities: () =>
    axios({
      method: "GET",
      url: APP_API_BASE_URL + "cities",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  categoryList: () =>
    axios({
      method: "GET",
      url: APP_API_BASE_URL + "categories",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  getCurrencyList: () =>
    axios({
      method: "GET",
      url: APP_API_BASE_URL + "currencies",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  subcategoryList: () =>
    axios({
      method: "GET",
      url: APP_API_BASE_URL + "subcategories",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  bedtypeList: () =>
    axios({
      method: "GET",
      url: APP_API_BASE_URL + "bed_types",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  countryList: () =>
    axios({
      method: "GET",
      url: APP_API_BASE_URL + "countries",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),
  amenitiesList: () =>
    axios({
      method: "GET",
      url: APP_API_BASE_URL + "amenities",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  propertyList: () =>
    axios({
      method: "GET",
      url: APP_API_BASE_URL + "properties?sortBy=id:desc",
      //url: APP_API_BASE_URL + "properties/property_list",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),
  get_api: (url) =>
    axios({
      method: "GET",
      url: APP_API_BASE_URL + url,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),
  get_msg_by_id: (url) =>
    caxios({
      method: "GET",
      url: APP_API_BASE_URL + url,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),
  propertyAmenities: (Ids) =>
    axios({
      method: "GET",
      //url: APP_API_BASE_URL + "amenities/property_amenities/" + Ids,
      url: APP_API_BASE_URL + "amenities",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  getPropertyDetailsById: (propertyId) =>
    axios({
      method: "GET",
      url: APP_API_BASE_URL + "properties/" + propertyId,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),
  update: (url, data) =>
    axios({
      method: "PUT",
      url: APP_API_BASE_URL + url,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
      data: JSON.stringify(data),
    }),

  createProperty: (data) =>
    axios({
      method: "POST",
      url: APP_API_BASE_URL + "properties",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
      data: JSON.stringify(data),
    }),

  updateRoom: (data, url) =>
    axios({
      method: "PUT",
      url: APP_API_BASE_URL + url,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
      data: JSON.stringify(data),
    }),

  updateBathroom: (data, url) =>
    axios({
      method: "PUT",
      url: APP_API_BASE_URL + url,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
      data: JSON.stringify(data),
    }),

  updateLocationSetup: (data, url) =>
    axios({
      method: "PUT",
      url: APP_API_BASE_URL + url,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
      data: JSON.stringify(data),
    }),

  update_method: (data, url) =>
    axios({
      method: "PUT",
      url: APP_API_BASE_URL + url,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
      data: JSON.stringify(data),
    }),

    create_method: (data, url) =>
    axios({
      method: "POST",
      url: APP_API_BASE_URL + url,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
      data: JSON.stringify(data),
    }),

  updateProperty: (data, id) =>
    axios({
      method: "PUT",
      url: APP_API_BASE_URL + "properties/" + id,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
      data: JSON.stringify(data),
    }),

  Get: (url) =>
    axios({
      method: "GET",
      url: APP_API_BASE_URL + url,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),
  
  upload: (url) => axios({
    method: "POST",
    url: APP_API_BASE_URL + url,
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "bearer " + getToken(),
    }
  }),

  getHouseRuleList: () =>
    axios({
      method: "GET",
      url: APP_API_BASE_URL + "house_rule",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),

  getHouseRuleDetails: () =>
    axios({
      method: "GET",
      url: APP_API_BASE_URL + "house_rule_details",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),
  get_mng_api: (url) =>
    axios({
      method: "GET",
      url: MANAGEMENT_API_BASE_URL + url,
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + getToken(),
      },
    }),
};

// export const actionTypes = {
//     AUTH_START: "AUTH_START",
//     AUTH_SUCCESS: "AUTH_SUCCESS",
//     AUTH_FAIL: "AUTH_FAIL",
//     AUTH_WELCOME: "AUTH_WELCOME",
//     AUTH_LOGOUT: "AUTH_LOGOUT"
// };

// export const authStart = () => {
//     return {
//       type: actionTypes.AUTH_START
//     };
// };

// export const Login = (email, pass) => {
//     return(dispatch, getState)=>{
//         return new Promise((resolve, reject)=>{
//             dispatch(authStart());

//             //Prepare Request Body
//             const requestBody = {
//                 UserName: email,
//                 Password: pass,
//                 EmailStatus:2
//             };

//             //Set header configuration like Authorization, Content-type etc
//             const config = {
//                 headers: {
//                 //   Authorization: LOGIN_TOKEN,
//                   "Content-Type": "application/json"
//                 }
//             };

//             axios.defaults.validateStatus = (status) => {
//                 if(status < 300 || status === 400 || status === 422 || status === 500) {
//                   return status;
//                 }
//             };

//             //make the api request
//             axios
//                 .post(SECURITY_API_BASE_URL+"login", requestBody, config)
//                 .then(function(resp){
//                     if(resp){
//                         let token = resp.Result.token;
//                         //dispatch(authSuccess(1, token, resp.data.user));
//                         resolve(resp);
//                     }
//                 })
//                 .catch((err, xx) => {
//                     console.log("err", err.response);
//                     //dispatch(authFail(err.response.data.error));
//                     reject(err);
//                 });

//         });
//     };
// };

// export default new ApiService();
