import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
// import FullPageLoader from "../common/FullPageLoader/fullpageloader";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

class AdventureMeet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: "",
      long: "",
      apartment: "",
      country_id: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      countries: [],
      countryArr: {},
      loading: false,
      direction: "",
      locationName: "",
      adventure_id: this.props.location.state
        ? this.props.location.state.adventure_id
        : null,
      user_id: this.props.location.state
        ? this.props.location.state.user_id
        : null,
      callFrom: this.props.location.state
        ? this.props.location.state.callFrom
        : null,
      hostId: this.props.location.state
        ? this.props.location.state.hostId
        : null,
    };
  }

  componentDidMount() {
    this.getCountries();
    if (this.state.adventure_id !== null) {
      this.getEditData();
   }
  }
  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("adventureDetails"));
    console.log("resp", resp);
    // if (resp["title"] !== null) {
    //     this.setState({
    //       title: resp["title"],
    //     });
    //   }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let _this = this;
    let params = {};

    var countryArr = this.state.countries;
    let adventure_id = this.state.adventure_id;
    let url = "adventure/" + adventure_id + "/meeting_location/";

    params["countryId"] = Number(this.state.country_id);
    params["stateAddress"] = this.state.street;
    params["appartment"] = this.state.apartment;
    params["city"] = this.state.city;
    params["state"] =  this.state.state;
    params["zipCode"] = this.state.zipCode;
    params["direction"] = this.state.direction;
    params["locationName"] = this.state.locationName;
    params["latitude"] = 0;
    params["longitude"] = 0;

    console.log("params", params);

    apiService
        .update_method(params, url)
        .then((response) => {
          console.log("response", response);
          if (response && response.status === 200) {
            toast.success("Updated Successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            // localStorage.setItem(
            //   "experienceDetails",
            //   JSON.stringify(response.data)
            // );
            // this.setState({
            //   adventure_id: response.data.id,
            //   // redirect: "/experiencemanagement/ideatheme",
            //   user_id: response.data.host.id,
            // });
            // console.log(response);
          } else {
            toast.error(response.error_description, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((error) => {
          // loginState.closeLoader();
          toast.error("Exception Occured!!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // console.log(error);
        });
  };
  nextBtnClick = (e) => {
    this.setState({
      adventure_id: this.state.adventure_id,
      redirect: "/advanturemanagement/adventurenotes",
      user_id: this.state.user_id,
    });
  };

  getCountries() {
    let _this = this;
    let url = "countries";
    apiService
      .get_api(url)
      .then((response) => {
        //this.hideLoader();
        if (response && response.status === 200) {
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["name"],
                name: dataList[key]["name"],
                country_id_api: dataList[key]["id"],
              });
              _this.state.countryArr[dataList[key]["shortName"]] =
                dataList[key]["id"];
            }
          }
          _this.setState({
            countries: dataPush,
          });

          console.log("countries", this.state.countries);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onChangeCountry = (e) => {
    console.log("called");
    this.setState({
      country_id: e.target.value,
    });
    console.log("country id", this.state.country_id);
  };
  onChangeStreet = (e) => {
    this.setState({
      street: e.target.value,
    });
  };
  onChangeApartment = (e) => {
    this.setState({
      apartment: e.target.value,
    });
  };

  onChangeCity = (e) => {
    this.setState({
      city: e.target.value,
    });
  };
  onChangeState = (e) => {
    this.setState({
      state: e.target.value,
    });
  };
  onChangeZip = (e) => {
    this.setState({
      zipCode: e.target.value,
    });
  };
  onChangeDirection = (e) => {
    this.setState({
      direction: e.target.value,
    });
  };
  onChangeLocationName = (e) => {
    this.setState({
      locationName: e.target.value,
    });
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              adventure_id: this.state.adventure_id,
              user_id: this.state.user_id,
              hostId: this.state.hostId,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    const { countries } = this.state;
    return (
      <Fragment>
        <Breadcrumb parent="Advanture Management / Meeting Location" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Where should guests meet you?</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="ribbon-wrapper card">
                      <div className="card-body">
                        <div className="ribbon ribbon-success">
                          Step 1: Provide an address
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="ApartmentDropDown"
                            className="col-sm-3 col-form-label"
                          >
                            Country / Region
                          </label>
                          <div className="col-sm-9">
                            <select
                              value={this.state.country_id}
                              className="form-control digits"
                              id="ApartmentDropDown"
                              onChange={this.onChangeCountry}
                            >
                              <option value="">
                                Please select your country!
                              </option>
                              {countries.length > 0 &&
                                countries.map((item, key) => {
                                  return (
                                    <option key={key} value={item.country_id_api}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="exampleFormControlSelect9"
                            className="col-sm-3 col-form-label"
                          >
                            Street address
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              id="exampleInputPassword2"
                              type="text"
                              value={this.state.street}
                              placeholder="Please enter your street address!"
                              onChange={this.onChangeStreet}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="apartmentId"
                            className="col-sm-3 col-form-label"
                          >
                            Apartment, suite
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              id="apartmentId"
                              type="text"
                              value={this.state.apartment}
                              placeholder="Please enter your Apartment, suite!"
                              autoComplete="off"
                              onChange={this.onChangeApartment}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="cityId"
                            className="col-sm-3 col-form-label"
                          >
                            City
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              id="cityId"
                              type="text"
                              value={this.state.city}
                              placeholder="Please enter your city!"
                              autoComplete="off"
                              onChange={this.onChangeCity}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="stateCountryId"
                            className="col-sm-3 col-form-label"
                          >
                            State/County
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              id="stateCountryId"
                              type="text"
                              value={this.state.state}
                              placeholder="Please enter your state!"
                              autoComplete="off"
                              onChange={this.onChangeState}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="zipcodeId"
                            className="col-sm-3 col-form-label"
                          >
                            Zip code / Post code
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              id="zipcodeId"
                              type="text"
                              value={this.state.zipCode}
                              placeholder="Please enter your zip code!"
                              autoComplete="off"
                              onChange={this.onChangeZip}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ribbon-wrapper card">
                      <div className="card-body">
                        <div className="ribbon ribbon-warning">
                          Step 2: Adjust the pin on the map
                        </div>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text.
                        </p>
                      </div>
                    </div>
                    <div className="ribbon-wrapper card">
                      <div className="card-body">
                        <div className="ribbon ribbon-primary">
                          Step 3: Add a description (optional)
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Direction (optional)
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              className="form-control"
                              rows="5"
                              cols="5"
                              placeholder="direction"
                              value={this.state.direction}
                              onChange={this.onChangeDirection}
                            ></textarea>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="locationName"
                            className="col-sm-3 col-form-label"
                          >
                            Location name
                          </label>
                          <div className="col-sm-9 input-group">
                            <input
                              className="form-control"
                              id="locationName"
                              type="text"
                              value={this.state.locationName}
                              placeholder="Location here..."
                              autoComplete="off"
                              onChange={this.onChangeLocationName}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-warning mr-1"
                        // onClick={() => {
                        //   this.setState({
                        //     redirect: "/propertiesmanagement/addproperties",
                        //   });
                        // }}
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        onClick={this.handleSubmit}
                      >
                        Update
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={this.nextBtnClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default AdventureMeet;
