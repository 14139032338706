import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import apiService from "../../../apiservices/apiservice";
import { ToastContainer, toast } from "react-toastify";
import FullPageLoader from "../../../components/common/FullPageLoader/fullpageloader";
import { Button } from "reactstrap";

var loginState = "";
class AddApplicationUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
			roleList: [],
			selectedRole: "",
			hidden: true,
			loading: false,
			buttonNameChanged: false,
			breadcrumbItems: [
				{ name: "User List", link: "/applicationuser/applicationuserlist" },
				{ name: "Add User" },
			],
		};
    this.toggleShow = this.toggleShow.bind(this);
    loginState = this;
  }

  showLoader() {
    this.setState({ loading: true });
  }

  closeLoader() {
    this.setState({ loading: false });
  }

  componentDidMount() {
    apiService
      .getRole()
      .then((response) => {
        //this.hideLoader();
        if (response && response.status === 200) {
          // toast.success("Login Successfully", {
          //     position: toast.POSITION.BOTTOM_RIGHT,
          // });
          console.log(response);
          let dataPush = [];
          if (response.data.length > 0){
            for (let key in response.data){
                if(response.data[key].name !== "Admin" && response.data[key].name !== "Sub-Admin"){
                    dataPush.push(response.data[key]);
                }
            }
            
          }
          this.setState({
            roleList: dataPush,
          });
        }
      })
      .catch((error) => {
        //this.hideLoader();
        // toast.error("user name or password invalid", {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        // });
        console.log(error);
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    loginState.showLoader();
    let userId_value = document.getElementById("userId").value;
    let firstName_value = document.getElementById("firstName").value; //values["email"];
    let lastName_value = document.getElementById("lastName").value;
    let userName_value = document.getElementById("userName").value;
    let password_value = document.getElementById("password").value;
    let confirmPassword_value = document.getElementById("confirmPassword")
      .value;
    let roleId_value = document.getElementById("roleDropDown").value; //this.state["selectedRole"];
    let dateOfBirth_value = document.getElementById("dateOfBirth").value;

    if (confirmPassword_value !== password_value) {
      loginState.closeLoader();
      toast.warn("Password do not match", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      //return false;
    } else {
      const data = {
        dateOfBirth: dateOfBirth_value,
        firstName: firstName_value,
        lastName: lastName_value,
        pwd: password_value,
        usr: userName_value,
        roleIds: roleId_value,
      };
      console.log(JSON.stringify(data));

      if (userId_value !== "") {
        apiService
          .updateUser(data, userId_value)
          .then((response) => {
            loginState.closeLoader();
            if (response && response.status === 200) {
              document.getElementById("userId").value = response.data.id;
              this.setState({ buttonNameChanged: true });
              toast.success("Information Updated Successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              console.log(response);
            }
          })
          .catch((error) => {
            loginState.closeLoader();
            toast.error("Exception Occured!!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            console.log(error);
          });
      } else {
        apiService
          .createUser(data)
          .then((response) => {
            loginState.closeLoader();
            if (response && response.status === 201) {
              toast.success("User Created Successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              console.log(response);
            }
          })
          .catch((error) => {
            loginState.closeLoader();
            toast.error("Exception Occured!!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            console.log(error);
          });
      }
    }
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }
  resetForm = () => {
    document.getElementById("adminCreateForm").reset();
    this.setState({ buttonNameChanged: false });
  };
  userDetails = (e) => {
    e.preventDefault();
    loginState.showLoader();
    var userId = document.getElementById("userId").value;

    apiService
      .getUserDetails(userId)
      .then((response) => {
        loginState.closeLoader();
        if (response && response.status === 200) {
          toast.success("User Details found Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log(response);
          //Change Button name
          this.setState({ buttonNameChanged: true });
          document.getElementById("firstName").value = response.data.firstName; //values["email"];
          document.getElementById("lastName").value = response.data.lastName;
          document.getElementById("userName").value = response.data.usr;
          // document.getElementById("roleDropDown").value = response.data.firstName; //this.state["selectedRole"];
          document.getElementById("dateOfBirth").value =
            response.data.dateOfBirth;
        }
      })
      .catch((error) => {
        loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        console.log(error);
      });
  };

  render = () => {
    // const roleListData = this.props.roleList;
    return (
      <Fragment>
        <Breadcrumb parent="Security / Add Admin User" options={this.state.breadcrumbItems} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Add New Application User</h5>
                </div>
                <form
                  className="form theme-form"
                  onSubmit={this.handleSubmit}
                  id="adminCreateForm"
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            User ID
                          </label>
                          <div className="col-sm-9 input-group">
                            <input
                              className="form-control input-air-primary"
                              type="text"
                              id="userId"
                              placeholder="Auto Generate"
                              // onChange={this.userDetails}
                              // readOnly
                            />
                            <div
                              className="input-group-prepend"
                              onClick={this.userDetails}
                            >
                              <Button
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i className="fa fa-search"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            First Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              id="firstName"
                              placeholder="Enter First Name Here"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Last Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              id="lastName"
                              placeholder="Enter Last Name Here"
                            />
                          </div>
                        </div>
                        <ToastContainer />
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            User Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              id="userName"
                              placeholder="xyz@hurraayy.com"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Date of Birth
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="date"
                              id="dateOfBirth"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Password
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type={this.state.hidden ? "password" : "text"}
                              id="password"
                              placeholder="Enter User Password Here"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 col-form-label">
                            Confirm Password
                          </label>

                          <div className="col-sm-9 input-group">
                            <input
                              className="form-control"
                              type={this.state.hidden ? "password" : "text"}
                              id="confirmPassword"
                              placeholder="Confirm User Password Here"
                              required
                            />
                            <div
                              className="input-group-prepend"
                              onClick={this.toggleShow}
                            >
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                <i
                                  className={
                                    this.state.hidden
                                      ? "fa fa-eye-slash"
                                      : "icofont icofont-open-eye"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="roleDropDown"
                            className="col-sm-3 col-form-label"
                          >
                            Role
                          </label>
                          <div className="col-sm-9">
                            <select
                              value={this.state.selectedRole}
                              className="form-control digits"
                              id="roleDropDown"
                              onChange={(e) =>
                                this.setState({ selectedRole: e.target.value })
                              }
                            >
                              {this.state.roleList.map((role) => (
                                <option key={role.id} value={role.id}>
                                  {role.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer">
                    <div className="col-sm-9 offset-sm-3">
                      <button
                        type="submit"
                        className={
                          this.state.buttonNameChanged
                            ? "btn btn-success mr-1"
                            : "btn btn-primary mr-1"
                        }
                      >
                        {this.state.buttonNameChanged ? "Update" : "Submit"}
                      </button>
                      <input
                        className="btn btn-light"
                        type="reset"
                        defaultValue="Cancel"
                        onClick={this.resetForm}
                      />
                    </div>
                  </div>
                  {this.state.loading ? (
                    <FullPageLoader loading={this.state.loading} />
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
}
export default AddApplicationUser;
