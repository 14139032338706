
import { $managementAxios } from '../lib/unility/http-service';

const getStatByDateRange = async (startDate, endDate) => {
  return $managementAxios.get(`/dashboard/get-stat-by-date-range?startDate=${startDate}&endDate=${endDate}`);
}

const getBookingTrendByDateRange = async (startDate, endDate) => {
  return $managementAxios.get(
		`/dashboard/get-booking-trend-by-date-range?startDate=${startDate}&endDate=${endDate}`
	);
}

const getUserTrendByDateRange = async (startDate, endDate) => {
  return $managementAxios.get(`/dashboard/get-user-trend-by-date-range?startDate=${startDate}&endDate=${endDate}`);
}


const getRetentionRate = async (startDate, endDate) => {
	return $managementAxios.get(
		`/dashboard/get-retention-rate?startDate=${startDate}&endDate=${endDate}`
	);
};

export {getStatByDateRange, getBookingTrendByDateRange, getUserTrendByDateRange,getRetentionRate};