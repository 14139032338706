import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { ToastContainer, toast } from "react-toastify";
import apiService from "../../apiservices/apiservice";
import { Redirect } from "react-router-dom";

class GuestNotification extends Component {
  state = {
    booksBefore: [],
    inFroms: [],
    toFroms: [],
    isCanBook: true,
    noticeBeforeGuestArrive: "0",
    timeGuestCanBook: "",
    checkInFrom: "",
    checkInTo: "",
    id: 0,
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
  };
  componentDidMount() {
    if (this.state.property_id !== undefined) {
      this.getEditData();
    }

    let booksBefore = this.getBooksBefore();
    let inFroms = this.getInFrom();
    let toFroms = this.getToFrom();
    this.setState({ booksBefore: booksBefore });
    this.setState({ inFroms: inFroms });
    this.setState({ toFroms: toFroms });
  }
  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("propDetails"));
    if (resp) {
      if (resp["propertyAvailabilitySetting"]) {
        let data = resp["propertyAvailabilitySetting"];
        this.setState({
          noticeBeforeGuestArrive: data["noticeBeforeGuestArrive"],
        });
        if (data["noticeBeforeGuestArrive"] === 0) {
          this.setState({ isCanBook: true });
        } else {
          this.setState({ isCanBook: false });
        }
        this.setState({
          timeGuestCanBook: data["timeGuestCanBook"],
        });
        this.setState({
          checkInFrom: data["checkInFrom"],
        });
        this.setState({
          checkInTo: data["checkInTo"],
        });
        this.setState({
          id: data["id"],
        });
      }
      this.setState({ loading: false });
    }
  }
  onChangeGuestArrive = (e) => {
    // console.log(e);
    if (e === "0") {
      this.setState({
        isCanBook: true,
        noticeBeforeGuestArrive: e.target.value,
      });
    } else {
      this.setState({
        isCanBook: false,
        noticeBeforeGuestArrive: e.target.value,
      });
    }
  };
  getBooksBefore() {
    let am = this.getHours(6, 12, "AM");
    let pm = this.getHours(1, 12, "PM");
    let ampm = am.concat(pm);
    return ampm;
  }
  getInFrom() {
    let am = this.getHours(8, 12, "AM");
    let pm = this.getHours(1, 12, "PM");
    let first = ["Flexible"];
    let firstam = first.concat(am);
    let ampm = firstam.concat(pm);
    let last = ["1:00 AM (next day)", "1:00 AM (next day)"];
    let lastam = ampm.concat(last);
    return lastam;
  }
  getToFrom() {
    let am = this.getHours(8, 12, "AM");
    let pm = this.getHours(1, 12, "PM");
    let first = ["Flexible"];
    let firstam = first.concat(am);
    let ampm = firstam.concat(pm);
    let last = ["1:00 AM (next day)"];
    let lastam = ampm.concat(last);
    return lastam;
  }
  getHours(hfrom, hto, hourType) {
    let arr = [];
    for (let i = hfrom; i <= hto; i++) {
      let hour = i + ":00 " + hourType;
      arr[i] = hour;
    }
    return arr;
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let property_id = this.state.property_id;
    let _this = this;
    let url = "properties/" + property_id + "/availability-settings";
    let params = {};

    params["noticeBeforeGuestArrive"] = this.state.noticeBeforeGuestArrive;
    params["checkInFrom"] = this.state.checkInFrom;
    params["checkInTo"] = this.state.checkInTo;
    params["timeGuestCanBook"] = this.state.timeGuestCanBook;
    params["howFarAdvanceGuestCanBook"] = "";
    params["manuallyReviewAndApproveRequests"] = "";
    params["maxStayNight"] = "";
    params["minStayNight"] = "";
    if (this.state.id) {
      params["id"] = this.state.id;
    }
    console.log("param", params);
    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem("propDetails", JSON.stringify(response.data));
          this.setState({
            property_id: response.data.id,
            // redirect: "/propertiesmanagement/guestbooks",
            user_id: response.data.user.id,
          });
          // console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };

  onChangeGuestBookBefore = (e) => {
    this.setState({
      timeGuestCanBook: e.target.value,
    });
  };
  onChangeGuestFrom = (e) => {
    this.setState({
      checkInFrom: e.target.value,
    });
  };
  onChangeGuestTo = (e) => {
    this.setState({
      checkInTo: e.target.value,
    });
  };
  nextBtnClick = (e) => {
    this.setState({
        redirect: "/propertiesmanagement/guestbooks",
        property_id:this.state.property_id,
      user_id: this.state.user_id
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/availabilityquestion",
      user_id: this.state.user_id
    });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    let booksBefore = this.state.booksBefore;
    let inFroms = this.state.inFroms;
    let toFroms = this.state.toFroms;
    const {
      noticeBeforeGuestArrive,
      timeGuestCanBook,
      checkInFrom,
      checkInTo,
      loading,
    } = this.state;
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Add Calendar" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>
                    How much time you need to prepare before a guest arrive?
                  </h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <select
                          value={this.state.noticeBeforeGuestArrive}
                          className="form-control digits"
                          id="place_type_id"
                          onChange={this.onChangeGuestArrive}
                        >
                          <option value="0">Same day</option>
                          <option value="1">1 day</option>
                          <option value="2">2 days</option>
                          <option value="3">3 days</option>
                          <option value="4">4 days</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <p>
                        <span className="text-primary">Tip:</span> Recommended:
                        It's better to accept booking same day to increase the
                        chances of getting more bookings. However if you have
                        time in hand then you can better prepare yourself.
                      </p>
                      <label
                        htmlFor="place_type_id"
                        className="col-sm-3 col-form-label"
                      >
                        Guests can book before:
                      </label>
                      <div className="col-sm-9">
                        <select
                          value={this.state.timeGuestCanBook}
                          className="form-control digits"
                          id="place_type_id"
                          onChange={this.onChangeGuestBookBefore}
                          //   onChange={() => {
                          //     this.setState({
                          //       timeGuestCanBook: this.value,
                          //     });
                          //   }}
                        >
                          {booksBefore.length > 0 &&
                            booksBefore.map((item, key) => {
                              return (
                                <option key={key} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <h5 className="col-sm-12">
                        Let's set the guest check in time
                      </h5>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="place_type_id"
                        className="col-sm-2 col-form-label"
                      >
                        From
                      </label>
                      <div className="col-sm-4">
                        <select
                          value={this.state.checkInFrom}
                          className="form-control digits"
                          id="place_type_id"
                          onChange={this.onChangeGuestFrom}
                          //   onChange={() => {
                          //     this.setState({
                          //       checkInFrom: this.value,
                          //     });
                          //   }}
                        >
                          {inFroms.length > 0 &&
                            inFroms.map((item, key) => {
                              return (
                                <option key={key} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <label
                        htmlFor="place_type_id"
                        className="col-sm-2 col-form-label"
                      >
                        To
                      </label>
                      <div className="col-sm-4">
                        <select
                          value={this.state.checkInTo}
                          className="form-control digits"
                          id="place_type_id"
                          onChange={this.onChangeGuestTo}
                          //   onChange={() => {
                          //     this.setState({
                          //         checkInTo: this.value,
                          //     });
                          //   }}
                        >
                          {toFroms.length > 0 &&
                            toFroms.map((item, key) => {
                              return (
                                <option key={key} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Fragment>
    );
  }
}

export default GuestNotification;
