import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import apiService from "../../apiservices/apiservice";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";

class GuestStay extends Component {
  state = {
    min: 1,
    max: 2,
    isApproveRequest: false,

    booksBefore: [],
    inFroms: [],
    toFroms: [],
    isCanBook: true,
    noticeBeforeGuestArrive: "0",
    timeGuestCanBook: "",
    checkInFrom: "",
    checkInTo: "",
    manuallyReviewAndApproveRequests: true,
    id: 0,
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
  };
  componentDidMount() {
      console.log("propid", this.state.property_id);
    if (this.state.property_id !== undefined) {
      this.getEditData();
    }
  }
  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("propDetails"));
    console.log("session", resp);
    if (resp && resp["propertyAvailabilitySetting"]) {
      let data = resp["propertyAvailabilitySetting"];
      if (data["maxStayNight"]) {
        this.setState({ max: parseInt(data["maxStayNight"]) });
      }
      if (this.state.max !== 2) {
        this.setState({ isApproveRequest: true });
      }
      if (data["minStayNight"]) {
        this.setState({ min: parseInt(data["minStayNight"]) });
      }
      this.setState({
        manuallyReviewAndApproveRequests:
          data["manuallyReviewAndApproveRequests"],
      });

      if (data["howFarAdvanceGuestCanBook"]) {
        this.setState({
          howFarAdvanceGuestCanBook: data["howFarAdvanceGuestCanBook"],
        });
      }
      this.setState({
        noticeBeforeGuestArrive: data["noticeBeforeGuestArrive"],
      });
      this.setState({
        timeGuestCanBook: data["timeGuestCanBook"],
      });
      this.setState({
        checkInFrom: data["checkInFrom"],
      });
      this.setState({
        checkInTo: data["checkInTo"],
      });
      this.setState({
        id: data["id"],
      });
      this.setState({ loading: false });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let property_id = this.state.property_id;
    let _this = this;
    let url = "properties/" + property_id + "/availability-settings";

    const {
      noticeBeforeGuestArrive,
      checkInFrom,
      checkInTo,
      timeGuestCanBook,
      id,
      howFarAdvanceGuestCanBook,
    } = this.state;
    let params = {};
    params["manuallyReviewAndApproveRequests"] = "";
    params["maxStayNight"] = this.state.max;
    params["minStayNight"] = this.state.min;
    params["id"] = id;

    params["noticeBeforeGuestArrive"] = noticeBeforeGuestArrive;
    params["checkInFrom"] = checkInFrom;
    params["checkInTo"] = checkInTo;
    params["timeGuestCanBook"] = timeGuestCanBook;
    params["howFarAdvanceGuestCanBook"] = howFarAdvanceGuestCanBook;
    // params["id"] = id;
    console.log("param", params);
    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem("propDetails", JSON.stringify(response.data));
          this.setState({
            property_id: response.data.id,
            // redirect: "/propertiesmanagement/propertylist",
            user_id: response.data.user.id,
          });
          // console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  nextBtnClick = (e) => {
    this.setState({
      redirect: "/propertiesmanagement/calendar",
      property_id: this.state.property_id,
      user_id: this.state.user_id,
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/guestbooks",
      user_id: this.state.user_id
    });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Guest Stay" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>
                    Please tell us for how long your guests can stay in your
                    place?
                  </h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Minimum</label>
                      <div className="col-sm-9">
                        <fieldset className="qty-box">
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-primary mr-0"
                                data-type="plus"
                                data-field=""
                                onClick={(e) =>
                                  this.setState({
                                    min: this.state.min - 1,
                                  })
                                }
                              >
                                <i className="fa fa-minus-square"></i>
                              </button>
                            </span>
                            <input
                              type="text"
                              name="quantity"
                              value={this.state.min}
                              readOnly={true}
                              className="form-control input-number text-center"
                            />
                            <span className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-primary mr-1"
                                data-type="plus"
                                data-field=""
                                onClick={(e) =>
                                  this.setState({
                                    min: this.state.min + 1,
                                  })
                                }
                              >
                                <i className="fa fa-plus-square"></i>
                              </button>
                            </span>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Maximum</label>
                      <div className="col-sm-9">
                        <fieldset className="qty-box">
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-primary mr-0"
                                data-type="plus"
                                data-field=""
                                onClick={(e) =>
                                  this.setState({
                                    max: this.state.max - 1,
                                  })
                                }
                              >
                                <i className="fa fa-minus-square"></i>
                              </button>
                            </span>
                            <input
                              type="text"
                              name="quantity"
                              value={this.state.max}
                              readOnly={true}
                              className="form-control input-number text-center"
                            />
                            <span className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-primary mr-1"
                                data-type="plus"
                                data-field=""
                                onClick={(e) =>
                                  this.setState({
                                    max: this.state.max + 1,
                                  })
                                }
                              >
                                <i className="fa fa-plus-square"></i>
                              </button>
                            </span>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Fragment>
    );
  }
}

export default GuestStay;
