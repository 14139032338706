import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { sum } from "chartist";

let days = [];
for (let i = 0; i <= 20; i++) {
  days.push(i);
}

class AdventureDuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: 4,
      startTime: "11:30",
      iteration: [],
      adventure_id: this.props.location.state
        ? this.props.location.state.adventure_id
        : null,
      user_id: this.props.location.state
        ? this.props.location.state.user_id
        : null,
      callFrom: this.props.location.state
        ? this.props.location.state.callFrom
        : null,
      hostId: this.props.location.state
        ? this.props.location.state.hostId
        : null,
    };
  }

  componentDidMount() {
    if (this.state.adventure_id !== null) {
      this.getEditData();
    }
  }

  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("adventureDetails"));
    console.log("resp", resp);

    if (resp["durationDescriptions"] !== null) {
      let duration = JSON.parse(resp["durationDescriptions"]);
      _this.setState({
        iteration: duration,
      });
    }
    if(resp["duration"] !== null){
        let durationDay = resp["duration"];
        _this.setState({
            duration: durationDay,
          });
    }
    
  }

  onChangeDuration = (e) => {
    let dataList = e.target.value;
    console.log("dataList", dataList);
    let dataPush = [];
    for (let i = 1; i <= dataList; i++) {
      
      dataPush.push({
        key: i,
      });
    }

    console.log("datapush", dataPush);
    
    this.setState({
      duration: e.target.value,
      iteration: dataPush,
    });
    console.log("value selected", this.state.iteration);
  };
  onChangeStartTime = (e) => {
    this.setState({
      startTime: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};

    let adventure_id = this.state.adventure_id;
    let url = "adventure/" + adventure_id + "/duration/";
let obj2 = {};
let descriptionJson = [];
    for(let i = 0; i < this.state.iteration.length; i++){
        obj2.key = this.state.iteration[i].key;
        obj2.text = this.state.iteration[i].text;
        descriptionJson.push(JSON.stringify(obj2));
    }

    console.log("test", descriptionJson);
// let obj = this.state.iteration;
// let ready = JSON.stringify(obj);

    params["duration"] = Number(this.state.duration);
let test = "";
test = descriptionJson;
    params["descriptionJson"] = "["+descriptionJson+"]";
    
    console.log("params", params);

    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem(
            "adventureDetails",
            JSON.stringify(response.data)
          );
          this.setState({
            adventure_id: response.data.id,
            // redirect: "/experiencemanagement/ideatheme",
            user_id: response.data.host.id,
          });
          console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };

  onChangeIteration = (e) => {
    let targetValue = e.target.value;
    let targetId = e.target.id;
    console.log("targetValue", targetId);

    this.setState((state) => {
        state.iteration.map((item, j) => {
          if (Number(targetId) === item.key) {
            item.text = targetValue;
          }
        });
      });

    console.log("modify", this.state.iteration);
  };
  nextBtnClick = (e) => {
    this.setState({
      adventure_id: this.state.adventure_id,
      redirect: "/advanturemanagement/adventureprice",
      user_id: this.state.user_id,
    });
  };
  render() {
    const { iteration } = this.state;
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              adventure_id: this.state.adventure_id,
              user_id: this.state.user_id,
              hostId: this.state.hostId,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Adventure Management / Duration" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Duration</h5>
                </div>
                <div className="card-body">
                  <form className="form theme-form" id="groupSizeForm">
                    <div className="form-group row">
                      <label
                        htmlFor="duration"
                        className="col-sm-3 col-form-label"
                      >
                        What’s the duration of your adventure?
                      </label>
                      <div className="col-sm-9">
                        <select
                          onChange={this.onChangeDuration}
                          value={this.state.duration}
                          className="form-control digits"
                          id="duration"
                        >
                          {days.length > 0 &&
                            days.map((item, key) => {
                              return (
                                <option key={key} value={item}>
                                  {item + " Days"}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="startTime"
                        className="col-sm-3 col-form-label"
                      >
                        What time would you typically start your adventure?
                      </label>
                      <div className="col-sm-9">
                        
                        {iteration.length > 0 &&
                          iteration.map((item, key) => {
                            return (
                              // <option key={key} value={item.id}>
                              //   {item.name}
                              // </option>
                              <p key={key}>
                                <textarea
                                  className="form-control"
                                  rows="5"
                                  cols="5"
                                  id={sum(key+1)}
                                  placeholder={"Day "+sum(key+1)}
                                  value={item.text}
                                  onChange={this.onChangeIteration}
                                ></textarea>
                              </p>
                            );
                          })}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-warning mr-1"
                        // onClick={() => {
                        //   this.setState({
                        //     redirect: "/propertiesmanagement/addproperties",
                        //   });
                        // }}
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        onClick={this.handleSubmit}
                      >
                        Update
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={this.nextBtnClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default AdventureDuration;
