import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import apiService from "../../apiservices/apiservice";
import Datatable from "../common/datatable";
import "jspdf-autotable";
import FullPageLoader from "../common/FullPageLoader/fullpageloader";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

class ExperienceReservationList extends Component {
  state = {
    experience_reservation_list: [],
    isDataLoaded: false,
    loading: false,
    redirect:null
  };

  showLoader() {
    this.setState({ loading: true });
  }
  closeLoader() {
    this.setState({ loading: false });
  }
  componentDidMount() {
    this.getReservationList();
  }

  getReservationList() {
    let _this = this;
    _this.showLoader();
    let url = "booking/experience?sortBy=id:desc";
    apiService
      .get_mng_api(url)
      .then((response) => {
        _this.closeLoader();
        if (response && response.status === 200) {
          //console.log(response);
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {

                var vId;
                if(dataList[key]["id"] !== null){
                    vId = dataList[key]["id"];
                }
                else{
                    vId = "";
                }

                var vHostName;
                if(dataList[key]["host"] !== null){
                    vHostName = dataList[key]["host"]["firstName"];
                }
                else{
                    vHostName = "";
                }

                var vGuestName;
                if(dataList[key]["guest"] !== null){
                    vGuestName = dataList[key]["guest"]["firstName"];
                }
                else{
                    vGuestName = "";
                }

                var vBookingCode;
                if(dataList[key]["bookingCode"] !== null){
                    vBookingCode = dataList[key]["bookingCode"];
                }
                else{
                    vBookingCode = "";
                }

                var vExperienceTitle;
                if(dataList[key]["experienceVO"] !== null){
                    vExperienceTitle = dataList[key]["experienceVO"]["title"];
                }
                else{
                    vExperienceTitle = "";
                }

                var vFeesTotal;
                if(dataList[key]["feesTotal"] !== null){
                    vFeesTotal = dataList[key]["feesTotal"];
                }
                else{
                    vFeesTotal = "";
                }

                var vBookingDate;
                if(dataList[key]["bookingDate"] !== 0){
                    vBookingDate = new Date(dataList[key]["bookingDate"]);
                }
                else{
                    vBookingDate = "";
                }

              dataPush.push({
                id: vId,
                hostName: vHostName,
                guestName:vGuestName,
                bookingCode:vBookingCode,
                experienceTitle:vExperienceTitle,
                totalAmount:vFeesTotal,
                bookingDate:vBookingDate
              });
            }
          }
          _this.setState({
            experience_reservation_list: dataPush,
            isDataLoaded: true,
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            _this.setState({ redirect: "/pages/authentication/login" });
            toast.error("Session Expired", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Reservation Management / Experience Reservation List" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <h5>Experience Reservation List</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <div className="col-sm-12 text-right">
                      <div
                        className="btn-group btn-group-square"
                        role="group"
                        aria-label="Basic example"
                      >
                        <button
                          className="btn btn-outline-success"
                          type="button"
                          disabled={true}
                        >
                          <i className="icofont icofont-document-folder"></i>
                        </button>
                        <button
                          className="btn btn-outline-success"
                          type="button"
                          //onClick={() => this.exportPDF()}
                        >
                          <i className="icofont icofont-file-pdf"></i>&nbsp;Pdf
                        </button>
                        <button
                          className="btn btn-outline-success"
                          type="button"
                        >
                          <i className="icofont icofont-file-excel"></i>
                          &nbsp;Excel
                        </button>
                        <button
                          className="btn btn-outline-success"
                          type="button"
                        >
                          <i className="icofont icofont-file-code"></i>&nbsp;CSV
                        </button>
                      </div>
                    </div>
                  </div>
                  {this.state.loading ? (
                    <FullPageLoader loading={this.state.loading} />
                  ) : null}
                  <div className="form-group row datatable-react">
                    <div className="col-sm-12">
                      {this.state.isDataLoaded ? (
                        <Datatable
                          multiSelectOption={false}
                          myData={this.state.experience_reservation_list}
                          pageSize={5}
                          pagination={true}
                          class="-striped -highlight"
                          callFrom="ExperienceReservationList"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ExperienceReservationList;
