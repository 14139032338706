const TOKEN_KEY = "token";
const USER_PROFILE = "profile";
const REFRESH_TOKEN_KEY = "refresh_token_info";

const isServer = typeof window === "undefined";

export const getToken = () => {
	let token = process.browser ? localStorage.getItem(TOKEN_KEY) : "";
	return token;
};

export const saveToken = (token) => {
	window.localStorage.setItem(TOKEN_KEY, token);
};

export const destroyToken = () => {
	window.localStorage.removeItem(TOKEN_KEY);
};


export const saveUser = (user) => {
	let user_encode = Buffer.from(JSON.stringify(user)).toString("base64");
	window.localStorage.setItem(USER_PROFILE, user_encode);
};
export const getUser = () => {
	let user_decode = "";
	let userInfo = process.browser ? localStorage.getItem(USER_PROFILE) : null;
	if (userInfo !== null) {
		user_decode = JSON.parse(Buffer.from(userInfo, "base64").toString("ascii"));
	}
	return user_decode;
};
export const destroyUser = () => {
	window.localStorage.removeItem(USER_PROFILE);
};

export function getAuthentication() {
	let isAuthenticate = false;
	if (!isServer) {
		let userInfo = getUser();
		if (userInfo && Object.keys(userInfo).length > 0) {
			isAuthenticate = true;
		}
	}
	return isAuthenticate;
}


export const getRefreshToken = () => {
	let token = process.browser
		? JSON.parse(localStorage.getItem(REFRESH_TOKEN_KEY))
		: "";
	return token;
};

export const saveRefreshToken = (rToken) => {
	let _rToken = JSON.stringify(rToken);
	window.localStorage.setItem(REFRESH_TOKEN_KEY, _rToken);
};

export const destroyRefreshToken = () => {
	window.localStorage.removeItem(REFRESH_TOKEN_KEY);
};


// eslint-disable-next-line import/no-anonymous-default-export
export default { getToken, saveToken, destroyToken, saveUser, getUser, destroyUser,getAuthentication };