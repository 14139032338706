import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
// import apiService from "../../apiservices/apiservice";
import { ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { Calendar} from "antd";

class CalendarPage extends Component {
  state = {
    selectedMonth: moment().month(),
    isPevBtnDisable: true,
    selectedDates: [],
    loading: true,
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
  };
  componentDidMount() {
    console.log("propid", this.state.property_id);
    if (this.state.property_id !== undefined) {
      this.getEditData();
    }
  }
  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("propDetails"));
    let selectedDates = [];
      if (resp && resp["calenderVOList"].length > 0) {
        for (let key in resp["calenderVOList"]) {
          selectedDates.push(resp["calenderVOList"][key]["blockDate"]);
        }
      }
      this.setState({
        selectedDates: selectedDates
      });
  }
  onSelect = (value) => {
    let date = value.format("YYYY-MM-DD");
    let selectedDates = this.state.selectedDates;
    let check = false;
    if (selectedDates.length > 0) {
      check = selectedDates.includes(date);
    }
    if (check) {
      var index = selectedDates.indexOf(date);
      if (index !== -1) selectedDates.splice(index, 1);
    } else {
      selectedDates.push(date);
    }
    this.setState({
      selectedDates: selectedDates,
    });
  };
  disabledDate(value) {
    const valueDate = value.format("YYYY-MM-DD");
    //const currentFirstDate = moment().startOf("month").format("YYYY-MM-DD");
    const currentFirstDate = moment().format("YYYY-MM-DD");
    // const dow = moment(valueDate).format("dddd");
    // if (dow == "Sunday") {
    //   return true;
    // }
    if (valueDate >= currentFirstDate) {
      return false;
    }
    return true;
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const next = this.props.continue;
    let calenderParamList = [];
    let selectedDates = this.state.selectedDates;
    if (selectedDates.length > 0) {
      for (let key in selectedDates) {
        calenderParamList.push({
          blockDate: selectedDates[key],
        });
      }
    }
    let params = {};
    let _this = this;
    let property_id = this.state.property_id;
    let url = "properties/" + property_id + "/calendar";
    params["calenderParamList"] = calenderParamList;
    console.log("param", params);
    // apiService
    //   .update_method(params, url)
    //   .then((response) => {
    //     console.log("response", response);
    //     if (response && response.status === 200) {
    //       toast.success("Updated Successfully.", {
    //         position: toast.POSITION.BOTTOM_RIGHT,
    //       });
    //       localStorage.setItem("propDetails", JSON.stringify(response.data));
    //       this.setState({
    //         property_id: response.data.id,
    //         // redirect: "/propertiesmanagement/propertylist",
    //         user_id: response.data.user.id,
    //       });
    //       // console.log(response);
    //     } else {
    //       toast.error(response.error_description, {
    //         position: toast.POSITION.BOTTOM_RIGHT,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     // loginState.closeLoader();
    //     toast.error("Exception Occured!!", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //     });
    //     // console.log(error);
    //   });
    // $axios.put(url, JSON.stringify(params)).then(function (resp) {
    //   if (resp && resp.status == 200) {
    //     _this.props.dispatch(adventrueForm("calendar", property_id));
    //     next({ page: 10, step: 3, data: resp });
    //     //Router.push({ pathname: "/become-a-host/price-space" });
    //   }
    // });
  };
  nextBtnClick = (e) => {
    this.setState({
      redirect: "/propertiesmanagement/pricespace",
      property_id: this.state.property_id,
      user_id: this.state.user_id,
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/gueststay",
      user_id: this.state.user_id
    });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    const {
      selectedMonth,
      selectedDates,
      isPevBtnDisable,
      loading,
    } = this.state;
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Calendar" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Select dates to block or unblock</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <Calendar
                        className="col-sm-12"
                        fullscreen={true}
                        disabledDate={this.disabledDate}
                        onSelect={this.onSelect}
                        dateFullCellRender={(value) => {
                          const valueDate = value.format("YYYY-MM-DD");
                          const currentFirstDate = moment().format(
                            "YYYY-MM-DD"
                          );
                          let a = value.date();
                          let check = false;
                          if (selectedDates && selectedDates.length > 0) {
                            check = selectedDates.includes(valueDate);
                          }
                          let styleDate = {};
                          let styleValue = { color: "#FFFFFF" };
                          if (check) {
                            styleDate = { backgroundColor: "#FF0000" };
                          }
                          if (!(valueDate >= currentFirstDate)) {
                            styleDate = { backgroundColor: "#EFAA5B" };
                          }
                          return (
                            <div
                              className="ant-fullcalendar-date col-sm-12"
                              style={styleDate}
                            >
                              <div className="ant-fullcalendar-value col-sm-12">
                                <span style={styleValue}>{a}</span>
                              </div>
                              <div className="ant-fullcalendar-content col-sm-12"></div>
                            </div>
                          );
                        }}
                        headerRender={({
                          value,
                          type,
                          onChange,
                          onTypeChange,
                        }) => {
                          const selectedDate = value.format("YYYY-MM-DD");
                          const currentDate = moment().format("YYYY-MM-DD");
                          const monthCount = moment(selectedDate).diff(
                            moment(currentDate),
                            "months",
                            true
                          );
                          const month = value.month();
                          const yearName = value.year();
                          const monthName = moment()
                            .month(month)
                            .format("MMMM");
                          return (
                            <div style={{ marginBottom: 20 }}>
                              <div
                                className="btn-group btn-group-square"
                                role="group"
                                aria-label="Basic example"
                              >
                                <button
                                  className="btn btn-outline-success"
                                  type="button"
                                  disabled={monthCount === 0 ? true : false}
                                  onClick={() => {
                                    const newValue = value.clone();
                                    let newMonth = selectedMonth - 1;
                                    newValue.month(parseInt(newMonth, 10));
                                    if (newMonth === -1) {
                                      newMonth = 11;
                                    }
                                    this.setState({ selectedMonth: newMonth });
                                    onChange(newValue);
                                  }}
                                >
                                  <i className="fa fa-toggle-left"></i>
                                </button>
                                <button
                                  className="btn btn-outline-success"
                                  type="button"
                                  disabled={monthCount > 22 ? true : false}
                                  onClick={() => {
                                    const newValue = value.clone();
                                    let newMonth = selectedMonth + 1;
                                    newValue.month(parseInt(newMonth, 10));
                                    if (newMonth === 12) {
                                      newMonth = 0;
                                    }
                                    this.setState({ selectedMonth: newMonth });
                                    this.setState({ isPevBtnDisable: false });
                                    onChange(newValue);
                                  }}
                                >
                                  <i className="fa fa-toggle-right"></i>
                                </button>
                              </div>

                              <strong style={{ marginLeft: 5 }}>
                                {monthName} {yearName}
                              </strong>
                              <button
                                className="btn btn-outline-danger float-right"
                                type="button"
                                onClick={() => {
                                  const newValue = value.clone();
                                  var daysInMonth = moment(
                                    newValue
                                  ).daysInMonth();
                                  while (daysInMonth) {
                                    var current = moment(newValue).date(
                                      daysInMonth
                                    );
                                    this.onSelect(current);
                                    daysInMonth--;
                                  }
                                }}
                              >
                                <span className="text-primary">
                                  Block this month
                                </span>
                              </button>
                              {/* <Button
                                className="float-right"
                                onClick={() => {
                                  const newValue = value.clone();
                                  var daysInMonth = moment(
                                    newValue
                                  ).daysInMonth();
                                  while (daysInMonth) {
                                    var current = moment(newValue).date(
                                      daysInMonth
                                    );
                                    this.onSelect(current);
                                    daysInMonth--;
                                  }
                                }}
                              >
                                <span className="text-primary">
                                  Block this month
                                </span>
                              </Button> */}
                            </div>
                          );
                        }}
                      />
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Fragment>
    );
  }
}

export default CalendarPage;
