import React, { Component, Fragment } from "react";
import { $managementAxios } from "../../lib/unility/http-service";
import DataTable from "react-data-table-component";
import { Redirect } from "react-router-dom";
import moment from "moment";
import BackButton from "../common/BackButton/BackButton";

class MessagingList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			columns: [
				{
					name: "Guest Name",
					selector: (row) =>
						row.sender?.firstName + " " + row.sender?.lastName || "",
					sortable: true,
					grow: 0.3,
				},
				{
					name: "Host Name",
					selector: (row) =>
						row.receiver?.firstName + " " + row.receiver?.lastName || "",
					sortable: true,
					grow: 0.3,
				},
				{
					name: "Last Message",
					selector: (row) => row.lastMessage?.slice(0,50) || "",
					sortable: true,
					grow: 1,
				},
				{
					name: "Message Created",
					selector: (row) => row.createdAt || "",
					sortable: true,
					grow: 0.1,
				},
				{
					name: "Property",
					selector: (row) => row?.bookingRequest?.title || "",
					sortable: true,
					grow: .3,
				},
				{
					name: "Action",
					fixed: true,
					cell: (row) => (
						<div className='d-flex flex-row justify-content-center'>
							<div>
								<i
									className='btn icofont icofont-eye p-2'
									onClick={() => {
										this.viewMessageDetails(row);
									}}
								></i>
							</div>
						</div>
					),
					button: true,
					grow: 2,
				},
			],
			loading: false,
			data: {},
			selectedId: null,
			parameters: {
				page: 1,
				pageSize: 10,
				sortBy: "id:desc",
			},
		};
	}

	showLoader() {
		this.setState({ loading: true });
	}
	closeLoader() {
		this.setState({ loading: false });
	}

	componentDidMount() {
		const storedParameters = JSON.parse(localStorage.getItem("messageParams"));
		if (storedParameters) {
			this.updateParameters(storedParameters);
			this.getData(
				storedParameters.page,
				storedParameters.pageSize,
				storedParameters.sortBy
			);
		} else {
			this.getData(
				this.state.parameters.page,
				this.state.parameters.pageSize,
				this.state.parameters.sortBy
			);
		}
	}

	updateParameters = (newParameters) => {
		this.setState({ parameters: newParameters }, () => {
			localStorage.setItem("messageParams", JSON.stringify(newParameters));
		});
	};

	getData = (pageNo, pageSize, sortBy) => {
		this.setState({ loading: true });
		$managementAxios
			.get(
				"/message/rooms?pageSize=" +
					pageSize +
					"&pageNo=" +
					pageNo +
					"&sortBy=" +
					sortBy
			)
			.then((response) => {
				response.data?.content.forEach((element) => {
					element.createdAt = new moment(new Date(element.createdAt)).format(
						"DD-MM-YYYY"
					);
				});
				this.setState({
					rowData: response.data,
					data: response.data,
					loading: false,
				});
			});
	};

	onChangePages = (page, totalRows) => {
		this.setState({ page: page });
		this.updateParameters({
			...this.state.parameters,
			page: page,
		});
		this.getData(
			page,
			this.state.parameters.pageSize,
			this.state.parameters.sortBy
		);
	};

	onChangePerPage = (currentRowsPerPage, currentPage) => {
		this.setState({ pageSize: currentRowsPerPage });
		this.updateParameters({
			...this.state.parameters,
			pageSize: currentRowsPerPage,
		});
		this.getData(
			this.state.parameters.page,
			currentRowsPerPage,
			this.state.parameters.sortBy
		);
	};

	viewMessageDetails = (data) => {
		this.setState({
			redirect: "/messagingmanagement/msgdetails",
			selectedId: data.id,
			...this.state.parameters,
		});
	};

	render() {
		if (this.state.redirect) {
			return (
				<Redirect
					to={{
						pathname: this.state.redirect,
						state: {
							message_id: this.state.selectedId,
							...this.state.parameters,
						},
					}}
				/>
			);
		}
		return (
			<Fragment>
				<div className='container-fluid'>
					<div className='d-flex justify-content-between mb-3'>
						<div>
							<h5>Messaging List</h5>
						</div>
						<div>
							<BackButton />
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-12'>
							<DataTable
								keyField='id'
								columns={this.state.columns}
								data={this.state.data.content}
								pagination={true}
								paginationServer={true}
								paginationTotalRows={this.state.data.totalElements}
								onChangePage={this.onChangePages}
								onChangeRowsPerPage={this.onChangePerPage}
								progressPending={this.state.loading}
								dense
							/>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default MessagingList;
