import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { $managementAxios } from "../../lib/unility/http-service";
import swal from "sweetalert";

class PropertyList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: [
				{
					name: "ID",
					selector: (row) => row.id,
					maxWidth: "5px",
					sortable: true,
					id: "id",
					style: {
						textAlign: "center",
						position: "static",
					},
				},
				{
					name: "Property Name",
					selector: (row) => row.name || "",
					sortable: true,
					id: "name",
				},
				{
					name: "Host Name",
					selector: (row) => row.host?.firstName || "",
					sortable: false,
				},
				{
					name: "Property Type",
					selector: (row) => row.propertyType?.name || "",
					sortable: true,
					id: "propertyType",
				},
				{
					name: "Create Date",
					selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
					sortable: true,
					id: "createdAt",
				},
				{
					name: "Status",
					cell: (row) => (
						<Dropdown
							className='w-100'
							onSelect={(newValue) => this.handleStatusChange(row.id, newValue)}
						>
							<Dropdown.Toggle
								variant='primary'
								id={`status-dropdown-${row.id}`}
								className='w-100'
							>
								{this.state.selectedStatus[row.id] || row.approvalFlag}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item eventKey='PENDING' className='w-100'>
									PENDING
								</Dropdown.Item>
								<Dropdown.Item eventKey='LISTED' className='w-100'>
									LISTED
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					),
				},
				{
					name: "Promotion",
					cell: (row) => (
						<Dropdown
							className='w-100'
							onSelect={(newValue) =>
								this.handlePromotionChange(row.id, newValue)
							}
						>
							<Dropdown.Toggle
								variant='primary'
								id={`status-dropdown-${row.id}`}
								className='w-100'
							>
								{this.state.selectedPromotion[row.id] || row.promotionFlag}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item eventKey='PROMOTED' className='w-100'>
									PROMOTED
								</Dropdown.Item>
								<Dropdown.Item eventKey='DEMOTED' className='w-100'>
									DEMOTED
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					),
				},
				{
					name: "Action",
					cell: (row) => (
						<div className='d-flex flex-row justify-content-center'>
							<div>
								<i
									className='btn fa fa-pencil p-2'
									onClick={() => {
										this.editProperty(row.id);
									}}
								></i>
							</div>
						</div>
					),
					button: true,
				},
			],
			loading: false,
			isDataLoaded: false,
			data: [],
			page: 1,
			pageSize: 10,
			sortBy: "id:desc",
			selectedStatus: {},
			selectedPromotion: {},
			propertyId: null,
			filter: {
				status: "",
				promotion: "",
				phone: null,
				email: null,
			},
		};
	}

	showLoader() {
		this.setState({ loading: true });
	}

	closeLoader() {
		this.setState({ loading: false });
	}

	componentDidMount() {
		this.getProperties(this.state.page, this.state.pageSize, this.state.sortBy);
	}


	handleSearch = () => {
		this.getProperties(this.state.page, this.state.pageSize, this.state.sortBy);
	};

	changeFilter = (value, property) => {
		let _filter = this.state.filter;
		_filter[property] = value;
		this.setState({ filter: _filter });
	};

	getProperties = (pageNo, pageSize, sortBy) => {
		this.setState({ loading: true });
		$managementAxios
			.get(
				"/accommodations?pageNo=" +
					pageNo +
					"&pageSize=" +
					pageSize +
					"&sortBy=" +
					sortBy +
					"&approvalFlag=" +
					this.state.filter.status +
					"&promotionFlag=" +
					this.state.filter.promotion
			)
			.then((response) => {
				response.data?.content.forEach((property) => {
					property.createdat = moment(property.createdAt).format("DD-MM-YYYY");
					property.lastmodifiedat = moment(property.lastModifiedAt).format(
						"DD-MM-YYYY"
					);
				});
				this.setState({ data: response.data, rowData: response.data.content });
				this.setState({ loading: false, isDataLoaded: true });
			})
			.catch((error) => {
				this.setState({ loading: false });
			});
	};

	onChangePages = (page, totalRows) => {
		this.setState({ page: page });
		this.getProperties(page, this.state.pageSize, this.state.sortBy);
	};

	onChangePerPage = (currentRowsPerPage, currentPage) => {
		this.setState({ pageSize: currentRowsPerPage });
		this.getProperties(this.state.page, currentRowsPerPage, this.state.sortBy);
	};

	editProperty = (propertyId) => {
		this.setState({
			redirect: "/propertiesmanagement/placesetting",
			propertyId: propertyId,
		});
	};

	handleSort = (column, sortDirection) => {
		var sort = column.id + ":" + sortDirection;
		this.setState({ sortBy: sort });
		this.getProperties(this.state.page, this.state.pageSize, sort);
	};

	handleStatusChange = (propertyId, newValue) => {
		swal({
			title: "Are you sure?",
			text: "You want to update the status?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willUpdate) => {
			if (willUpdate) {
				this.setState((prevState) => ({
					selectedStatus: {
						...prevState.selectedStatus,
						[propertyId]: newValue,
					},
				}));

				const payload = {
					approvalStatus: newValue,
				};

				$managementAxios
					.put(
						"/accommodations/" + propertyId + "/update-approval",
						JSON.stringify(payload),
						{}
					)
					.then((response) => {
						this.getProperties(
							this.state.page,
							this.state.pageSize,
							this.state.sortBy
						);
					})
					.catch((error) => {});
			}
		});
	};

	handlePromotionChange = (propertyId, newValue) => {
		swal({
			title: "Are you sure?",
			text: "You want to update the status?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willUpdate) => {
			if (willUpdate) {
				this.setState((prevState) => ({
					selectedPromotion: {
						...prevState.selectedPromotion,
						[propertyId]: newValue,
					},
				}));
				debugger;
				const payload = {
					promotionFlag: newValue,
				};

				$managementAxios
					.put(
						"/accommodations/" + propertyId + "/update-promotion",
						JSON.stringify(payload),
						{}
					)
					.then((response) => {
						this.getProperties(
							this.state.page,
							this.state.pageSize,
							this.state.sortBy
						);
					})
					.catch((error) => {});
			}
		});
	};

	render() {
		if (this.state.redirect) {
			return (
				<Redirect
					to={{
						pathname: this.state.redirect,
						state: { property_id: this.state.propertyId },
					}}
				/>
			);
		}

		return (
			<Fragment>
				<Breadcrumb parent='Properties Management / Property List' />
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-sm-12'>
							<div className='card'>
								<div className='card-header'>
									<div className='row'>
										<div className='col-sm-3'>
											<h5>Property Management</h5>
										</div>
										<div className='col-sm-9 text-right'>
											<button
												type='submit'
												className='btn btn-primary mr-1'
												onClick={() => {
													this.setState({
														redirect: "/propertiesmanagement/addproperties",
													});
												}}
											>
												<i className='fa fa-plus pr-2'></i>
												Add Property
											</button>
										</div>
									</div>
									<div className='d-flex justify-content-end align-item-center mt-1'>
										<Dropdown
											className='p-1'
											onSelect={(newValue) => {
												this.changeFilter(newValue, "status");
											}}
										>
											<Dropdown.Toggle
												variant='secondary'
												id={`filter-status-dropdown`}
											>
												{this.state.filter.status === ""
													? "SELECT STATUS"
													: this.state.filter.status}
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item eventKey=''>SELECT STATUS</Dropdown.Item>
												<Dropdown.Item eventKey='PENDING'>
													PENDING
												</Dropdown.Item>
												<Dropdown.Item eventKey='LISTED'>LISTED</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>

										<Dropdown
											className='p-1'
											onSelect={(newValue) => {
												this.changeFilter(newValue, "promotion");
											}}
										>
											<Dropdown.Toggle
												variant='secondary'
												id={`filter-promotion-dropdown`}
											>
												{this.state.filter.promotion === ""
													? "SELECT PROMOTION"
													: this.state.filter.promotion}
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item eventKey=''>SELECT PROMOTION</Dropdown.Item>
												<Dropdown.Item eventKey='PROMOTED'>PROMOTED</Dropdown.Item>
												<Dropdown.Item eventKey='DEMOTED'>DEMOTED</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
										<div className='p-1'>
											<button
												className='btn btn-success'
												onClick={this.handleSearch}
											>
												Search
											</button>
										</div>
									</div>
								</div>

								<div className='card-body'>
									<div className='form-group row datatable-react'>
										<div className='col-sm-12'>
											<DataTable
												keyField='id'
												columns={this.state.columns}
												data={this.state.data.content} // tried data={this.state.data}
												pagination={true}
												paginationServer={true}
												paginationTotalRows={this.state.data.totalElements}
												onChangePage={this.onChangePages}
												onChangeRowsPerPage={this.onChangePerPage}
												progressPending={this.state.loading}
												onSort={this.handleSort}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default PropertyList;
