import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { ToastContainer, toast } from "react-toastify";
import apiService from "../../apiservices/apiservice";
import { Redirect } from "react-router-dom";

class KeepCalendarUpdate extends Component {
  state = {
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
    keepCalenderUpToDate: false,
  };
  componentDidMount() {
    this.getEditData();
  }

  getEditData() {
    // let _this = this;
    var resp = JSON.parse(localStorage.getItem("propDetails"));

    if (resp["keepCalenderUpToDate"]) {
      this.setState({
        keepCalenderUpToDate: resp["keepCalenderUpToDate"],
      });
    }
  }

  handleChange(e) {
    let isChecked = e.target.checked;
    // do whatever you want with isChecked value
    if (isChecked) {
      this.setState({
        keepCalenderUpToDate: true,
      });
    } else {
      this.setState({
        keepCalenderUpToDate: false,
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let property_id = this.state.property_id;
        // let _this = this;
        let url = "properties/" + property_id + "/keep-calendar-up-to-date";
        let params = {};

        params["keepCalenderUpToDate"] = this.state.keepCalenderUpToDate;

        apiService
        .update_method(params, url)
        .then((response) => {
          console.log("response", response);
          if (response && response.status === 200) {
            toast.success("Updated Successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            localStorage.setItem("propDetails",JSON.stringify(response.data));
            this.setState({
              property_id:response.data.id,
              // redirect: "/propertiesmanagement/addcalendar",
              user_id:response.data.user.id
            });
            // console.log(response);
          } else {
            toast.error(response.error_description, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((error) => {
          // loginState.closeLoader();
          toast.error("Exception Occured!!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // console.log(error);
        });

        
  };
  nextBtnClick = (e) => {
    this.setState({
      // redirect: "/propertiesmanagement/addcalendar",
      redirect: "/propertiesmanagement/availabilityquestion",
      property_id:this.state.property_id,
      user_id: this.state.user_id
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/houserules",
      user_id: this.state.user_id
    });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom : this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Keep Calendar Update" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>An accurate calendar helps you to get frequent guests</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <p>
                        If you keep the calendar up-to-date then you will get
                        more bookings and guests will be able to book available
                        days instantly.
                      </p>
                      <p>
                        If you cancel any booking made by the guests due to your
                        inaccurate calendar then it creates chaos, you may
                        receive negative review and overall it creates bad
                        impression.
                      </p>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 d-block" for="chk-ani">
                        <input
                          className="checkbox_animated"
                          id="chk-ani"
                          type="checkbox"
                          checked={this.state.keepCalenderUpToDate? true:false}
                          onChange={(e) => this.handleChange(e)}
                        />
                        I will take care of the calendar
                      </label>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer/>
      </Fragment>
    );
  }
}

export default KeepCalendarUpdate;
