import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Redirect } from "react-router-dom";

class PropertiesCompletion extends Component {
    state = {
        redirect:""
    }
    nextBtnClick = (e) => {
        this.setState({
          redirect: "/dashboard/project"
        });
      };
  render() {
    if (this.state.redirect) {
        return (
          <Redirect
            to={{
              pathname: this.state.redirect,
            }}
          />
        );
      }
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Complete" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card bg-primary">
                <div className="card-body">
                  <div className="media faq-widgets">
                    <div className="media-body">
                      <h5>Congratulations!!</h5>
                      <p>
                        You just completed the process of Properties Creation/Update.
                      </p>
                      <div className="col-sm-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Back to Home
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default PropertiesCompletion;
