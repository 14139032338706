import axios from "axios";
import { APP_API_URL, OPEN_API_URL, LOGIN_TOKEN, MANAGEMENT_API_URL } from "../constants";
import {
	getToken,
	getAuthentication,
	destroyUser,
  destroyToken,
  getRefreshToken,
  saveRefreshToken,
  saveToken
} from "../../services/storage.service";
let isRefreshing = false;
let refreshSubscribers = [];

const caxios = axios.create({
  baseURL: APP_API_URL,
  timeout: 200000,
  headers: {
    Authorization: "Bearer " + getToken,
    "Content-Type": "multipart/form-data",
    "Content-Type": "application/json",
  },
  transformRequest: [
    function (data, headers) {
      return data;
    },
  ],
  validateStatus: function (status) {
    if(status < 300 || status === 400 || status === 422 || status === 302 || status === 500 ) {
        return status;
    }
  }
});

const openAxios = axios.create({
  baseURL: OPEN_API_URL,
  timeout: 200000,
  headers: {
    Authorization: LOGIN_TOKEN,
    "Content-Type": "application/json",
  },
  transformRequest: [
    function (data, headers) {
      return data;
    },
  ],
});

const managementAxios = axios.create({
  baseURL: MANAGEMENT_API_URL,
  timeout: 200000,
  headers: {
    Authorization: LOGIN_TOKEN,
    "Content-Type": "application/json",
  },
  transformRequest: [
    function (data, headers) {
      return data;
    },
  ],
});

managementAxios.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
}, (error) => { })


caxios.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
}, (err) => { })

caxios.interceptors.response.use(
	(res) => res,
  (error) => {
		const originalConfig = error.config;
		if (error.response && error.response.status === 401 &&!originalConfig._retry)
    {
      if (!isRefreshing) {
        isRefreshing = true;
        RefreshToken().then((_) => {
          isRefreshing = false;
          onRrefreshed(_.access_token);
        });
      }
      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((token) => {
					originalConfig.headers["Authorization"] = "Bearer " + token;
					resolve(caxios(originalConfig));
				});
      });
      return retryOrigReq;
		} else {
			return Promise.reject(error);
		}
	}
);

function subscribeTokenRefresh(cb){
  refreshSubscribers.push(cb);
}

function onRrefreshed(token){
  refreshSubscribers.map(cb => cb(token));
}

export function RefreshToken() {
  if(getAuthentication()) {
    let refreshTokenInfo = getRefreshToken();
    const requestBody = {
      refreshToken: refreshTokenInfo['refresh_token']};
    const config = {
      headers: {
        Authorization: LOGIN_TOKEN,
        "Content-Type": "application/json"
      }
    };
    let _url = OPEN_API_URL+"refresh";
    const response = axios.put(_url, requestBody, config)
      .then((resp) => {
        if (resp && resp.status === 200) {
          setTokens(resp);
          return Promise.resolve(true);
        }
        else {
          clearTokens();
          return Promise.reject(resp);
        }
      })
      .catch((err) => {
        clearTokens();
        return Promise.reject(err);
      });
    return response;
  }
}

function setTokens(resp) {
  let token = resp.data.access_token;
	let _rTokenInfo = {
		refresh_token: resp.data.refresh_token,
		expires_in: resp.data.expires_in,
  };

  delete axios.defaults.headers.common["Authorization"];
	axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  
	saveRefreshToken(_rTokenInfo);
	saveToken(token);
}

function clearTokens() {
  destroyUser();
  destroyToken();
	window.location = "/";
}
export const $axios = caxios;
export const $openAxios = openAxios;
export const $managementAxios = managementAxios;


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  $axios,
  $openAxios,
  $managementAxios,
};
