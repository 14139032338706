import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

class AdventureSkills extends Component {
  state = {
    questionList: [],
    questionAnswerList: [],
    savedAnswers: [],
    questionAnswer_id: "",
    spentTimeCheck: 1204,
    practicingCheck: "",
    answerIds:[],
    isSavedAnswerLoaded: false,
    adventure_id: this.props.location.state
      ? this.props.location.state.adventure_id
      : null,
    user_id: this.props.location.state
      ? this.props.location.state.user_id
      : null,
    callFrom: this.props.location.state
      ? this.props.location.state.callFrom
      : null,
    hostId: this.props.location.state ? this.props.location.state.hostId : null,
  };

  componentDidMount() {
    this.getQuestion();
    this.getAnswer();
    if (this.state.adventure_id !== null) {
      this.getEditData();
    }
  }

  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("adventureDetails"));
    console.log("resp", resp);
    if (resp) {
      if (resp["skillSet"]) {
        let dataPush = [];
        let pEvents = resp["skillSet"];
        var dataList = [];
        dataList = pEvents.split(",");

        if (dataList.length > 0) {
          for (let key in dataList) {
            // let vName = "";

            // for (var j = 0; j < this.state.questionAnswerList.length; j++) {
            //   if (dataList[key] === this.state.questionAnswerList[j].id) {
            //     vName = this.state.questionAnswerList[j].name;
            //   }
            // }
            dataPush.push({
              id: dataList[key],
              //   name: vName,
            });
          }
        }

        _this.state.savedAnswers = dataPush;
        _this.state.isSavedAnswerLoaded = true;
        // console.log("pushded", _this.state.savedAnswers);
      }
    }

    // console.log("saved ans", this.state.savedAnswers);
  }

  getQuestion() {
    let _this = this;
    let url = "adventure_skill_question";
    apiService
      .get_api(url)
      .then((response) => {
        if (response && response.status === 200) {
          let dataList = response.data;
          console.log("response", dataList);
          let dataPush = [];
          let dataPush2 = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
                adventureSkillAnswerVOList:
                  dataList[key]["adventureSkillAnswerVOList"],
              });
            }
          }
          if (dataPush.length > 0) {
            for (let i = 0; i < dataPush.length; i++) {
              for (
                let j = 0;
                j < dataPush[i].adventureSkillAnswerVOList.length;
                j++
              ) {
                dataPush2.push({
                  id: dataPush[i].adventureSkillAnswerVOList[j].id,
                });
              }
            }
          }
          _this.setState({
            questionList: dataPush,
            answerIds: dataPush2,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAnswer() {
    let _this = this;
    let url = "adventure_skill_answer";
    apiService
      .get_api(url)
      .then((response) => {
        if (response && response.status === 200) {
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              dataPush.push({
                id: dataList[key]["id"],
                name: dataList[key]["name"],
                checked: 1204,
                experienceSkillQuestionVO:
                  dataList[key]["adventureSkillQuestionVO"],
              });
            }
          }
          _this.setState({
            questionAnswerList: dataPush,
          });

          //   console.log("answer", this.state.questionAnswerList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onChangeAnswer(event) {
    console.log("method called", event);
    // let _this = this;
    // var targetValue = Number(event.target.id);

    // this.setState({
    //     spentTimeCheck:event
    // })

    // this.setState((state) => {
    //   state.questionAnswerList.map((item, j) => {
    //     item.checked = event;
    //     // if (targetValue === item.id) {
    //     //   item.checked = true;
    //     // }
    //   });
    // });
    this.setState((state) => {
        state.answerIds.map((item, j) => {
          if (event === item.id) {
            item.checked = true;
          }
          else{
            item.checked = false;
          }
        });
      });
      this.setState((state) => {
        state.savedAnswers.map((item, j) => {
          if (event === item.id) {
            // console.log("dhukseeee");
            item.id = event;
          }
          // else{
          //   item.checked = false;
          // }
        });
      });
    console.log("updatedAnswerList", this.state.answerIds);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    // let _this = this;
    let adventure_id = this.state.adventure_id;

    let url = "adventure/" + adventure_id + "/skill-set";
    params["expertise"] = "Tour guide";

    // let answerString = "";
    // let vQuestionList = this.state.questionList;
    // let vAnswerList = this.state.questionAnswerList;
    // for (var i = 0; i < vQuestionList.length; i++) {
    //   let isChecked = false;
    //   for (var j = 0; j < vAnswerList.length; j++) {
    //     if (
    //       vQuestionList[i].id === vAnswerList[j].experienceSkillQuestionVO.id
    //     ) {
    //       isChecked = document.getElementById(vAnswerList[j].id).checked;
    //       if (isChecked) {
    //         if (i === 0) {
    //           answerString += vAnswerList[j].id;
    //         } else {
    //           answerString += "," + vAnswerList[j].id;
    //         }
    //       }
    //     }
    //   }
    // }
    // params["answerIds"] = answerString;
    let answerString = "";
    console.log("length", this.state.answerIds.length);
    for(let i =0 ; i < this.state.answerIds.length; i++){
      if(this.state.answerIds[i].checked){
        if(answerString === ""){
          answerString = this.state.answerIds[i].id
        }
        else{
          console.log("i value", i);
          answerString = answerString + ","+this.state.answerIds[i].id
        }
      }
      
    }
    params["answerIds"] = answerString;

    console.log("param", params);
    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem(
            "adventureDetails",
            JSON.stringify(response.data)
          );
          this.setState({
            adventure_id: response.data.id,
            // redirect: "/experiencemanagement/ideatheme",
            user_id: response.data.host.id,
          });
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  nextBtnClick = (e) => {
    this.setState({
      adventure_id: this.state.adventure_id,
      redirect: "/adventuremanagement/aboutyou",
      user_id: this.state.user_id,
    });
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              adventure_id: this.state.adventure_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    const { questionList } = this.state;
    const { questionAnswerList } = this.state;
    const { savedAnswers } = this.state;
    return (
      <Fragment>
        <Breadcrumb parent="Adventure Management / Skills" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Your Skillsets</h5>
                  <b>Tell us a bit about your background</b>
                </div>
                <div className="card-body">
                  <form className="form theme-form" id="themeForm">
                    {this.state.isSavedAnswerLoaded
                      ? savedAnswers.map((item, key) => {
                          return (
                            <div className="form-group row" key={key}>
                              <p>Saved Skills: </p>
                              {questionAnswerList.map((item1, key1) => {
                                if (Number(item.id) === item1.id) {
                                  return <p key={key1}>{item1.name}</p>;
                                }
                              })}
                            </div>
                          );
                        })
                      : null}

                    <div className="form-group row">
                      <div className="col-sm-12">
                        <div className="card-body btn-showcase">
                          <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                            {questionList.length > 0 &&
                              questionList.map((item, key) => {
                                return (
                                  <div className="card" key={key}>
                                    <div className="card-header">
                                      <h6>
                                        {key + 1}. {item.name}
                                      </h6>
                                    </div>
                                    <div className="card-body btn-showcase col-sm-9">
                                      <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                        {item.adventureSkillAnswerVOList
                                          .length > 0 &&
                                          item.adventureSkillAnswerVOList.map(
                                            (item1, key1) => {
                                              return (
                                                <div
                                                  className="radio radio-primary"
                                                  key={key1}
                                                >
                                                  <input
                                                    id={item1.id}
                                                    type="radio"
                                                    name={
                                                      item1
                                                        .name
                                                    }
                                                    // checked={
                                                    //   item1.id != null
                                                    //     ? true
                                                    //     : false
                                                    // }
                                                    onChange={this.onChangeAnswer.bind(
                                                      this,
                                                      item1.id
                                                    )}
                                                  />
                                                  <label
                                                    className="mb-0"
                                                    htmlFor={item1.id}
                                                  >
                                                    {item1.name}
                                                  </label>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-warning mr-1"
                        // onClick={() => {
                        //   this.setState({
                        //     redirect: "/propertiesmanagement/addproperties",
                        //   });
                        // }}
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        onClick={this.handleSubmit}
                      >
                        Update
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={this.nextBtnClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AdventureSkills;
