import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import StepZilla from "react-stepzilla";
// import Registration from '../../components/forms/wizard/registration';
// import Email from '../../components/forms/wizard/email';
import Birthdate from '../../components/forms/wizard/birthdate';
import Basics from './basics';
import Description from '../properties-management/desciption';
import DescriptionMaster from '../properties-management/DescriptionMaster';

const FormWizard = () => {
    const steps =
        [
            { name: 'Step 1 - Basics', component: <Basics /> },
            { name: 'Step 2 - Description', component: <DescriptionMaster /> },
            { name: 'Step 3', component: <Description /> },
            { name: 'Step 4', component: <Birthdate /> },
        ]
    return (
        <Fragment>
            <Breadcrumb parent="Manage Rooms / Add Room" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Add New Property</h5>
                            </div>
                            <div className="card-body">
                                <StepZilla steps={steps} showSteps={true} showNavigation={true} stepsNavigation={true}
                                    prevBtnOnLastStep={true}
                                    dontValidate={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Fragment>
    );
};

export default FormWizard;