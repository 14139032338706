import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
import apiService from "../../apiservices/apiservice";
import { Redirect } from "react-router-dom";

class AvailabilityQuestion extends Component {
  state = {
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
    availability_a2: ["Not sure yet", "As often as possible", "Part-time"],
    availability_a1: ["I’m new to this", "I have"],
    answerId1: "",
    answerId2: "",
  };

  componentDidMount() {
    console.log("propid", this.state.property_id);
    if (this.state.property_id !== undefined) {
        this.getEditData();
      }
    
  }

  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("propDetails"));
    console.log("res", resp);
    if (resp) {
      if (resp["questionAnswer"]) {
        let questionAnswer = JSON.parse(resp["questionAnswer"]);
        if (questionAnswer[0] && questionAnswer[0]["q1"]) {
          this.setState({
            answerId1: questionAnswer[0]["q1"],
          });
        }
        if (questionAnswer[0] && questionAnswer[0]["q2"]) {
          this.setState({
            answerId2: questionAnswer[0]["q2"],
          });
        }
      }
    }

    console.log("session", this.state.answerId2);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let property_id = this.state.property_id;
    let _this = this;
    let url = "properties/" + property_id + "/availability-questions";
    let params = {};

    params["questionAnswer"] = JSON.stringify([
      {
        q1:  document.getElementById("answerId1").value, // values["answerId1"],
        q2:  document.getElementById("answerId2").value,// this.state.values["answerId2"],
      },
    ]);
    // console.log("save param", params);
    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem("propDetails", JSON.stringify(response.data));
          this.setState({
            property_id: response.data.id,
            // redirect: "/propertiesmanagement/guestnotification",
            user_id: response.data.user.id,
          });
          // console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };

  nextBtnClick = (e) => {
    this.setState({
        redirect: "/propertiesmanagement/guestnotification",
        property_id:this.state.property_id,
      user_id: this.state.user_id
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/keepcalendarupdate",
      user_id: this.state.user_id
    });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    const { availability_a1, availability_a2, loading } = this.state;
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Add Calendar" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Please answer these</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <label
                        htmlFor="place_type_id"
                        className="col-sm-3 col-form-label"
                      >
                        Have you rented out your place before?
                      </label>
                      <div className="col-sm-9">
                        <select
                          value={this.state.answerId1}
                          className="form-control digits"
                          id="answerId1"
                          onChange={() => {
                            this.setState({
                                answerId1: this.value,
                            });
                          }}
                        >
                          <option value="">Please select your property</option>
                          {availability_a1.length > 0 &&
                            availability_a1.map((item, key) => {
                              return (
                                <option key={key} value={key}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="place_type_id"
                        className="col-sm-3 col-form-label"
                      >
                        How often do you want to have guests ?
                      </label>
                      <div className="col-sm-9">
                        <select
                          value={this.state.answerId2}
                          className="form-control digits"
                          id="answerId2"
                          onChange={() => {
                            this.setState({
                                answerId2: this.value,
                            });
                          }}
                        >
                          <option value="">Please select your property</option>
                          {availability_a2.length > 0 &&
                            availability_a2.map((item, key) => {
                              return (
                                <option key={key} value={key}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AvailabilityQuestion;
