import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import { toast } from "react-toastify";
const descriptionObj = {
  descriptionAround: "",
  descriptionAvailability: "",
  descriptionNeighborhood: "",
  descriptionSpace: "",
  descriptionSummery: "",
};
class Description extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResults: true,
      description: {},
      propertyId: props.Id != null ? props.Id : null,
      property_id: this.props.property_id,
      user_id: this.props.user_id,
      callFrom: this.props.callFrom,
    };
    this.onToggle = this.onToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    // this.setState({
    //   propertyId: this.props.propertyId
    // })
    console.log("prop id", this.state.property_id);
    if (this.state.property_id !== undefined) {
      this.getEditData();
    }
    // if (this.state.propertyId) {
    //   apiService
    //     .Get("properties/" + this.state.propertyId)
    //     .then((response) => {
    //       if (response && response.status === 200) {
    //         var data = response.data;
    //         descriptionObj.descriptionAround = data.descriptionAround;
    //         descriptionObj.descriptionAvailability =
    //           data.descriptionAvailability;
    //         descriptionObj.descriptionNeighborhood =
    //           data.descriptionNeighborhood;
    //         descriptionObj.descriptionSpace = data.descriptionSpace;
    //         descriptionObj.descriptionSummery = data.descriptionSummery;
    //         this.setState({
    //           description: descriptionObj,
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       toast.error("Properties not found!", {
    //         position: toast.POSITION.BOTTOM_RIGHT,
    //       });
    //     });
    // }
  }

  getEditData() {
    let _this = this;
    var propDetails = JSON.parse(localStorage.getItem("propDetails"));
    var data = propDetails; //.data;
    descriptionObj.descriptionAround = data.descriptionAround;
    descriptionObj.descriptionAvailability =
      data.descriptionAvailability;
    descriptionObj.descriptionNeighborhood =
      data.descriptionNeighborhood;
    descriptionObj.descriptionSpace = data.descriptionSpace;
    descriptionObj.descriptionSummery = data.descriptionSummery;
    this.setState({
      description: descriptionObj,
    });


  }

  handleChange(event) {
    var target = event.target;
    if (target.name === "descriptionAround") {
      descriptionObj.descriptionAround = target.value;
    } else if (target.name === "descriptionAvailability") {
      descriptionObj.descriptionAvailability = target.value;
    } else if (target.name === "descriptionNeighborhood") {
      descriptionObj.descriptionNeighborhood = target.value;
    } else if (target.name === "descriptionSpace") {
      descriptionObj.descriptionSpace = target.value;
    } else if (target.name === "descriptionSummery") {
      descriptionObj.descriptionSummery = target.value;
    }
    this.setState({
      description: descriptionObj,
    });
    console.log(this.state.description);
  }

  onToggle() {
    this.setState((state) => ({
      showResults: !state.showResults,
    }));
  }
  submit() {
    var url = "properties/" + this.state.property_id + "/descriptions";
    console.log("description", this.state.description);
    let params = {};
    params["descriptionAround"] = this.state.description.descriptionAround;
    params["descriptionAvailability"] = this.state.description.descriptionAvailability;
    params["descriptionNeighborhood"] = this.state.description.descriptionNeighborhood;
    params["descriptionSpace"] = this.state.description.descriptionSpace;
    params["descriptionSummery"] = this.state.description.descriptionSummery;
    console.log("param", params);
    apiService
      .update_method(params, url)
      .then((response) => {
        if (response && response.status === 200) {
          toast.success("Update successfull!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem("propDetails", JSON.stringify(response.data));
          this.setState({
            property_id: response.data.id,
            user_id: response.data.user.id
          });
        }
      })
      .catch((error) => {
        toast.error("Update Failed!", { position: toast.POSITION.BOTTOM_RIGHT });
      });
  }
  render() {
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header shadow-lg" onClick={this.onToggle}>
                  <h5>Give a good description to your place</h5>
                </div>
                {this.state.showResults ? (
                  <Fragment>
                    <div className="card-body">
                      <form>
                        <div className="form-group">
                          <label>Descibe your place</label>
                          <textarea
                            key="descriptionSummery"
                            name="descriptionSummery"
                            className="form-control"
                            value={this.state.description.descriptionSummery}
                            onChange={this.handleChange}
                            rows="8"
                          ></textarea>
                        </div>
                        <h4 className="h4">
                          Want to add more info? (Optional)
                        </h4>
                        <div className="form-group">
                          <label>Your space</label>
                          <textarea
                            className="form-control"
                            key="descriptionSpace"
                            name="descriptionSpace"
                            value={this.state.description.descriptionSpace}
                            onChange={this.handleChange}
                            rows="4"
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <label>Your neighborhood</label>
                          <textarea
                            className="form-control"
                            name="descriptionNeighborhood"
                            key="descriptionNeighborhood"
                            value={
                              this.state.description.descriptionNeighborhood
                            }
                            onChange={this.handleChange}
                            rows="4"
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <label>Your availability</label>
                          <textarea
                            className="form-control"
                            name="descriptionAvailability"
                            key="descriptionAvailability"
                            value={
                              this.state.description.descriptionAvailability
                            }
                            onChange={this.handleChange}
                            rows="4"
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <label>Getting around</label>
                          <textarea
                            className="form-control"
                            name="descriptionAround"
                            key="descriptionAround"
                            value={this.state.description.descriptionAround}
                            onChange={this.handleChange}
                            rows="4"
                          ></textarea>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer">
                      <div>
                        <button
                          onClick={this.submit}
                          className="btn btn-primary"
                        >
                          {this.props != null ? "Update" : "Save"}
                        </button>
                      </div>
                    </div>
                  </Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Description;
