import React, { Component, Fragment } from "react";
import Countup from "react-countup";
import { Bar } from "react-chartjs-2";
import {
	getStatByDateRange,
	getBookingTrendByDateRange,
	getUserTrendByDateRange,
	getRetentionRate
} from "../../../services/dashboar.service";
import {barChartOptions} from '../../../charts-data/default';
import moment from "moment";
class HomeComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			todaysStats: {
				userCount: 0,
				bookingCount: 0,
				payingUserCount: 0,
				propertyCount: 0,
				hostCount: 0,
			},
			chartOptions: {},
			userTrend: {},
			bookingTrend: {},
			retentionRate: {
				retentionRate: 0,
				startCount: 0,
				endCount: 0,
				difference: 0,
			},
		};
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}
	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
		this.getStats();
		this.getBookingTrend();
		this.getUserTrend();
	}
	handleClickOutside = (event) => {
		this.setState({
			open: false,
		});
	};
	handleButtonClick = () => {
		this.setState((state) => {
			return {
				open: !state.open,
			};
		});
	};



	getStats = () => {
		var endDate = moment(new Date()).format("yyyy-MM-DD");
		var startDate = moment(endDate).subtract(50000, "days").format("yyyy-MM-DD");

		getStatByDateRange(startDate,endDate).then((response) => {
			if (response.status === 200) {
				this.setState({ todaysStats: response.data });
			}
		});

		getRetentionRate(startDate, endDate).then((response) => {
			if (response.status === 200) {
				this.setState({ retentionRate: response.data });
			}
		});
	};

	getBookingTrend = () => { 
		var endDate = moment(new Date()).format("yyyy-MM-DD");
		var startDate = moment(endDate).subtract(6, "days").format("yyyy-MM-DD");
		getBookingTrendByDateRange(startDate,endDate).then((response) => {
			if (response.status === 200) {
				this.setState({ bookingTrend: response.data });
				this.setChatData(response.data);
			}
		});
	}

	getUserTrend = () => {
		var endDate = moment(new Date()).format("yyyy-MM-DD");
		var startDate = moment(endDate).subtract(6, "days").format("yyyy-MM-DD");

		getUserTrendByDateRange(startDate,endDate).then((response) => {
			if (response.status === 200) {
				this.setState({ userTrend: response.data });
				this.setUserChatData(response.data);
			}
		});
	}
	
	onChangeGetStats = (e) => { 
		const days = Number(e.target.value) - 1;
		var endDate = moment(new Date()).format("yyyy-MM-DD");
		var startDate = moment(endDate).subtract(days,'days').format("yyyy-MM-DD");
		getStatByDateRange(startDate, endDate).then((response) => {
			if (response.status === 200) {
				this.setState({ todaysStats: response.data });
			}
		});

		getRetentionRate(startDate, endDate).then((response) => {
			if (response.status === 200) {
				this.setState({ retentionRate: response.data });
			}
		});
	};

	onChangeGetBookingTrend = (e) => {
		const days = Number(e.target.value)-1;
		var endDate = moment(new Date()).format('yyyy-MM-DD');
		var startDate = moment(endDate).subtract(days, "days").format("yyyy-MM-DD");
		getBookingTrendByDateRange(startDate, endDate).then((response) => {
			if (response.status === 200) {
				this.setState({ bookingTrend: response.data });
				this.setChatData(response.data);
			}
		});
	};

	onChangeGetUserTrend = (e) => {
		const days = Number(e.target.value) - 1;
		var endDate = moment(new Date()).format("yyyy-MM-DD");
		var startDate = moment(endDate).subtract(days, "days").format("yyyy-MM-DD");

		getUserTrendByDateRange(startDate, endDate).then((response) => {
			if (response.status === 200) {
				this.setState({ userTrend: response.data });
				this.setUserChatData(response.data);
			}
		});
	};



	setChatData = (data) => { 
		var labels = data.map(item => item.date);
		var value = data.map((item) => item.count);

		var chartData = {
			labels: labels,
			datasets: [
				{
					label: "Bookings",
					data: value,
					fill: false,
					backgroundColor: "#FF9F43",
					borderColor: "#FF9F43",
				},
			],
		};

		this.setState({ bookingTrend: chartData });
	}

	setUserChatData = (data) => { 
		var labels = data.map(item => item.date);
		var value = data.map((item) => item.count);

		var chartData = {
			labels: labels,
			datasets: [
				{
					label: "Users",
					data: value,
					fill:true,
					backgroundColor: "#FF9F43",
					borderColor: "#FF9F43",
				},
			],
		};

		this.setState({ userTrend: chartData });
	}
    
	render() {
		return (
			<Fragment>
				<div className='tab-content active default' id='tab-1'>
					<div className='d-flex justify-content-end'>
						<div className='select2-drpdwn-project select-options pb-3'>
							<select
								className='form-control form-control-primary btn-square'
								name='select'
								onChange={(e) => {
									this.onChangeGetStats(e);
								}}
							>
								<option value='500000' selected>All</option>
								<option value='7'>Last 7 days</option>
								<option value='30'>Last 30 days</option>
								<option value='365'>Last 365 days</option>
							</select>
						</div>
					</div>
					<div className='row'>
						<div className='col-xl-3 col-sm-6'>
							<div className='card'>
								<div className='card-body p-3'>
									<div className='media'>
										<h5 className='mb-0'>User</h5>
									</div>
									<div className='project-widgets text-center'>
										<h1 className='font-primary counter'>
											<Countup end={this.state?.todaysStats?.userCount} />
										</h1>
									</div>
								</div>
							</div>
						</div>
						<div className='col-xl-3 col-sm-6'>
							<div className='card'>
								<div className='card-body p-3'>
									<div className='media'>
										<h5 className='mb-0'>Booking</h5>
									</div>
									<div className='project-widgets text-center'>
										<h1 className='font-primary counter'>
											<Countup end={this.state?.todaysStats?.bookingCount} />
										</h1>
									</div>
								</div>
							</div>
						</div>
						<div className='col-xl-3 col-sm-6'>
							<div className='card'>
								<div className='card-body p-3'>
									<div className='media'>
										<h5 className='mb-0'>Property</h5>
									</div>
									<div className='project-widgets text-center'>
										<h1 className='font-primary counter'>
											<Countup end={this.state?.todaysStats?.propertyCount} />
										</h1>
									</div>
								</div>
							</div>
						</div>
						<div className='col-xl-3 col-sm-6'>
							<div className='card'>
								<div className='card-body p-3'>
									<div className='media'>
										<h5 className='mb-0'>Paying User</h5>
									</div>
									<div className='project-widgets text-center'>
										<h1 className='font-primary counter'>
											{this.state?.todaysStats?.payingUserCount}
										</h1>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-xl-12 xl-100 project-col'>
							<div className='card'>
								<div className='card-header project-header'>
									<div className='d-flex justify-content-between'>
										<div>
											<h5>Bookings trend</h5>
										</div>
										<div>
											<div className='select2-drpdwn-project select-options'>
												<select
													className='form-control form-control-primary btn-square'
													name='select'
													onChange={(e) => {
														this.onChangeGetBookingTrend(e);
													}}
												>
													<option value='1'>Today</option>
													<option value='7' selected>Last 7 days
													</option>
													<option value='30'>Last 30 days</option>
													<option value='365'>Last 365 days</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className='card-body spent'>
									<div className='spent-graph'>
										<div className='d-flex' style={{ height: "300px" }}>
											<Bar
												data={this.state.bookingTrend}
												options={barChartOptions}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-xl-12 xl-100 project-col'>
							<div className='card'>
								<div className='card-header project-header'>
									<div className='d-flex justify-content-between'>
										<div>
											<h5>User trend</h5>
										</div>
										<div>
											<div className='select2-drpdwn-project select-options'>
												<select
													className='form-control form-control-primary btn-square'
													name='select'
													onChange={(e) => {
														this.onChangeGetUserTrend(e);
													}}
												>
													<option value='1'>Today</option>
													<option value='7' selected>
														Last 7 days
													</option>
													<option value='30'>Last 30 days</option>
													<option value='365'>Last 365 days</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className='card-body spent'>
									<div className='spent-graph'>
										<div className='d-flex' style={{ height: "300px" }}>
											<Bar
												data={this.state.userTrend}
												options={barChartOptions}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default HomeComponent;
