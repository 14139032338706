import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import FullPageLoader from "../common/FullPageLoader/fullpageloader";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";

class City extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      country_city_id: "",
      loading: false,
      experience_id: this.props.location.state
        ? this.props.location.state.property_id
        : null,
      user_id: this.props.location.state
        ? this.props.location.state.user_id
        : null,
      callFrom: this.props.location.state
        ? this.props.location.state.callFrom
        : null,
      hostId: this.props.location.state
        ? this.props.location.state.hostId
        : null,
    };
  }

  componentDidMount() {
    //   console.log("calling values", this.state.experience_id, this.state.user_id, this.state.hostId);
    this.getCities();
    if (this.state.callFrom === "AddExperienceForUserList") {
      this.props.location.state.experience_id = 0;
      this.state.experience_id = 0;
    }
    if (this.state.experience_id != null && this.state.experience_id !== 0) {
      this.getExperienceDetails();
    }
  }
  showLoader() {
    this.setState({ loading: true });
  }

  closeLoader() {
    this.setState({ loading: false });
  }

  getCities() {
    let _this = this;
    apiService
      .cities()
      .then((response) => {
        //this.hideLoader();
        let dataList = response.data;
        let dataPush = [];
        if (dataList.length > 0) {
          for (let key in dataList) {
            let city_id = dataList[key]["id"];
            let country_id = dataList[key]["state"]["country"]["id"];
            let city = dataList[key]["name"];
            let country = dataList[key]["state"]["country"]["name"];
            dataPush.push({
              id: city_id, // city_id + "_" + country_id,
              name: city + ", " + country,
            });
          }
        }
        _this.setState({
          cities: dataPush,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getExperienceDetails() {
    let _this = this;
    _this.showLoader();
    let url = "experience/" + this.state.experience_id;
    // console.log("Method called", _this.state.property_id);

    apiService
      .get_api(url)
      .then((response) => {
        _this.closeLoader();
        if (response && response.status === 200) {
          localStorage.setItem(
            "experienceDetails",
            JSON.stringify(response.data)
          );
          console.log("resp", response);
          //document.getElementsByName(response.data.placeType.name).select = true;
          if (response.data.city !== null) {
            this.setState({
              // place_type_id: response.data.placeType.id,
              // placeAccommodatesCount: response.data.placeAccommodatesCount,

              country_city_id: response.data.city.id, // + "_" + response.data.city.name,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  resetForm = () => {
    document.getElementById("locationForm").reset();
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    let _this = this;
    params["cityId"] = this.state.country_city_id;
    if (this.state.callFrom === "AddExperienceForUserList") {
      let url = "experience";
      params["userId"] = this.state.user_id;

      console.log("param", params);
      apiService
        .create_method(params, url)
        .then((response) => {
          console.log("response", response);
          if (response && response.status === 201) {
            toast.success("Created Successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            localStorage.setItem(
              "experienceDetails",
              JSON.stringify(response.data)
            );
            this.setState({
              experience_id: response.data.id,
              redirect: "/experiencemanagement/ideatheme",
              user_id: response.data.host.id,
            });
            // console.log(response);
          } 
          else {
            toast.error(response.error_description, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((error) => {
          // loginState.closeLoader();
          toast.error("Exception Occured!!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // console.log(error);
        });
    } 
    else {
      let experience_id = this.state.experience_id;
      let url = "experience/" + experience_id;
      params["userId"] = this.state.hostId;
      console.log("param", params);
      apiService
        .update_method(params, url)
        .then((response) => {
          console.log("response", response);
          if (response && response.status === 200) {
            toast.success("Updated Successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            localStorage.setItem(
              "experienceDetails",
              JSON.stringify(response.data)
            );
            this.setState({
              experience_id: response.data.id,
              redirect: "/experiencemanagement/ideatheme",
              user_id: response.data.host.id,
            });
            // console.log(response);
          } else {
            toast.error(response.error_description, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((error) => {
          // loginState.closeLoader();
          toast.error("Exception Occured!!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // console.log(error);
        });
    }
  };
  onChangeCity = (e) => {
    this.setState({
      country_city_id: e.target.value,
    });
  };
  render() {
    const { cities } = this.state;
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              experience_id: this.state.experience_id,
              user_id: this.state.user_id,
              hostId: this.state.hostId,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Experience Management / Location" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Location</h5>
                </div>
                <div className="card-body">
                  <form className="form theme-form" id="locationForm">
                    <div className="form-group row">
                      <label
                        htmlFor="country_city_id"
                        className="col-sm-3 col-form-label"
                      >
                        Which city will you host your experience in?
                      </label>
                      <div className="col-sm-9">
                        <select
                          value={this.state.country_city_id}
                          className="form-control digits"
                          id="country_city_id"
                          //   onChange={() => {
                          //     this.setState({
                          //       country_city_id: this.value,
                          //     });
                          //   }}
                          onChange={this.onChangeCity}
                        >
                          <option value="">Please select location</option>
                          {cities.length > 0 &&
                            cities.map((item, key) => {
                              return (
                                <option key={key} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="col-sm-9 offset-sm-3">
                    <input
                      className="btn btn-light"
                      type="reset"
                      defaultValue="Cancel"
                      onClick={this.resetForm}
                    />
                    &nbsp;
                    <button
                      type="submit"
                      className="btn btn-primary mr-1"
                      onClick={this.handleSubmit}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {this.state.loading ? (
            <FullPageLoader loading={this.state.loading} />
          ) : null}
        </div>
      </Fragment>
    );
  }
}
export default City;
