import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import apiService from "../../apiservices/apiservice";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";

const testArray = [];
var currentState = "";
class LengthOfStay extends Component {
  state = {
    visible: false,
    weekendList: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    checked: [],
    weeklyPrice: "",
    weekends: [],
    monthlyPrice: [],
    userName: "",
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
  };

  componentDidMount() {
    currentState = this;
    console.log("propid", this.state.property_id);
    if (this.state.property_id !== undefined) {
      this.getEditData();
    }
  }
  getEditData() {
    // let _this = this;
    var resp = JSON.parse(localStorage.getItem("propDetails"));
    console.log("resp", resp);
    if (resp) {
      if (resp["weeklyPrice"]) {
        this.setState({
          weeklyPrice: resp["weeklyPrice"],
        });
      }
      if (resp["monthlyPrice"]) {
        this.setState({
          monthlyPrice: resp["monthlyPrice"],
        });
      }
      if (resp["weekends"]) {
        let weekends = resp["weekends"].split(",");
        this.setState({
          weekends: weekends,
        });
      }
      if (resp["user"]) {
        let fullName =
          resp["user"]["firstName"] + " " + resp["user"]["lastName"];
        this.setState({
          userName: fullName,
        });
      }
    }
  }
  onChangeWeeklyPrice = (e) => {
    this.setState({
      weeklyPrice: e.target.value,
    });
  };
  onChangeWeekend(id) {
    this.setState({
      weekends: id,
    });

    this.setState(() => {
      return { checked: id };
    });

    console.log("we", this.state.checked);
  }
  onChangeMonthlyPrice = (e) => {
    this.setState({
      monthlyPrice: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    // let _this = this;
    let property_id = this.state.property_id;

    let dataList = testArray;
    var vWeekends = "";
    for (var i = 0; i < dataList.length; i++) {
      if (i === 0) {
        vWeekends += dataList[i].checkedValue;
      } else {
        vWeekends += "," + dataList[i].checkedValue;
      }
    }

    let url = "properties/" + property_id + "/additional-pricing";
    params["weeklyPrice"] = this.state.weeklyPrice;
    params["weekends"] = vWeekends;
    params["monthlyPrice"] = this.state.monthlyPrice;
    console.log("param", params);
    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem("propDetails", JSON.stringify(response.data));
          this.setState({
            property_id: response.data.id,
            // redirect: "/propertiesmanagement/propertylist",
            user_id: response.data.user.id,
          });
          // console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  nextBtnClick = (e) => {
    this.setState({
      redirect: "/propertiesmanagement/propertiescompletion",
      property_id: this.state.property_id,
      user_id: this.state.user_id,
    });
  };
  handleCheckChange(e) {
    //e.preventDefault();
    let isChecked = e.target.checked;
    if (isChecked) {
      var checkValue = e.target.value;
      const obj = { checkedValue: checkValue };
      var isExist = currentState.CheckValueExist(checkValue);
      if (!isExist) {
        testArray.push(obj);
      } else {
        toast.error("Item Already Selected!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      console.log("testArray", testArray);
    } else {
      var uncheckValue = e.target.value;
      var index = currentState.findSelectedIndex(uncheckValue);
      testArray.splice(index, 1);
    }
  }
  findSelectedIndex(pTcValue) {
    if (testArray.length > 0) {
      for (var i = 0; i < testArray.length; i += 1) {
        if (testArray[i]["checkedValue"] === pTcValue) {
          return i;
        }
      }
    }
    return -1;
  }
  CheckValueExist(value) {
    var isExist = false;
    for (var i = 0; i < testArray.length; i++) {
      if (value === testArray[i].checkedValue) {
        isExist = true;
        break;
      } else {
        isExist = false;
        continue;
      }
    }
    return isExist;
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    const {
      // userName,
      weekendList,
      // weekends,
      // monthlyPrice,
      // weeklyPrice,
      // loading,
    } = this.state;
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Pricing" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Length-of-stay prices</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <label
                        htmlFor="weeklyPrice"
                        className="col-sm-3 col-form-label"
                      >
                        Weekly discount
                      </label>
                      <div className="col-sm-9 input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            $
                          </span>
                        </div>
                        <input
                          className="form-control"
                          id="weeklyPrice"
                          type="number"
                          value={this.state.weeklyPrice}
                          placeholder="5% off"
                          autoComplete="off"
                          onChange={this.onChangeWeeklyPrice}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="weekends"
                        className="col-sm-3 col-form-label"
                      >
                        Weekends
                      </label>
                      <div className="col-sm-9 btn-showcase">
                        <div className="form-group m-t-10 m-checkbox-inline mb-0 custom-radio-ml">
                          {weekendList.length > 0 &&
                            weekendList.map((item, key) => {
                              return (
                                <label key={key}>
                                  <input
                                    className="checkbox_animated"
                                    type="checkbox"
                                    key={key}
                                    id={key}
                                    // checked={weekends[key]}
                                    value={item}
                                    title={item}
                                    onChange={this.handleCheckChange}
                                  />
                                  <span className="label-text">{item} </span>
                                </label>
                              );
                            })}
                            
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="monthlyPrice"
                        className="col-sm-3 col-form-label"
                      >
                        Monthly discount
                      </label>
                      <div className="col-sm-9 input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            $
                          </span>
                        </div>
                        <input
                          className="form-control"
                          id="monthlyPrice"
                          type="number"
                          value={this.state.monthlyPrice}
                          placeholder="5% off"
                          autoComplete="off"
                          onChange={this.onChangeMonthlyPrice}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Fragment>
    );
  }
}

export default LengthOfStay;
