import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import FullPageLoader from "../common/FullPageLoader/fullpageloader";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { Collapse, Button, CardBody, Card } from "reactstrap";

class Language extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language_list: [],
      language_id: "",
      language_name: "",
      loading: false,
      collapse: true,
      experience_id: this.props.location.state
        ? this.props.location.state.experience_id
        : null,
      user_id: this.props.location.state
        ? this.props.location.state.user_id
        : null,
      callFrom: this.props.location.state
        ? this.props.location.state.callFrom
        : null,
      hostId: this.props.location.state
        ? this.props.location.state.hostId
        : null,
    };
  }

  componentDidMount() {
    //   console.log("calling values", this.state.experience_id, this.state.user_id, this.state.hostId);
    this.getLanguageList();
    if (this.state.experience_id !== null) {
      this.getEditData();
    }
  }
  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("experienceDetails"));
    console.log("resp", resp);
    if (resp) {
      if (resp["language"]) {
          this.setState({
              language_id:resp["language"]["id"]
          });
        // this.onChangeLanguage(resp["language"]["id"]);
      }
    }
  }
  showLoader() {
    this.setState({ loading: true });
  }

  closeLoader() {
    this.setState({ loading: false });
  }
  getLanguageList() {
    let _this = this;
    let url = "language";
    apiService
      .get_api(url)
      .then((response) => {
        //this.hideLoader();
        let dataList = response.data;
        let dataPush = [];
        if (dataList.length > 0) {
          for (let key in dataList) {
            let language_id = dataList[key]["id"];
            let language_name = dataList[key]["name"];
            dataPush.push({
              id: language_id,
              name: language_name,
            });
          }
        }
        _this.setState({
          language_list: dataPush,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onChangeLanguage = (e) => {
    this.setState({
      language_id: e.target.value,
    });
  };
  toggle = (e) => {
    this.state.collapse
      ? this.setState({
          collapse: false,
        })
      : this.setState({
          collapse: true,
        });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    let _this = this;
    let experience_id = this.state.experience_id;

    let url = "experience/"+experience_id+"/language/";
    params["languageId"] = this.state.language_id;

    console.log("param", params);
    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem(
            "experienceDetails",
            JSON.stringify(response.data)
          );
          this.setState({
            experience_id: response.data.id,
            // redirect: "/experiencemanagement/ideatheme",
            user_id: response.data.host.id,
          });
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  nextBtnClick = (e) => {
    this.setState({
      experience_id: this.state.experience_id,
      redirect: "/experiencemanagement/skills",
      user_id: this.state.user_id,
    });
  };
  render() {
    const { language_list } = this.state;
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              experience_id: this.state.experience_id,
              user_id: this.state.user_id,
              hostId: this.state.hostId,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Experience Management / Language" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Choose your primary language</h5>
                </div>
                <div className="card-body">
                  <form className="form theme-form" id="locationForm">
                    <div className="form-group row">
                      <Button
                        color="ff6204"
                        onClick={this.toggle}
                        style={{ marginBottom: "1rem", color:"#ff6204" }}
                      >
                        Tips
                      </Button>
                      <Collapse isOpen={this.state.collapse ? true : false}>
                        <Card className="mb-0">
                          <CardBody>
                            <p><i className="fa fa-angle-double-right"></i>
                              You should be able to read, write and speak in
                              your primary language
                            </p>
                            <p><i className="fa fa-angle-double-right"></i>
                              If you speak more languages, you can always add
                              them to your experience page in the future
                            </p>
                          </CardBody>
                        </Card>
                      </Collapse>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="country_city_id"
                        className="col-sm-3 col-form-label"
                      >
                        Primary language
                      </label>
                      <div className="col-sm-9">
                        <select
                          value={this.state.language_id}
                          className="form-control digits"
                          id="country_city_id"
                          //   onChange={() => {
                          //     this.setState({
                          //       country_city_id: this.value,
                          //     });
                          //   }}
                          onChange={this.onChangeLanguage}
                        >
                          <option value="">Select a primary language</option>
                          {language_list.length > 0 &&
                            language_list.map((item, key) => {
                              return (
                                <option key={key} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-warning mr-1"
                        // onClick={() => {
                        //   this.setState({
                        //     redirect: "/propertiesmanagement/addproperties",
                        //   });
                        // }}
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        onClick={this.handleSubmit}
                      >
                        Update
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={this.nextBtnClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {this.state.loading ? (
            <FullPageLoader loading={this.state.loading} />
          ) : null}
        </div>
      </Fragment>
    );
  }
}
export default Language;
