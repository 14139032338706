import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import apiService from "../../apiservices/apiservice";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

class Bathroom extends Component {
  state = {
    bathroomCount: 0,
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
  };
  componentDidMount() {
    this.getEditData();
  }
  getEditData() {
    var propDetails = JSON.parse(localStorage.getItem("propDetails"));

    if (propDetails["bathroomCount"]) {
      this.setState({
        bathroomCount: Number(propDetails["bathroomCount"]),
      });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    let _this = this;
    let property_id = this.state.property_id;
    let url = "properties/" + property_id + "/bathrooms";
    params["bathroomCount"] = this.state.bathroomCount;
    
    console.log("new prop id", this.state.property_id);
    apiService
        .updateBathroom(params, url)
        .then((response) => {
          console.log("response", response);
          if (response && response.status === 200) {
            toast.success("Updated Successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            localStorage.setItem("propDetails",JSON.stringify(response.data));
            this.setState({
              property_id:response.data.id,
              // redirect: "/propertiesmanagement/locationsetup",
              user_id:response.data.user.id
            });
            // console.log(response);
          } else {
            toast.error(response.error_description, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((error) => {
          // loginState.closeLoader();
          toast.error("Exception Occured!!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // console.log(error);
        });
    
  };
  nextBtnClick = (e) => {
    this.setState({
      redirect: "/propertiesmanagement/locationsetup",
      property_id:this.state.property_id,
      user_id: this.state.user_id
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/bedroom-design",
      user_id: this.state.user_id
    });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom : this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Bath Rooms" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>How many bathrooms?</h5>
                  <p>
                    You can consider a bathroom as half if it doesn't have
                    shower or bathtub.
                  </p>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Bathrooms
                      </label>
                      <div className="col-sm-9">
                        <fieldset className="qty-box">
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-primary mr-0"
                                data-type="plus"
                                data-field=""
                                onClick={(e) =>
                                  this.setState({
                                    bathroomCount: this.state.bathroomCount - 0.5,
                                  })
                                }
                              >
                                <i className="fa fa-minus-square"></i>
                              </button>
                            </span>
                            <input
                              type="text"
                              name="quantity"
                              value={this.state.bathroomCount}
                              readOnly={true}
                              className="form-control input-number text-center"
                            />
                            <span className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-primary mr-1"
                                data-type="plus"
                                data-field=""
                                onClick={(e) =>
                                  this.setState({
                                    bathroomCount: this.state.bathroomCount + 0.5,
                                  })
                                }
                              >
                                <i className="fa fa-plus-square"></i>
                              </button>
                            </span>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Fragment>
    );
  }
}

export default Bathroom;
