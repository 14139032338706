import React, { Component } from "react";


class Bedrooms extends Component {
    
    state = {
        bedList: [],
        bedCount: 0,
    }

    render(){
        return (
            <div className="container-fluid">
                <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        In total how many beds are there?
                      </label>
                      <div className="col-sm-9">
                        <fieldset className="qty-box">
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-primary mr-0"
                                data-type="plus"
                                data-field=""
                                onClick={(e) =>
                                  this.setState({
                                    bedCount: this.state.bedCount - 1,
                                  })
                                }
                              >
                                <i className="fa fa-minus-square"></i>
                              </button>
                            </span>
                            <input
                              type="text"
                              name="quantity"
                              value={this.state.bedCount}
                              readOnly={true}
                              className="form-control input-number text-center"
                            />
                            <span className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-primary mr-1"
                                data-type="plus"
                                data-field=""
                                onClick={(e) =>
                                  this.setState({
                                    bedCount: this.state.bedCount + 1,
                                  })
                                }
                              >
                                <i className="fa fa-plus-square"></i>
                              </button>
                            </span>
                          </div>
                        </fieldset>
                      </div>
                    </div>
            </div>
        );
    }
    
};

export default Bedrooms;
