import React, { Component, Fragment } from "react";
import { $managementAxios } from "../../lib/unility/http-service";
import Breadcrumb from "../common/breadcrumb";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Redirect } from "react-router-dom/cjs/react-router-dom";


class PropertiesBookingReqList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: [
				{
					name: "Id",
					selector: (row) => row.id,
					maxWidth: "10px",
					id: "id",
					sortable: true,
				},
				{
					name: "Host",
					selector: (row) => row.hostAccount?.firstName || "",
					sortable: true,
				},
				{
					name: "Guest",
					selector: (row) => row.guestAccount?.firstName || "",
					sortable: true,
				},
				{
					name: "Phone",
					selector: (row) => row.guestAccount?.phoneNumber || "",
					sortable: true,
				},
				{
					name: "Title",
					selector: (row) => row.property?.name || "",
					sortable: true,
				},
				{
					name: "Total",
					selector: (row) => row.feesTotal ?? "",
					sortable: true,
				},
				{
					name: "Status",
					selector: (row) => row.bookingStatus ?? "",
					sortable: false,
				},
				{
					name: "Booking Date",
					selector: (row) => row.bookingDate,
					sortable: true,
				},
				{
					name: "CheckIn Date",
					selector: (row) => row.checkInDate,
					format: (row, index) => row.checkInDate,
					sortable: true,
				},
				{
					name: "CheckOut Date",
					selector: (row) => row.checkOutDate,
					format: (row, index) => row.checkOutDate,
					sortable: true,
				},
				{
					name: "Action",
					cell: (row) => (
						<div className='d-flex flex-row justify-content-center'>
							<div>
								<i
									className='btn icofont icofont-eye p-2'
									onClick={() => {
										this.viewBookingDetails(row);
									}}
								></i>
							</div>
						</div>
					),
					button: true,
				},
			],
			loading: false,
			data: {},
			parameters: {
				page: 1,
				pageSize: 20,
				bookingConfirmation: "PENDING",
				bookingCode: "",
				sortBy: "id:desc",
			},
			bookingConfirmation: "PENDING",
			bookingCode: "",
			selectedId: null,
			breadcrumb: [{ name: "Reservation Management" }],
		};
	}

	showLoader() {
		this.setState({ loading: true });
	}

	closeLoader() {
		this.setState({ loading: false });
	}

	
	componentDidMount() {
		const storedParameters = JSON.parse(localStorage.getItem("bookingParameters"));
		// console.log("storedParameters",storedParameters);
		if(storedParameters){
			this.updateParameters(storedParameters)
		}
		setTimeout(()=>{
			
			this.getData(
				this.state.parameters.page,
				this.state.parameters.pageSize,
				this.state.bookingConfirmation,
				this.state.parameters.sortBy,
				this.state.bookingCode
				);
		},2000);
	
		
		
	  }

	updateParameters = (newParameters) => {
		this.setState({ parameters: newParameters }, () => {
		  localStorage.setItem("bookingParameters", JSON.stringify(newParameters));
		});
		console.log(this.state.parameters);
	  };

	getData = (pageNo, pageSize, bookingConfirmation, sortBy,bookingCode) => {
		this.setState({ loading: true });
		let url = "/booking/accommodations?pageSize=" + pageSize + "&pageNo=" + pageNo + "&sortBy=" + sortBy;
		if (bookingConfirmation) { 
			url = url + "&bookingConfirmation=" + bookingConfirmation;
		}
		if (bookingCode) {
			url = url + "&bookingCode=" + bookingCode;
		}
		$managementAxios
			.get(url)
			.then((response) => {
				response.data.content?.forEach((element) => {
					element.bookingDate = new moment(new Date(element.bookingDate)).format(
						"DD-MM-YYYY"
					);
					element.checkInDate = new moment(element.checkInDate).format(
						"DD-MM-YYYY"
					);
					element.checkOutDate = new moment(element.checkOutDate).format(
						"DD-MM-YYYY"
					);
				});
				this.setState({
					rowData: response.data.content,
					data: response.data,
					loading: false,
				});
			});
	};

	onChangePages = (page, totalRows) => {
		this.setState({ page: page });
		this.updateParameters({
			...this.state.parameters,
			page:page
		})
		  this.getData(
			page,
			this.state.parameters.pageSize,
			this.state.bookingConfirmation,
			  this.state.parameters.sortBy,
			  this.state.bookingCode
		  );
	  };
	  
	  
	  onChangePerPage = (currentRowsPerPage, currentPage) => {
		this.setState({ pageSize: currentRowsPerPage})
		this.updateParameters({
			...this.state.parameters,
			pageSize:currentRowsPerPage
		})
		this.getData(
				this.state.parameters.page,
				currentRowsPerPage,
				this.state.bookingConfirmation,
			this.state.parameters.sortBy,
			this.state.bookingCode
			);
	  };
	  

	viewBookingDetails = (data) => {
		this.setState({redirect:"/reservationmanagement/propdetails" , selectedId:data.id,
		...this.state.parameters,
		});
	  };
	  
	
	  handleStatusChange = (newStatus) => {
		// Update the bookingConfirmation parameter when the "Search" button is clicked
		this.setState({ bookingConfirmation:newStatus });
		const newParameters = {
		  ...this.state.parameters,
		 	 bookingConfirmation: newStatus,
			 page:1,
			 pageSize:15
		};
		this.updateParameters(newParameters);
	  };
	  
	  
	
	  search = () => {
		this.getData(
		  this.state.parameters.page,
		  this.state.parameters.pageSize,
		  this.state.bookingConfirmation,
			this.state.parameters.sortBy,
			this.state.bookingCode
		);
	  };
	  

	  render() {
		if (this.state.redirect) {
		  return (
			<Redirect
			  to={{
				pathname: this.state.redirect,
				state: {
				  property_id: this.state.selectedId,
				  ...this.state.parameters,
				},
			  }}
			/>
		  );
		}
		return (
			<Fragment>
				<Breadcrumb options={this.state.breadcrumb} />
				<div className='container-fluid'>
					{/* <div className='d-flex justify-content-between mb-3'>
						<div>
							<h4>Properties Booking Request List</h4>
						</div>
						<div>
							<BackButton />
						</div>
					</div> */}
					<div className='d-flex flex-row justify-content-end my-2'>
						<div className='pr-3'>
							<input
								type='text'
								className='form-control pr-2'
								placeholder='Booking Code'
								value={this.state.bookingCode}
								onChange={(e) => {
									this.setState({ bookingCode: e.target.value });
								}}
							/>
						</div>
						<div className='pr-3'>
							<select
								className='form-control'
								id='bookingConfirmationDropdown'
								defaultValue={this.state.bookingConfirmation}
								onChange={(e) => {
									this.setState({ bookingConfirmation: e.target.value });
								}}
							>
								<option value='PENDING'>PENDING</option>
								<option value='ACCEPTED'>ACCEPTED</option>
								<option value='DECLINED'>DECLINED</option>
								<option value='CONFIRMED'>CONFIRMED</option>
								<option value='CANCELLED'>CANCELLED</option>
							</select>
						</div>
						<div>
							<button
								className='btn btn-primary'
								onClick={() => this.search()} // Call the search function on button click
							>
								Search
							</button>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-12'>
							<DataTable
								keyField='id'
								columns={this.state.columns}
								data={this.state.rowData}
								pagination={true}
								paginationServer={true}
								paginationPerPage={this.state.parameters.pageSize}
								paginationTotalRows={this.state.data.totalElements}
								onChangePage={this.onChangePages}
								onChangeRowsPerPage={this.onChangePerPage}
								progressPending={this.state.loading}
								paginationDefaultPage={this.state.parameters.page}
								dense
							/>
						</div>
					</div>
				</div>
			</Fragment>
		);
	  }
}

export default PropertiesBookingReqList;

