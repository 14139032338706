import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
import {  Redirect } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";

class GuestBring extends Component {
  state = {
    houseRuleAdditionals: [],
    guestbringitemlist: [],
    experience_id: this.props.location.state
      ? this.props.location.state.experience_id
      : null,
    user_id: this.props.location.state
      ? this.props.location.state.user_id
      : null,
    callFrom: this.props.location.state
      ? this.props.location.state.callFrom
      : null,
    hostId: this.props.location.state ? this.props.location.state.hostId : null,
  };

  componentDidMount() {
    if (this.state.experience_id !== null) {
        this.getEditData();
      }
  }
  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("experienceDetails"));
    console.log("resp", resp);
    if (resp["guestBring"] !== null) {
      let savedItems = resp["guestBring"];
      let splitedItems = savedItems.split(',');
      let dataPush = [];
    if (splitedItems.length > 0) {
      for (let key in splitedItems) {
        dataPush.push({
          ruleName: splitedItems[key],
        });
      }
    }
    this.setState({
        guestbringitemlist: dataPush,
      });
      console.log("savedD", this.state.guestbringitemlist);
    }
    
  }

  setAdditional = (selectedOptions) => {
    console.log("label", selectedOptions);
    var typeHeadList2 = selectedOptions;

    let dataPush = [];
    if (typeHeadList2.length > 0) {
      for (let key in typeHeadList2) {
        dataPush.push({
          ruleName: typeHeadList2[key]["label"],
        });
      }
    }
    this.setState({
      guestbringitemlist: dataPush,
    });

    console.log("add", this.state.houseRuleAdditionals2);
  };
  nextBtnClick = (e) => {
    this.setState({
      experience_id: this.state.experience_id,
      redirect: "/experiencemanagement/experiencetitle",
      user_id: this.state.user_id,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    let _this = this;
    let experience_id = this.state.experience_id;

    let url = "experience/" + experience_id + "/guest-bring";
    let enteredItem = this.state.guestbringitemlist;
    let itemstring = "";
    for (var i = 0; i < enteredItem.length; i++) {
      if (i === 0) {
        itemstring += enteredItem[i].ruleName;
      } else {
        itemstring += "," + enteredItem[i].ruleName;
      }
    }
    params["guestBringItems"] = itemstring;

    console.log("param", params);
    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem(
            "experienceDetails",
            JSON.stringify(response.data)
          );
          this.setState({
            experience_id: response.data.id,
            // redirect: "/experiencemanagement/ideatheme",
            user_id: response.data.host.id,
          });
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              experience_id: this.state.experience_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    const guestbringitemlist = this.state.guestbringitemlist;
    return (
      <Fragment>
        <Breadcrumb parent="Experience Management / Guest Bring" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>What should guest bring with them?</h5>
                </div>
                <div className="card-body">
                  <form className="form theme-form" id="guestbringForm">
                  <div className="form-group row">
                      <label className="col-sm-6 col-form-label">
                        Saved Items
                      </label>
                      <div className="col-sm-12">
                        <div id="bloodhound">
                          <div className="list-group">
                            {guestbringitemlist.length > 0 &&
                              guestbringitemlist.map((item, key) => {
                                return (
                                  <li
                                    className="list-group-item list-group-item-action active"
                                    key={key}
                                  >
                                    {item.ruleName}
                                  </li>
                                );
                              })}
                          </div>
                        </div>
                        {/* {additional} */}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-6 col-form-label">
                        Write down the item what guest should bring and select
                      </label>
                      <div className="col-sm-12">
                        <div id="bloodhound">
                          <div className="form-group">
                            <Typeahead
                              id="custom-typeahead"
                              allowNew
                              multiple
                              newSelectionPrefix="Add a new item: "
                              options={this.state.houseRuleAdditionals}
                              placeholder="Type anything..."
                              onChange={this.setAdditional}
                            />
                          </div>
                        </div>
                        {/* {additional} */}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-warning mr-1"
                        // onClick={() => {
                        //   this.setState({
                        //     redirect: "/propertiesmanagement/addproperties",
                        //   });
                        // }}
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        onClick={this.handleSubmit}
                      >
                        Update
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={this.nextBtnClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default GuestBring;
