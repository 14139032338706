import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import apiService from "../../apiservices/apiservice";
import "jspdf-autotable";
import { Redirect } from "react-router-dom";
import { Card } from "antd";
import moment from "moment";
import man from "../messaging-management/user.png";
import BackButton from "../common/BackButton/BackButton";
class MessagingDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			message_id: this.props.location.state
				? this.props.location.state.message_id
				: null,
			userId: "",
			SenderPhoto: "",
			ReceiverMsg: [],
			SenderMsg: [],
			url: "../user.png",
			host: "",
			guest: "",
			msgDetails: [],
		};
	}
    componentDidMount() {
        this.setState({
            loading: true,
        });
		if (this.state.message_id > 0) {
			this.getDetails();
		}
	}

	getDetails() {
		let _this = this;
		let url = "message/" + this.state.message_id;

		apiService.get_msg_by_id(url).then((resp) => {
            if (resp && resp.status === 200) {
                var _messages = resp.data.messages.filter((x) => x.type==="USER");
				_this.setState({
					msgDetails: _messages,
				});

				_this.setState({
					host: resp.data.room.receiver,
					guest: resp.data.room.sender,
				});
            }

            this.setState({
                loading: false,
            });
		});
	}

	render() {
		if (this.state.redirect) {
			return (
				<Redirect
					to={{
						pathname: this.state.redirect,
					}}
				/>
			);
		}
		return (
			<Fragment>
				 <div className='d-flex flex-column'>
   					 	<div><Breadcrumb options={this.state.breadcurumbOptions} /></div>
      					<div className='mb-2 ml-auto mr-4'>
        					<BackButton to="/messagingmanagement/propertiesmessaginglist" />
    					</div>
  				</div>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-sm-2'></div>
						<div className='col-sm-8'>
							<div className='card'>
								<div className='card-header' style={{ textAlign: "center" }}>
									<div className='row'>
										<div className='col-sm-12'>
											<h6>
												Guest:{" "}
												<b>
													{this.state?.guest?.firstName +
														" " +
														this.state?.guest?.lastName}
												</b>
												&nbsp;&nbsp; Host:{" "}
												<b>
													{this.state?.host?.firstName +
														" " +
														this.state?.host?.lastName}
												</b>{" "}
											</h6>
										</div>
									</div>
								</div>
								<div className='card-body'>
									{this.state.msgDetails.length > 0 &&
										this.state.msgDetails.map((msg, key) => {
											return msg.sender.id === this.state.guest.id ? (
												<div>
													<div
														className='row'
														style={{
															position: "relative",
															display: "flex",
															flexDirection: "column",
															userSelect: "text",
															alignItems: "flex-start",
														}}
														key={key}
													>
														<img
															src={man}
															style={{ height: 60, width: 60 }}
															alt=''
														/>
														<Card loading={this.state.loading}
															size='small'
															style={{
																borderRadius: 7.5,
																position: "relative",
																flex: "none",
																marginLeft: 80,
																marginTop: -40,
															}}
														>
															<span>{msg.message}</span>
														</Card>
													</div>
													<div
														style={{
															fontSize: 10,
															marginLeft: 65,
															marginBottom: 30,
														}}
													>
														<span>
															{moment(msg.createdAt).format(
																"DD-MM-YYYY h:mm:ss a"
															)}
														</span>
													</div>
												</div>
											) : (
												<div style={{ marginTop: 20 }}>
													<div
														className='row'
														style={{
															position: "relative",
															display: "flex",
															flexDirection: "column",
															userSelect: "text",
															alignItems: "flex-end",
														}}
														key={key}
													>
														<img
															src={man}
															style={{ height: 60, width: 60 }}
															alt=''
														/>
                                                            <Card
                                                                loading={this.state.loading}
															size='small'
															style={{
																borderRadius: 7.5,
																position: "relative",
																flex: "none",
																marginRight: 80,
																marginTop: -40,
															}}
														>
															<span>{msg.message}</span>
														</Card>
													</div>
													<div
														style={{
															position: "relative",
															display: "flex",
															flexDirection: "column",
															alignItems: "flex-end",
															textAlign: "right",
															marginRight: 65,
															fontSize: 10,
														}}
													>
														<span>
															{moment(msg.createdAt).format(
																"DD-MM-YYYY h:mm:ss a"
															)}
														</span>
													</div>
												</div>
											);
										})}
								</div>
							</div>
						</div>
						<div className='col-sm-2'></div>
					</div>
				</div>
			</Fragment>
		);
	}
}
export default MessagingDetails;
