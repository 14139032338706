import React, { Component, Fragment } from "react";
// import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
// import FullPageLoader from "../common/FullPageLoader/fullpageloader";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { $axios } from "../../apiservices/apiservice";

class ExperiencePhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PhotoObject: [],
      PhotoObject2: [],
      isOpen: false,
      photoIndex: 0,
      id: 0,
      id2: 0,
      isOpen2: false,
      photoIndex2: 0,
      experience_id: this.props.location.state
        ? this.props.location.state.experience_id
        : null,
      user_id: this.props.location.state
        ? this.props.location.state.user_id
        : null,
      callFrom: this.props.location.state
        ? this.props.location.state.callFrom
        : null,
      hostId: this.props.location.state
        ? this.props.location.state.hostId
        : null,
    };
    this.onDrop = this.onDrop.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.onDrop2 = this.onDrop2.bind(this);
    this.removeImage2 = this.removeImage2.bind(this);
  }

  componentDidMount() {
    let experience_id = this.state.experience_id;
    let url = "experience_photo/experience/" + experience_id;
    $axios
      .get(url)
      .then((response) => {
        this.setState({
          PhotoObject: response.data,
        });
      })
      .catch((error) => { });
  }

  onDrop = (e) => {
    var SelectedFiles = e.target.files;
    let imageObject = [];
    for (var i = 0; i < SelectedFiles.length; i++) {
      var obj = {
        id: null,
        photo: SelectedFiles[i],
        captions: "",
        photoPath: URL.createObjectURL(SelectedFiles[i]),
      };
      imageObject.push(obj);
    }
    this.setState({
      PhotoObject: this.state.PhotoObject.concat(imageObject),
    });

    console.log("PhotoObj", this.state.PhotoObject);
  };

  onDrop2 = (e) => {
    var SelectedFiles = e.target.files;
    let imageObject = [];
    for (var i = 0; i < SelectedFiles.length; i++) {
      var obj = {
        id: null,
        photo: SelectedFiles[i],
        captions: "",
        photoPath: URL.createObjectURL(SelectedFiles[i]),
      };
      imageObject.push(obj);
    }
    this.setState({
      PhotoObject2: this.state.PhotoObject2.concat(imageObject),
    });

    console.log("PhotoObj2", this.state.PhotoObject2);
  };

  removeImage = async (index, id) => {
    console.log("id", id);
    if (id > 0) {
      // let res = await this.imgDelte(id);
      let url = "experience_photo/" + id;
      let res = await $axios.delete(url);
      if (res) {
        this.setState({
          PhotoObject: this.state.PhotoObject.filter((el) => el.id !== id),
        });
        toast.warn("Image deleted!");
      }
    } else {
      this.setState({
        PhotoObject: this.state.PhotoObject.filter(
          (el) => el.photoPath !== index
        ),
      });
      toast.warn("Image removed!");
    }
  };
  removeImage2 = async (index, id) => {
    console.log("id", id);
    if (id > 0) {
      let url = "experience_photo/" + id;
      let res = await $axios.delete(url);
      if (res) {
        this.setState({
          PhotoObject2: this.state.PhotoObject2.filter((el) => el.id !== id),
        });
        toast.warn("Image deleted!");
      }
    } else {
      this.setState({
        PhotoObject2: this.state.PhotoObject2.filter(
          (el) => el.photoPath !== index
        ),
      });
      toast.warn("Image removed!");
    }
  };
  handaleChanges = (index, e) => {
    var caption = e.target.value;
    let images = this.state.PhotoObject;
    images[index].captions = caption;
    this.setState({
      PhotoObject: images,
    });
  };

  handleUpload = async (e) => {
    e.preventDefault();
    for (var i = 0; i < this.state.PhotoObject.length; i++) {
      try {
        let uploaded = await this.imageUpload(this.state.PhotoObject[i], i, 1);
        console.log(uploaded);
      } catch (err) {
        toast.error("Failed to save image");
      }
    }
    for (var j = 0; j < this.state.PhotoObject2.length; j++) {
      try {
        let uploaded = await this.imageUpload(this.state.PhotoObject2[j], j, 2);
        console.log(uploaded);
      } catch (err) {
        toast.error("Failed to save image");
      }
    }
    toast.success("Success!");
  };

  imageUpload = async (data, i, callfrom) => {
    let experience_id = this.state.experience_id;
    let formData = new FormData();
    if (!data.photo) {
      await fetch(data.photoPath, { mode: "no-cors" })
        .then((res) => res.blob())
        .then((blob) => {
          var file = new File([blob], "Image");
          formData.append("files", file);
        });
    } else {
      formData.append("files", data.photo);
    }
    if (callfrom === 1) {
      formData.append("isCover", i === 0 ? true : false);
      formData.append("id", this.state.id);
      formData.append("captions", "Cover Photo")
    }
    else {
      formData.append("isCover", false);
      formData.append("id", this.state.id2);
      formData.append("captions", "Gallery Photo")
    }

    formData.append("experienceId", experience_id);

    if (data.id > 0) {

      let url = "experience_phot/" + data.id + "/experience/" + experience_id;
      return await $axios.put(url, formData);
    }
    else {
      let url = "experience_photo/experience/" + experience_id;
      return await $axios.post(url, formData);

    }
  };
  nextBtnClick = (e) => {
    this.setState({
      experience_id: this.state.experience_id,
      redirect: "/experiencemanagement/meetinglocation",
      user_id: this.state.user_id,
    });
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              experience_id: this.state.experience_id,
              user_id: this.state.user_id,
              hostId: this.state.hostId,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }

    return (
      <Fragment>
        <Breadcrumb parent="Experience Management / Photos" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Add photos to your experience</h5>
                </div>
                <div className="card-body">
                  <div>
                    <div className="form-group row">
                      <div className="col-sm-4 imageContainer mt-4 mb-5">
                        <input
                          type="file"
                          className="btnU"
                          id="upload"
                          hidden
                          onChange={this.onDrop}
                        />
                        <label
                          className="btn btn-primary p-2 pr-4 pl-4 btnU"
                          htmlFor="upload"
                        >
                          <i className="fa fa-upload" aria-hidden="true"></i>
                          <br></br>
                          Cover Photo
                        </label>
                      </div>
                      <div className="col-sm-8 my-gallery gallery-with-description">
                        {this.state.PhotoObject.map((obj, index) => {
                          return (
                            <figure
                              className="col-xl-4 col-sm-6 imageContainer"
                              key={index}
                            >
                              <div className="">
                                <button
                                  onClick={() =>
                                    this.removeImage(obj.photoPath, obj.id)
                                  }
                                  className="btnx"
                                >
                                  <i className="icofont icofont-trash "></i>
                                </button>
                              </div>
                              <img
                                src={obj.photoPath}
                                alt="Gallery"
                                className="img-thumbnail"
                                onClick={() =>
                                  this.setState({
                                    photoIndex: index,
                                    isOpen: true,
                                  })
                                }
                              />
                              {/* <div className="caption">
                            <textarea
                              className="form-control"
                              key={index.toString()}
                              value={obj.captions}
                              id={index}
                              onChange={(e) => {
                                this.handaleChanges(index, e);
                              }}
                            >
                              {obj.captions}
                            </textarea>
                          </div> */}
                            </figure>
                          );
                        })}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-4 imageContainer mt-4 mb-5">
                        <input
                          type="file"
                          className="btnU"
                          id="upload2"
                          hidden
                          multiple
                          onChange={this.onDrop2}
                        />
                        <label
                          className="btn btn-primary p-2 pr-4 pl-4 btnU"
                          htmlFor="upload2"
                        >
                          <i className="fa fa-upload" aria-hidden="true"></i>
                          <br></br>
                          Gallery Photos
                        </label>
                      </div>
                      <div className="col-sm-8 my-gallery gallery-with-description">
                        {this.state.PhotoObject2.map((obj, index) => {
                          return (
                            <figure
                              className="col-xl-4 col-sm-6 imageContainer"
                              key={index}
                            >
                              <div>
                                <button
                                  onClick={() =>
                                    this.removeImage2(obj.photoPath, obj.id)
                                  }
                                  className="btnx"
                                >
                                  <i className="icofont icofont-trash "></i>
                                </button>
                              </div>
                              <img
                                src={obj.photoPath}
                                alt="Gallery"
                                className="img-thumbnail"
                                onClick={() =>
                                  this.setState({
                                    photoIndex2: index,
                                    isOpen2: true,
                                  })
                                }
                              />
                            </figure>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {/* <div className=""></div> */}
                  {/* <div className="my-gallery card-body row gallery-with-description"></div> */}
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-warning mr-1"
                      // onClick={() => {
                      //   this.setState({
                      //     redirect: "/propertiesmanagement/addproperties",
                      //   });
                      // }}
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        onClick={this.handleUpload}
                      >
                        Update
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={this.nextBtnClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default ExperiencePhotos;
