import React, { Fragment } from 'react';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'
import Bookmark from './bookmark';

const Breadcrumb = props => {
    const breadcrumb = props;
	const options = breadcrumb.options;
    return (
			<Fragment>
				<div className='container-fluid'>
					<div className='page-header pt-0'>
						<div className='row px-1 mx-1 py-3 shadow rounded'>
							<div className='col'>
								<div className='page-header-left '>
									<h5 className=''>{breadcrumb.title}</h5>
									<ol className='breadcrumb pull-right ml-0'>
										<li className='breadcrumb-item'>
											<Link to='/dashboard/default'>
												<Home />
											</Link>
											<span className='pl-2'>/</span>
										</li>
										{/* <li className='breadcrumb-item'>{breadcrumb.parent}</li> */}
										{options &&
											options.length > 0 &&
											options.map((item, i) => {
												return (
													<div>
														{item.link ? (
															<li className='breadcrumb-item px-2'>
																<Link to={item.link}>{item.name}</Link>
																<span className='pl-2'>/</span>
															</li>
														) : (
															<li className='breadcrumb-item px-2'>
																{item.name}
																<span className='pl-2'>/</span>
															</li>
														)}
													</div>
												);
											})}
									</ol>
								</div>
							</div>
							{/* <!-- Bookmark Start--> */}
							<Bookmark />
							{/* <!-- Bookmark Ends--> */}
						</div>
					</div>
				</div>
			</Fragment>
		);
}

export default Breadcrumb
