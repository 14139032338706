// import app from "../../src/data/base";

//Live
export const OPEN_API_BASE_URL = "https://api.hurraayy.com/api/v1/open/";
export const OPEN_API_URL = "https://api.hurraayy.com/api/v1/open/";
export const MANAGEMENT_API_BASE_URL = "https://api.hurraayy.com/api/v1/management/";
export const MANAGEMENT_API_URL = "https://api.hurraayy.com/api/v1/management/";
export const APP_API_BASE_URL = "https://api.hurraayy.com/api/v1/app/";
export const APP_API_URL = "https://api.hurraayy.com/api/v1/app/";

export const API_REQUEST_TOKEN = "basic dHJ1c3RlZC1hcHA6cGFzc3dvcmQ=";
export const LOGIN_TOKEN = "basic dHJ1c3RlZC1hcHA6cGFzc3dvcmQ=";
export const GEOCODE_URL = "https://maps.googleapis.com/maps/api/geocode/json";
export const GEOCODE_KEY = "AIzaSyCo-FxtKpVO5l1LsG_YxJnh8X_wsJ7ppiA";


// UAT
// export const OPEN_API_BASE_URL = "https://api.uat.hurraayy.com/api/v1/open/";
// export const OPEN_API_URL = "https://api.uat.hurraayy.com/api/v1/open/";
// export const MANAGEMENT_API_BASE_URL = "https://api.uat.hurraayy.com/api/v1/management/";
// export const MANAGEMENT_API_URL = "https://api.uat.hurraayy.com/api/v1/management/";
// export const APP_API_BASE_URL = "https://api.uat.hurraayy.com/api/v1/app/";
// export const APP_API_URL = "https://api.uat.hurraayy.com/api/v1/app/";

// export const API_REQUEST_TOKEN = "basic dHJ1c3RlZC1hcHA6cGFzc3dvcmQ=";
// export const LOGIN_TOKEN = "basic dHJ1c3RlZC1hcHA6cGFzc3dvcmQ=";
// export const GEOCODE_URL = "https://maps.googleapis.com/maps/api/geocode/json";
// export const GEOCODE_KEY = "AIzaSyCo-FxtKpVO5l1LsG_YxJnh8X_wsJ7ppiA";


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  OPEN_API_BASE_URL,
  API_REQUEST_TOKEN,
  MANAGEMENT_API_BASE_URL,
  APP_API_BASE_URL,
  GEOCODE_URL,
  GEOCODE_KEY,
  LOGIN_TOKEN,
  MANAGEMENT_API_URL,
  APP_API_URL,
  OPEN_API_URL
};
