import React, { useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import * as serviceWorker from "./serviceWorker";

// ** Import custom components for redux**
import { Provider } from "react-redux";
import store from "./store/index";
import App from "./components/app";
import Project from "./components/dashboard/project/project";

// User Management
import {
	AddAdminUser,
	AdminUserList,
	AddApplicationUser,
	ApplicationUserList,
	AddRole,
	RoleList,
} from "./components/user-management";

import AddRoom from "./components/managerooms/addroom";
import AddProperties from "./components/properties-management/addproperties";

import Login from "./pages/authentication/login";
import Error404 from "./pages/errors/error404";

//config data
import configDB from "./data/customizer/config";

import PlaceSetting from "./components/properties-management/placesetting";
import PlaceType from "./components/properties-management/placetype";
import BedRoom from "./components/properties-management/bedroom-design";
import Bathroom from "./components/properties-management/bathrooms";
import LocationSetup from "./components/properties-management/locationsetup";
import LocationConfirmation from "./components/properties-management/locationconfirmation";
import NamePlace from "./components/properties-management/nameplace";
import PropertyList from "./components/properties-management/propertylist";
import DescriptionMaster from "./components/properties-management/DescriptionMaster";
import GuestRequirements from "./components/properties-management/guestrequirements";
import HouseRules from "./components/properties-management/houserules";
import KeepCalendarUpdate from "./components/properties-management/keepcalendarupdate";
import AddCalendar from "./components/properties-management/addcalendar";
import AvailabilityQuestion from "./components/properties-management/availabilityquestion";
import GuestBooks from "./components/properties-management/guestbooks";
import GuestNotification from "./components/properties-management/guestnotification";
import GuestStay from "./components/properties-management/gueststay";
import CalendarPage from "./components/properties-management/calendar";
import PriceSpace from "./components/properties-management/pricespace";
import SpecialDiscount from "./components/properties-management/specialdiscount";
import LengthOfStay from "./components/properties-management/lengthofstay";
import PropertiesCompletion from "./components/properties-management/propertiescompletion";
import ExperienceList from "./components/experience-management/experiencelist";
import AddExperienceUserList from "./components/experience-management/addexperienceuserlist";
import City from "./components/experience-management/city";
import IdeaTheme from "./components/experience-management/ideatheme";
import Language from "./components/experience-management/language";
import Skills from "./components/experience-management/skills";
import AboutYou from "./components/experience-management/aboutyou";
import WillDo from "./components/experience-management/willdo";
import WillBe from "./components/experience-management/willbe";
import WillProvide from "./components/experience-management/willprovide";
import GuestBring from "./components/experience-management/guestbring";
import ExperienceTitle from "./components/experience-management/experiencetitle";
import ExperiencePhotos from "./components/experience-management/experiencephotos";
import MeetingLocation from "./components/experience-management/meetinglocation";
import Notes from "./components/experience-management/notes";
import GuestRequirements2 from "./components/experience-management/guestrequirements";
import GroupSize from "./components/experience-management/groupsize";
import Duration from "./components/experience-management/duration";
import Price from "./components/experience-management/price";
import BookingSettings from "./components/experience-management/bookingsettings";
import Review from "./components/experience-management/review";
import AdvantureList from "./components/advanture-management/advantureList";
import AdvantureUserList from "./components/advanture-management/adventureUserList";
import AdventureCity from "./components/advanture-management/adventureCity";
import AdventureTheme from "./components/advanture-management/adventureTheme";
import AdventureLanguage from "./components/advanture-management/adventureLanguage";
import AdventureSkills from "./components/advanture-management/adventureSkill";
import AdventureAboutYou from "./components/advanture-management/adventureAboutYou";
import AdventureWillDo from "./components/advanture-management/adventureWilldo";
import AdevntureWillBe from "./components/advanture-management/adventureWillBe";
import WillProvide2 from "./components/advanture-management/willprovide";
import AdventureGuestBring from "./components/advanture-management/adventureGuestBring";
import AdevntureTitle from "./components/advanture-management/adventureTitle";
import AdventurePhotos from "./components/advanture-management/adventurePhoto";
import AdventureMeet from "./components/advanture-management/adventuremeet";
import AdventureNotes from "./components/advanture-management/adventurenotes";
import AdventureGuestRequirements from "./components/advanture-management/adventureguestrequirements";
import AdventureGroupSize from "./components/advanture-management/adventuregroupsize";
import AdventureDuration from "./components/advanture-management/adventureduration";
import AdventurePrice from "./components/advanture-management/adventureprice";
import AdventureBookingSettings from "./components/advanture-management/adventurebookingsettings";
import AdventureReview from "./components/advanture-management/adventurereview";

//Reservation
import {
	AdventureReservationList,
	ExperienceReservationList,
	PropertiesReservationList,
	AdvDetails,
	ExpDetails,
	PropDetails,
	PropertiesBookingReqList,
} from "./components/reservation-management";

//Messaging
import {
	MessagingDetails,
	MessagingList,
} from "./components/messaging-management";

//Utility
import { OneTimePassword,Rating } from "./components/utility";


let loggedIn = localStorage.getItem("token");

const routes = [
	{
		path: "/dashboard",
		component: Project,
	},
	{
		path: "/adminuser/addadminuser",
		component: AddAdminUser,
	},
	{
		path: "/adminuser/adminuserlist",
		component: AdminUserList,
	},
	{
		path: "/security/addrole",
		component: AddRole,
	},
	{
		path: "/security/rolelist",
		component: RoleList,
	},
	{
		path: "/applicationuser/addapplicationuser",
		component: AddApplicationUser,
	},
	{
		path: "/applicationuser/applicationuserlist",
		component: ApplicationUserList,
	},
	{
		path: "/security/addroom",
		component: AddRoom,
	},
	{
		path: "/propertiesmanagement/addproperties",
		component: AddProperties,
	},
	{
		path: "/propertiesmanagement/placesetting",
		component: PlaceSetting,
	},
	{
		path: "/propertiesmanagement/placetype",
		component: PlaceType,
	},
	{
		path: "/propertiesmanagement/bedroom-design",
		component: BedRoom,
	},
	{
		path: "/propertiesmanagement/bathrooms",
		component: Bathroom,
	},
	{
		path: "/propertiesmanagement/locationsetup",
		component: LocationSetup,
	},
	{
		path: "/propertiesmanagement/locationconfirmation",
		component: LocationConfirmation,
	},
	{
		path: "/propertiesmanagement/nameplace",
		component: NamePlace,
	},
	{
		path: "/propertiesmanagement/propertylist",
		component: PropertyList,
	},
	{
		path: "/propertiesmanagement/DescriptionMaster",
		component: DescriptionMaster,
	},
	{
		path: "/propertiesmanagement/guestrequirements",
		component: GuestRequirements,
	},
	{
		path: "/propertiesmanagement/houserules",
		component: HouseRules,
	},
	{
		path: "/propertiesmanagement/keepcalendarupdate",
		component: KeepCalendarUpdate,
	},
	{
		path: "/propertiesmanagement/addcalendar",
		component: AddCalendar,
	},
	{
		path: "/propertiesmanagement/availabilityquestion",
		component: AvailabilityQuestion,
	},
	{
		path: "/propertiesmanagement/guestnotification",
		component: GuestNotification,
	},
	{
		path: "/propertiesmanagement/guestbooks",
		component: GuestBooks,
	},
	{
		path: "/propertiesmanagement/gueststay",
		component: GuestStay,
	},
	{
		path: "/propertiesmanagement/calendar",
		component: CalendarPage,
	},
	{
		path: "/propertiesmanagement/pricespace",
		component: PriceSpace,
	},
	{
		path: "/propertiesmanagement/specialdiscount",
		component: SpecialDiscount,
	},
	{
		path: "/propertiesmanagement/lengthofstay",
		component: LengthOfStay,
	},
	{
		path: "/propertiesmanagement/propertiescompletion",
		component: PropertiesCompletion,
	},
	{
		path: "/experiencemanagement/experiencelist",
		component: ExperienceList,
	},
	{
		path: "/experiencemanagement/addexperienceuserlist",
		component: AddExperienceUserList,
	},
	{
		path: "/experiencemanagement/city",
		component: City,
	},
	{
		path: "/experiencemanagement/ideatheme",
		component: IdeaTheme,
	},
	{
		path: "/experiencemanagement/language",
		component: Language,
	},
	{
		path: "/experiencemanagement/skills",
		component: Skills,
	},
	{
		path: "/experiencemanagement/aboutyou",
		component: AboutYou,
	},
	{
		path: "/experiencemanagement/willdo",
		component: WillDo,
	},
	{
		path: "/experiencemanagement/willbe",
		component: WillBe,
	},
	{
		path: "/experiencemanagement/willprovide",
		component: WillProvide,
	},
	{
		path: "/experiencemanagement/guestbring",
		component: GuestBring,
	},
	{
		path: "/experiencemanagement/experiencetitle",
		component: ExperienceTitle,
	},
	{
		path: "/experiencemanagement/experiencephotos",
		component: ExperiencePhotos,
	},
	{
		path: "/experiencemanagement/meetinglocation",
		component: MeetingLocation,
	},
	{
		path: "/experiencemanagement/notes",
		component: Notes,
	},
	{
		path: "/experiencemanagement/guestrequirements",
		component: GuestRequirements2,
	},
	{
		path: "/experiencemanagement/groupsize",
		component: GroupSize,
	},
	{
		path: "/experiencemanagement/duration",
		component: Duration,
	},
	{
		path: "/experiencemanagement/price",
		component: Price,
	},
	{
		path: "/experiencemanagement/bookingsettings",
		component: BookingSettings,
	},
	{
		path: "/experiencemanagement/review",
		component: Review,
	},
	{
		path: "/adventuremanagement/adventurelist",
		component: AdvantureList,
	},
	{
		path: "/adventuremanagement/adventureuserlist",
		component: AdvantureUserList,
	},
	{
		path: "/adventuremanagement/city",
		component: AdventureCity,
	},
	{
		path: "/adventuremanagement/theme",
		component: AdventureTheme,
	},
	{
		path: "/adventuremanagement/language",
		component: AdventureLanguage,
	},
	{
		path: "/adventuremanagement/skills",
		component: AdventureSkills,
	},
	{
		path: "/adventuremanagement/aboutyou",
		component: AdventureAboutYou,
	},
	{
		path: "/adventuremanagement/willdo",
		component: AdventureWillDo,
	},
	{
		path: "/adventuremanagement/willbe",
		component: AdevntureWillBe,
	},
	{
		path: "/advanturemanagement/willprovide",
		component: WillProvide2,
	},
	{
		path: "/adventuremanagement/guestbring",
		component: AdventureGuestBring,
	},
	{
		path: "/adventuremanagement/title",
		component: AdevntureTitle,
	},
	{
		path: "/adventuremanagement/photos",
		component: AdventurePhotos,
	},
	{
		path: "/advanturemanagement/adventuremeet",
		component: AdventureMeet,
	},
	{
		path: "/advanturemanagement/adventurenotes",
		component: AdventureNotes,
	},
	{
		path: "/advanturemanagement/adventureguestrequirements",
		component: AdventureGuestRequirements,
	},
	{
		path: "/advanturemanagement/adventuregroupsize",
		component: AdventureGroupSize,
	},
	{
		path: "/advanturemanagement/adventureduration",
		component: AdventureDuration,
	},
	{
		path: "/advanturemanagement/adventureprice",
		component: AdventurePrice,
	},
	{
		path: "/advanturemanagement/adventurebookingsettings",
		component: AdventureBookingSettings,
	},
	{
		path: "/advanturemanagement/adventurereview",
		component: AdventureReview,
	},
	{
		path: "/reservationmanagement/adventurereservationlist",
		component: AdventureReservationList,
	},
	{
		path: "/reservationmanagement/experiencereservationlist",
		component: ExperienceReservationList,
	},
	{
		path: "/reservationmanagement/propertiesreservationlist",
		component: PropertiesReservationList,
	},
	{
		path: "/reservationmanagement/propertiesbookingreqlist",
		component: PropertiesBookingReqList,
	},
	{
		path: "/reservationmanagement/advdetails",
		component: AdvDetails,
	},
	{
		path: "/reservationmanagement/expdetails",
		component: ExpDetails,
	},
	{
		path: "/reservationmanagement/propdetails",
		component: PropDetails,
	},
	{
		path: "/messagingmanagement/propertiesmessaginglist",
		component: MessagingList,
	},
	{
		path: "/messagingmanagement/msgdetails",
		component: MessagingDetails,
	},
	{
		path: "/utility/ganaretotp",
		component: OneTimePassword,
	},
	{
		path: "/utility/rating",
		component: Rating,
	}
];

function Root() {
	const abortController = new AbortController();

	useEffect(() => {
		const layout =
			localStorage.getItem("layout_version") ||
			configDB.data.color.layout_version;

		document.body.classList.add(layout);
		return function cleanup() {
			abortController.abort();
		};
	}, [abortController]);

	return (
		<div className='App'>
			<Provider store={store}>
				<BrowserRouter basename={`/`}>
					<ScrollContext>
						<Switch>
							<Route exact path='/'>
								{loggedIn ? <Redirect to='/dashboard' /> : <Login />}
							</Route>
							<Route
								path={`${process.env.PUBLIC_URL}/pages/authentication/login`}
								component={Login}
							/>
							<Route
								path={`${process.env.PUBLIC_URL}/pages/errors/error404`}
								component={Error404}
							/>
							<Fragment>
								<App>
									{routes.map((route, i) => (
										<RouteWithSubRoutes key={i} {...route} />
									))}
								</App>
							</Fragment>
						</Switch>
					</ScrollContext>
				</BrowserRouter>
			</Provider>
		</div>
	);
}

function RouteWithSubRoutes(route) {
	return (
		<Route
			path={route.path}
			render={(props) => (
				<route.component {...props} routes={route.routes} />
			)}
		/>
	);
}
ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
