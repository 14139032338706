import React from 'react'
export const data =[
    {
        id:"1",
        name: "Md. Habibur Rahman Jewel",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on:"2020-09-10"
    },
    {
        id:"2",
        name: "Nasif",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2020-09-10"
    },
    {
        id:"3",
        name: "Shofiul Alam",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on:"2020-09-10"
    },
    {
        id:"4",
        name: "Mahbub Hassan",
        status: <i className="fa fa-circle font-danger f-12" />,
        creat_on:"2020-09-10"
    },
    {
        id:"5",
        name: "Shahidul Islam",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on:"2020-09-10"
    },
    {
        id:"6",
        name: "Jahid",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on:"2020-09-10"
    }
]

export default data