import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
// import data from "../../data/dummyTableData";
// import Datatable from "../common/datatable";
// import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import apiService from "../../../apiservices/apiservice";
import { ToastContainer, toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import FullPageLoader from "../../../components/common/FullPageLoader/fullpageloader"

//var adminUserList = [];
var currentState = "";
class RoleList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
      rowSelection: "single",
      rowSelected: false,
      redirect:null,
      paginationPageSize: 10,
      loading:false,
      roleList: [],
      selectedRole:"",
      columnDefs: [
        {
          headerName: "Id",
          field: "id",
          filter: true,
          //checkboxSelection: true,
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Role Name",
          field: "name",
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Description",
          field: "description",
          filter: true,
          sortable: true,
          resizable: true,
        },
      ],
      rowData : null,
    };
  }


  showLoader(){
    this.setState({loading:true});
  }

  closeLoader(){
    this.setState({loading:false});
  }

  componentDidMount() {
    apiService.getRole()
        .then((response) => {
            if (response && response.status === 200) {
                toast.success(response.data.length + " Role Found", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                console.log(response);
                this.setState({ rowData: response.data });
            }
        })
        .catch((error) => {
            toast.error("Exception Occured!!", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            console.log(error);
        });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    currentState = this;
    // this.gridApi.paginationSetPageSize = 5;
  };

  getSelectedRowData = () => {
    let selectedNodes = this.gridApi.getSelectedRows();
    //console.log(selectedNodes);
    let selectedData = selectedNodes.map((node) => node.data);
    console.log(selectedData);
    toast.success(selectedNodes[0].usr, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    // alert(`Selected Nodes:\n${JSON.stringify(selectedData)}`);
    // return selectedData;
  };

  onPageSizeChanged(newPageSize){
    var value = newPageSize.target.value;
    currentState.gridApi.paginationSetPageSize(Number(value));
  };
  onSelectionChanged = () => {
    this.setState({ rowSelected: true });
    //var selectedRows = this.gridApi.getSelectedRows();
    // document.querySelector('#selectedRows').innerHTML =
    //   selectedRows.length === 1 ? selectedRows[0].usr : '';
  };

  DeleteRole = () =>{
    currentState.showLoader();
    let selectedNodes = this.gridApi.getSelectedRows();
      apiService
      .deleteRole(selectedNodes[0].id)
      .then((response) => {
        currentState.closeLoader();
          if (response && response.status === 204) {
              toast.success("Deleted Successfully", {
                  position: toast.POSITION.BOTTOM_RIGHT,
              });
              console.log(response);
          }
          console.log(response);
          this.componentDidMount();
      })
      .catch((error) => {
        currentState.closeLoader();
          toast.error("Exception Occured!!", {
              position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log(error);
      });
  }

  goToAddAdmin=()=>{
    this.setState({ redirect: "/applicationuser/addapplicationuser" });
  }
  render() {
    // if (this.state.redirect) {
    //   return <Redirect to={{
    //     pathname: this.state.redirect,
    //     state: { property_id: 1 }
    //   }} />

    //   // return <AddAdminUser cutomprop='1'/>
    // }
    return (
      <Fragment>
        <Breadcrumb parent="security / User List" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-9">
                      <h5>Role List</h5>
                    </div>
                    <div className="col-sm-1">
                      <a
                        className="btn btn-primary mr-1"
                        href="/security/addrole"
                      >
                        Add Role{" "}
                      </a>
                    </div>
                  </div>
                </div>
                <ToastContainer />

                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Page size</label>
                    <div className="col-sm-3">
                      <select
                        onChange={this.onPageSizeChanged}
                        id="page-size"
                        className="form-control digits"
                      >
                        <option value="10" defaultValue="">
                          10
                        </option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </select>
                    </div>
                    <div className="col-sm-6" id="editDiv">
                      {/* <button
                        className="btn btn-primary mr-1"
                        type="button"
                        // onClick={this.getSelectedRowData}
                        onClick={this.goToAddAdmin}
                        disabled={!this.state.rowSelected}
                      >
                        Edit
                      </button> */}
                      <button
                        className="btn btn-danger mr-1"
                        type="button"
                        onClick={this.DeleteRole}
                        disabled={!this.state.rowSelected}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  <div
                    className="ag-theme-alpine"
                    style={{ height: "500px", width: "auto" }}
                  >
                    <AgGridReact
                      pagination={true}
                      columnDefs={this.state.columnDefs}
                      rowData={this.state.rowData}
                      onGridReady={this.onGridReady}
                      rowSelection={this.state.rowSelection}
                      onSelectionChanged={this.onSelectionChanged.bind(this)}
                      paginationPageSize={this.state.paginationPageSize}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.loading ? <FullPageLoader loading={this.state.loading}/> : null}
        </div>
      </Fragment>
    );
  }
}

export default RoleList;
