import React, { Component, Fragment } from "react";
import { $managementAxios } from "../../../lib/unility/http-service";
import Breadcrumb from "../../common/breadcrumb";
import apiService from "../../../apiservices/apiservice";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { Redirect } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";

class AdminUserList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: [
				{
					name: "Id",
					selector: (row) => row.id,
					maxWidth: "20px",
				},
				{
					name: "User name",
					selector: (row) => row.usr,
				},
				{
					name: "First name",
					selector: (row) => row.firstName,
				},
				{
					name: "Last name",
					selector: (row) => row.lastName,
				},
				{
					name: "Date of registration",
					selector: (row) => row.createdAt,
					sortable: true,
					format: (row, index) =>
						new moment(row.createdAt).format("DD-MM-YYYY"),
				},
				{
					name: "Date of birth",
					selector: (row) => row.dateOfBirth,
					format: (row, index) =>
						new moment(row.dateOfBirth).format("DD-MM-YYYY"),
				},
				{
					name: "Action",
					cell: (row) => (
						<div className='d-flex flex-row justify-content-center'>
							<div>
								<i
									className='btn icofont icofont-ui-delete p-2'
									onClick={() => {
										this.DeleteApplicationUser(row);
									}}
								></i>
							</div>
							<div>
								<i
									className='btn icofont icofont-eye p-2'
									onClick={() => {
										this.viewApplicationUser(row);
									}}
								></i>
							</div>
						</div>
					),
					button: true,
				},
			],
			loading: false,
			data: {},
			page: 1,
			pageSize: 10,
			breadcrumbItems: [
				{ name: "Admin List"}
			]
		};
	}

	showLoader() {
		this.setState({ loading: true });
	}

	closeLoader() {
		this.setState({ loading: false });
	}

	componentDidMount() {
		this.getData(this.state.page, this.state.pageSize, "createdAt:desc");
	}

	getData = (pageNo, pageSize, sortBy) => {
		this.setState({ loading: true });
		$managementAxios
			.get(
				"/users?roles=5&pageSize=" +
					pageSize +
					"&pageNo=" +
					pageNo +
					"&sortBy=" +
					sortBy
			)
			.then((response) => {
				response.data.content?.forEach((element) => {
					element.dateOfBirth = new moment(element.dateOfBirth).format(
						"DD-MM-YYYY"
					);
					element.createdAt = new moment(element.createdAt).format(
						"DD-MM-YYYY"
					);
				});
				this.setState({ rowData: response.data.content, data: response.data });
				this.setState({ loading: false });
			});
	};

	DeleteApplicationUser = (user) => {
		swal({
			title: "Are you sure?",
			text: "You want to delete this user?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete)
				apiService
					.deleteUser(user.id)
					.then((response) => {
						if (response && response.status === 204) {
							toast.success("Deleted Successfully", {
								position: toast.POSITION.BOTTOM_RIGHT,
							});
						}
						this.componentDidMount();
					})
					.catch((error) => {
						toast.error("Exception Occurred!", {
							position: toast.POSITION.BOTTOM_RIGHT,
						});
					});
		});
	};

	goToAddAdmin = () => {
		this.setState({ redirect: "/adminuser/addadminuser" });
	};

	onChangePages = (page, totalRows) => {
		this.setState({ page: page });
		this.getData(page, this.state.pageSize, "createdAt:desc");
	};
	onChangePerPage = (currentRowsPerPage, currentPage) => {
		this.setState({ pageSize: currentRowsPerPage });
		this.getData(this.state.page, currentRowsPerPage, "createdAt:desc");
	};

	render() {
		if (this.state.redirect) {
			return (
				<Redirect
					to={{
						pathname: this.state.redirect
					}}
				/>
			);
		}
		return (
			<Fragment>
				<Breadcrumb parent='Security / User List' options={this.state.breadcrumbItems} />
				<div className='container-fluid'>
					<div className='d-flex justify-content-between mb-3'>
						<div>
							<h5>Admin User List</h5>
						</div>
						<div>
							<button onClick={this.goToAddAdmin} className='btn btn-primary mt-2'>
								<i className='icofont icofont-plus pr-2'></i>
								Add User
							</button>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-12'>
							<DataTable
								keyField='id'
								columns={this.state.columns}
								data={this.state.data.content}
								pagination={true}
								paginationServer={true}
								paginationTotalRows={this.state.data.totalElements}
								onChangePage={this.onChangePages}
								onChangeRowsPerPage={this.onChangePerPage}
								progressPending={this.state.loading}
							/>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default AdminUserList;
