import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

const spaceList = [];
const guestSpaceList = [
  {
    name: "Kitchen",
    val: "kitchen",
  },
  {
    name: "Laundry - washer",
    val: "laundry_washer",
  },
  {
    name: "Laundry - dryer",
    val: "laundry_dryer",
  },
  {
    name: "Parking",
    val: "parking",
  },
  {
    name: "Gym",
    val: "gym",
  },
  {
    name: "Pool",
    val: "pool",
  },
  {
    name: "Hot tub",
    val: "hot_tub",
  },
  {
    name: "Elevator",
    val: "elevator",
  },
]
class Spaces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResults: true,
      spaces: [],
      propertyId: props.property_id != null ? props.property_id : null,
    };

    //Bind Events
    this.onToggle = this.onToggle.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    // apiService
    //   .amenitiesList()
    //   .then((response) => {
    //     if (response && response.status === 200) {
    //       this.setState({
    //         spaces: response.data,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     toast.error(error.statusText, {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //     });
    //   });

    // if (this.state.propertyId) {
    //   //added by Habib
    //   // let _this = this;
    //   // var response = JSON.parse(localStorage.getItem("propDetails"));
    //   // var propertySpaceList = response.propertySpaceList;
    //   // propertySpaceList.forEach((element) => {
    //   //   var id = element.name;
    //   //   if (this.state.spaces.slice((x) => x.name === element.name)) {
    //   //     document.getElementById(id).checked = true;
    //   //     var spaceObject = {
    //   //       name: element.name,
    //   //     };
    //   //     spaceList.push(spaceObject);
    //   //   }
    //   // });
    //   apiService.Get('properties/' + this.state.propertyId)
    //     .then((response) => {
    //       if (response && response.status === 200) {
    //         var propertySpaceList = response.data.propertySpaceList;
    //         propertySpaceList.forEach(element => {
    //           var id = element.name;
    //           if (this.state.spaces.slice(x => x.name === element.name)) {
    //             document.getElementById(id).checked = true;
    //             var spaceObject = {
    //               name: element.name,
    //               id: element.id
    //             }
    //             spaceList.push(spaceObject);
    //           }
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       // toast.error('Properties not found!', { position: toast.POSITION.BOTTOM_RIGHT })
    //     });
    // }
  }
  onToggle() {
    this.setState((state) => ({
      showResults: !state.showResults,
    }));
  }

  handleCheckChange(event) {
    // if (
    //   event.target.checked &&
    //   !spaceList.some((x) => x === event.target.title)
    // ) {
    //   var spaceObject = {
    //     name: event.target.title,
    //   };
    //   spaceList.push(spaceObject);
    // } else {
    //   spaceList.splice(spaceList.indexOf(event.target.title), 1);
    // }

    // console.log(spaceList);

    if (event.target.checked && !spaceList.some((x) => x === event.target.title))
    {
      var spaceObject = {
        name: event.target.title,
      };
      spaceList.push(spaceObject);
    } 
    else {
      spaceList.splice(spaceList.indexOf(event.target.title), 1);
    }

    console.log(spaceList);
  }

  submit = (e) => {
    var spaceListObj = {
      spaceParamList: spaceList,
    };
    console.log("submit", spaceListObj);
    var url = 'properties/' + this.state.propertyId + '/spaces';
    apiService.update(url, spaceListObj)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Updated successfull!',
            { position: toast.POSITION.BOTTOM_RIGHT })
          localStorage.setItem("propDetails", JSON.stringify(response.data));
          this.setState({
            property_id: response.data.id,
            // redirect: "/propertiesmanagement/guestrequirements",
            user_id: response.data.user.id
          });
        }
      })
      .catch((error) => {
        toast.error('Updated faild!',
          { position: toast.POSITION.BOTTOM_RIGHT })
      })
    console.log(spaceListObj);
  };
  nextBtnClick = (e) => {
    this.setState({
      //redirect: "/propertiesmanagement/guestrequirements",
      redirect: "/propertiesmanagement/nameplace",
      property_id: this.state.propertyId,
      user_id: this.state.user_id
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/locationconfirmation",
      user_id: this.state.user_id
    });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header shadow-lg" onClick={this.onToggle}>
                  <h5>What spaces guests can use?</h5>
                </div>
                {this.state.showResults ? (
                  <Fragment>
                    <div className="card-body">
                      {guestSpaceList.map((space) => {
                        return (
                          <div className="form-check">
                            <label>
                              <input
                                className="checkbox_animated"
                                type="checkbox"
                                key={space.val}
                                id={space.val}
                                value={space.val}
                                title={space.name}
                                onChange={this.handleCheckChange}
                              />
                              <span className="label-text">{space.name} </span>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    {/* <div className="card-footer">
                      <div className="row">
                        <div className="col-sm-2">
                        <button
                          onClick={this.submit}
                          className="btn btn-success"
                        >
                          {this.props != null ? "Update" : "Save"}
                        </button>
                        </div>
                        <div className="col-sm-2">
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                        </div>
                      </div>
                      
                    </div> */}
                    <div className="card-footer">
                      <div className="form-group row text-center">
                        <div className="col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-warning mr-1"
                            onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                          >
                            Back
                        </button>
                        &nbsp;
                        <button
                            onClick={this.submit}
                            className="btn btn-success"
                          >
                            {this.props != null ? "Update" : "Save"}
                          </button>
                        &nbsp;
                        <button
                            type="submit"
                            className="btn btn-primary mr-1"
                            onClick={this.nextBtnClick}
                          >
                            Next
                        </button>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Spaces;
