import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
import {  Redirect } from "react-router-dom";

class AdventureWillDo extends Component {
    state = {
        about: "",
        adventure_id: this.props.location.state
            ? this.props.location.state.adventure_id
            : null,
        user_id: this.props.location.state
            ? this.props.location.state.user_id
            : null,
        callFrom: this.props.location.state
            ? this.props.location.state.callFrom
            : null,
        hostId: this.props.location.state ? this.props.location.state.hostId : null,
    };
    componentDidMount() {
        if (this.state.adventure_id !== null) {
            this.getEditData();
        }
    }
    getEditData() {
        let _this = this;
        var resp = JSON.parse(localStorage.getItem("adventureDetails"));
        console.log("resp", resp);
        if (resp["aboutYouWill"] !== null) {
            // this.state.about = resp["aboutYourSelf"];
            _this.setState({
                about: resp["aboutYouWill"]
            })
        }

        // console.log("saved ans", this.state.savedAnswers);
    }
    onChangeAbout = (e) => {
        let targetValue = e.target.value;
        this.setState({
            about: targetValue,
        });
        console.log("about", this.state.about);
    };
    nextBtnClick = (e) => {
        this.setState({
            adventure_id: this.state.adventure_id,
            redirect: "/adventuremanagement/willbe",
            user_id: this.state.user_id,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let params = {};
        let _this = this;
        let adventure_id = this.state.adventure_id;

        let url = "adventure/" + adventure_id + "/about-you-will/";
        params["about"] = this.state.about;


        console.log("param", params);
        apiService
            .update_method(params, url)
            .then((response) => {
                console.log("response", response);
                if (response && response.status === 200) {
                    toast.success("Updated Successfully.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    localStorage.setItem(
                        "adventureDetails",
                        JSON.stringify(response.data)
                    );
                    this.setState({
                        adventure_id: response.data.id,
                        // redirect: "/experiencemanagement/ideatheme",
                        user_id: response.data.host.id,
                    });
                } else {
                    toast.error(response.error_description, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
            .catch((error) => {
                // loginState.closeLoader();
                toast.error("Exception Occured!!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                // console.log(error);
            });
    };

    render() {
        if (this.state.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: this.state.redirect,
                        state: {
                            adventure_id: this.state.adventure_id,
                            user_id: this.state.user_id,
                            callFrom: this.state.callFrom,
                        },
                    }}
                />
            );
        }
        const { about } = this.state;
        return (
            <Fragment>
                <Breadcrumb parent="Adventure Management / Skills" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Describe what you’ll do</h5>
                                </div>
                                <div className="card-body">
                                    <form className="form theme-form" id="aboutyouForm">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">
                                                Describe your experience from beginning to end, in the order you’ll
                                                do the activites
                      </label>
                                            <div className="col-sm-8">
                                                <textarea
                                                    className="form-control"
                                                    rows="5"
                                                    cols="5"
                                                    placeholder="About"
                                                    value={this.state.about}
                                                    onChange={this.onChangeAbout}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <h6 className="col-sm-6 col-form-label">
                                                <b>Keep in mind:</b>{" "}
                                                <small>
                                                    Guests are looking for expert in their field.{" "}
                                                    <a href="#">Learn more</a>
                                                </small>
                                            </h6>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-footer">
                                    <div className="form-group row text-center">
                                        <div className="col-sm-12">
                                            <button
                                                type="submit"
                                                className="btn btn-warning mr-1"
                                            // onClick={() => {
                                            //   this.setState({
                                            //     redirect: "/propertiesmanagement/addproperties",
                                            //   });
                                            // }}
                                            >
                                                Back
                      </button>
                      &nbsp;
                      <button
                                                type="submit"
                                                className="btn btn-success mr-1"
                                                onClick={this.handleSubmit}
                                            >
                                                Update
                      </button>
                      &nbsp;
                      <button
                                                type="submit"
                                                className="btn btn-primary mr-1"
                                                onClick={this.nextBtnClick}
                                            >
                                                Next
                      </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default AdventureWillDo;
