import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
// import apiService from "../../apiservices/apiservice";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";

class SpecialDiscount extends Component {
  state = {
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
  };
  handleSubmit = (e) => {
    e.preventDefault();
    toast.error("API not developed for this", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };
  nextBtnClick = (e) => {
    this.setState({
      redirect: "/propertiesmanagement/lengthofstay",
      property_id: this.state.property_id,
      user_id: this.state.user_id,
    });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Guest Stay" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Something special for your first guests</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <div className="col-sm-12 col-xl-6">
                        <div className="card card-absolute">
                          <div className="card-header bg-success">
                            <label className="d-block" htmlFor="edo-ani">
                              <input
                                className="radio_animated"
                                id="edo-ani"
                                type="radio"
                                name="rdo-ani"
                                value={1}
                                defaultChecked
                              />
                              <h5> Offer 20% off to your first guests</h5>
                              <span className="txt-info">recomended</span>
                            </label>
                          </div>
                          <div className="card-body mt-5">
                            <p>
                              The first 3 guests who book your place will get
                              20% off their stay. This special offer can attract
                              new guests, and help you get the 3 reviews you
                              need for a star rating.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-xl-6">
                        <div className="card card-absolute">
                          <div className="card-header bg-warning">
                            <label className="d-block" htmlFor="edo-ani1">
                              <input
                                className="radio_animated"
                                id="edo-ani1"
                                type="radio"
                                name="rdo-ani"
                                value={2}
                              />
                              <h5> Don’t add a special offer</h5>
                            </label>
                          </div>
                          <div className="card-body mt-5">
                            <p>
                              Once you publish your listing, you won’t be able
                              to add this offer.
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Fragment>
    );
  }
}

export default SpecialDiscount;
