import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';

class BackButton extends Component {
  handleBackClick = () => {
    const { onClick, history, to, state } = this.props;
  
    if (onClick) {
  
    //   console.log(onClick())
      onClick();
    } else if (to) {
      console.log('to',to)
      history.push({
        pathname: to,
      });
    } else {
      history.goBack(); // Go back to the previous page
    }
  };

  render() {
    return (
      <Button variant="outline-primary" size="sm" onClick={this.handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft} /> Back
      </Button>
    );
  }
}

export default withRouter(BackButton);
