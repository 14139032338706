import { LOGIN, SET_PROFILE } from "../../constant/actionTypes";
import {saveToken } from "../../services/storage.service";


export const setLoginToken = (token) => {
    //debugger;
    //saveToken(token);
	let obj = {
		token: token,
    };
    // return (dispatch) => {
	// 		dispatch({ type: LOGIN, payload: obj });
	// 	};
    
    return (dispatch) => {
			dispatch({ type: LOGIN, payload: obj });
		};
    
};

export const setProfile = (profile) => {
	return (dispatch) => {
		dispatch({ type: SET_PROFILE, payload: profile });
	};
};
