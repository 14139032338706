import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { $managementAxios } from "../../../lib/unility/http-service";

class OneTimePassword extends Component {
	state = {
		oneTimePassword: '',
		options: [
			{ name: "Utility" },
			{ name: "Generate OTP" }
		]
	};
	componentDidMount() {
		setInterval(() => {
		}, 30);
	}
	handleSubmit = (e) => {
		e.preventDefault();
		e.persist();
		const phone = e.target.phone.value;
		if (phone && phone.length === 11) {
			const requestBody =JSON.stringify({	phone: phone });	
			$managementAxios.post("/utility/get-otp", requestBody).then((res) => {
				if (res.data && res.data.code > 0) {
					this.setState({ oneTimePassword: res.data.code });
				}
			});
		}
	};

	copyToClipboard = (e) => {
		e.preventDefault();
		e.persist();
		const otp = this.state.oneTimePassword;
		navigator.clipboard.writeText(otp);
	};

	render() {
		return (
			<Fragment>
				<Breadcrumb options={this.state.options} />
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-sm-12'>
							<div className='card'>
								<div className='card-body'>
									<div className='row'>
										<div className='col-md-6'>
											<form className='row' onSubmit={this.handleSubmit}>
												<div className='form-group col-12'>
													<label htmlFor='phone'>Phone Number</label>
													<input
														type='tel'
														id='phone'
														name='phone'
														pattern='^(((\+|00)?880)|0)(\d){10}$'
														className='form-control'
														placeholder='017XXXXXXXX'
														maxLength={11}
														minLength={11}
													/>
												</div>
												<div className='form-group col-12 d-flex flex-row-reverse'>
													<button type='submit' className='btn btn-primary '>
														Get OTP
													</button>
													<button
														type='button'
														onClick={this.copyToClipboard}
														className='btn btn-secoundary mr-1 px-3'
														data-toggle='tooltip'
														data-placement='top'
														title='Tooltip on top'
													>
														<i className='fa fa-copy'></i>
													</button>
													<input
														type='number'
														value={this.state.oneTimePassword}
														id='onetimepassword'
														name='onetimepassword'
														disabled
														className='form-control mr-1'
														placeholder='- - - - - -'
													/>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default OneTimePassword;
