import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import apiService from "../../apiservices/apiservice";

class AddExperienceUserList extends Component {
    state = {
        user_list: [],
        isDataLoaded: false,
        loading: false,
      };

      componentDidMount() {
        this.getApplicationUserList();
      }

      getApplicationUserList() {
        let _this = this;
        apiService
        .getUserList(1)
          .then((response) => {
            if (response && response.status === 200) {
              //console.log(response);
              this.setState({ user_list: response.data ,isDataLoaded: true,});

              console.log(_this.state.user_list);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      render(){
        return (
            <Fragment>
              <Breadcrumb parent="Experience Management / Add Experience" />
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h5>Add New Experience</h5>
                      </div>
                      <div className="card-body">
                          <div className="form-group row">
                              <p>To add a new experience please select an User for whom you want to add new experience</p>
                          </div>
                        <div className="form-group row datatable-react">
                          <div className="col-sm-12">
                            {this.state.isDataLoaded ? (
                              <Datatable
                                multiSelectOption={false}
                                myData={this.state.user_list}
                                pageSize={5}
                                pagination={true}
                                class="-striped -highlight"
                                callFrom="AddExperienceForUserList"
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
      }
  
};

export default AddExperienceUserList;
