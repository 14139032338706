import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import apiService from "../../apiservices/apiservice";
import Datatable from "../common/datatable";
// import data from "../../data/dummyTableData";
import jsPDF from "jspdf";
import "jspdf-autotable";
import FullPageLoader from "../common/FullPageLoader/fullpageloader";
import { Redirect } from "react-router-dom";

class AdventureList extends Component {
  state = {
    adventure_list: [],
    isDataLoaded: false,
    loading: false,
  };
  showLoader() {
    this.setState({ loading: true });
  }
  closeLoader() {
    this.setState({ loading: false });
  }
  componentDidMount() {
    this.getExperienceList();
  }
  exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Experience Management Report";
    const headers = [
      [
        "ID",
        "Name",
        "Host Name",
        "Title",
        "Create Date",
        "Last Modified",
        "Verified",
      ],
    ];

    const data2 = this.state.adventure_list.map((elt) => [
      elt.id,
      elt.name,
      elt.hostname,
      elt.title,
      elt.createdAt,
      elt.lastModifiedAt,
      elt.approvalFlag,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data2,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("experience_management_report.pdf");
  };
  getExperienceList() {
    let _this = this;
    _this.showLoader();
    let url = "adventure?sortBy=id:desc";
    apiService
      .get_api(url)
      .then((response) => {
        _this.closeLoader();
        if (response && response.status === 200) {
          //console.log(response);
          let dataList = response.data;
          let dataPush = [];
          if (dataList.length > 0) {
            for (let key in dataList) {
              // console.log("data",dataList[key]);

              var vName;
              if (dataList[key]["name"] !== null) {
                vName = dataList[key]["name"];
              } else {
                vName = "";
              }

              var vHostName; // = dataList[key]["user"]["usr"];
              if (dataList[key]["host"] !== null) {
                vHostName = dataList[key]["host"]["usr"];
              } else {
                vHostName = "";
              }

              var vTitle;
              if (dataList[key]["title"] !== null) {
                vTitle = dataList[key]["title"];
              } else {
                vTitle = "";
              }

              var vCity;
              if (dataList[key]["city"] !== null) {
                vCity = dataList[key]["city"]["name"];
              } else {
                vCity = "";
              }

              var vApprovalFlag;
              if (dataList[key]["approvalFlag"] !== null) {
                vApprovalFlag = dataList[key]["approvalFlag"];
              } else {
                vApprovalFlag = "";
              }
              var vHostId = dataList[key]["host"]["id"];
              var vCreatedAt;
              if (dataList[key]["createdAt"] !== null) {
                vCreatedAt = new Date(dataList[key]["createdAt"]);
              } else {
                vCreatedAt = "";
              }
              var vLastModify;
              if (dataList[key]["lastModifiedAt"] !== null) {
                vLastModify = new Date(dataList[key]["lastModifiedAt"]);
              } else {
                vLastModify = "";
              }

              dataPush.push({
                id: dataList[key]["id"],
                name: vName,
                hostname: vHostName,
                hostId: vHostId,
                title: vTitle,
                city: vCity,
                createdAt: vCreatedAt,
                lastModifiedAt: vLastModify,
                approvalFlag: vApprovalFlag,
              });
            }
          }
          _this.setState({
            adventure_list: dataPush,
            isDataLoaded: true,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
          }}
        />
      );

      // return <AddAdminUser cutomprop='1'/>
    }
    return (
      <Fragment>
        <Breadcrumb parent="Experience Management / Experience List" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-3">
                      <h5>Adventure Management</h5>
                    </div>
                    <div className="col-sm-9 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={() => {
                          this.setState({
                            redirect: "/adventuremanagement/adventureuserlist",
                          });
                        }}
                      >
                        Add Adventure
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <div className="col-sm-12 text-right">
                      <div
                        className="btn-group btn-group-square"
                        role="group"
                        aria-label="Basic example"
                      >
                        <button
                          className="btn btn-outline-success"
                          type="button"
                          disabled={true}
                        >
                          <i className="icofont icofont-document-folder"></i>
                        </button>
                        <button
                          className="btn btn-outline-success"
                          type="button"
                          onClick={() => this.exportPDF()}
                        >
                          <i className="icofont icofont-file-pdf"></i>&nbsp;Pdf
                        </button>
                        <button
                          className="btn btn-outline-success"
                          type="button"
                        >
                          <i className="icofont icofont-file-excel"></i>
                          &nbsp;Excel
                        </button>
                        <button
                          className="btn btn-outline-success"
                          type="button"
                        >
                          <i className="icofont icofont-file-code"></i>&nbsp;CSV
                        </button>
                      </div>
                    </div>
                  </div>
                  {this.state.loading ? (
                    <FullPageLoader loading={this.state.loading} />
                  ) : null}
                  <div className="form-group row datatable-react">
                    <div className="col-sm-12">
                      {this.state.isDataLoaded ? (
                        <Datatable
                          multiSelectOption={false}
                          myData={this.state.adventure_list}
                          pageSize={5}
                          pagination={true}
                          class="-striped -highlight"
                          callFrom="AdventureList"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default AdventureList;
