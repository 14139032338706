import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
import { ToastContainer, toast } from "react-toastify";
import {  Redirect } from "react-router-dom";
import FullPageLoader from "../common/FullPageLoader/fullpageloader";

var currentState = "";
class GuestRequirements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minimumAge: 18,
      accessibilityFeatures: true,
      peopleExpecet: "",
      skillLevel: "",
      AdditionalRecquirements: "",
      experience_id: this.props.location.state
        ? this.props.location.state.experience_id
        : null,
      user_id: this.props.location.state
        ? this.props.location.state.user_id
        : null,
      callFrom: this.props.location.state
        ? this.props.location.state.callFrom
        : null,
      hostId: this.props.location.state
        ? this.props.location.state.hostId
        : null,
    };
  }

  componentDidMount() {
    currentState = this;
    if (this.state.experience_id !== null) {
      this.getEditData();
    }
  }

  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("experienceDetails"));
    console.log("resp", resp);
    if (resp["activityLevelExpectation"] !== null) {
      // this.state.about = resp["aboutYourSelf"];
      _this.setState({
        peopleExpecet: resp["activityLevelExpectation"],
      });
    }
    if (resp["skillRequired"] !== null) {
      // this.state.about = resp["aboutYourSelf"];
      _this.setState({
        skillLevel: resp["skillRequired"],
      });
    }
    if (resp["additionalRequirement"] !== null) {
      // this.state.about = resp["aboutYourSelf"];
      _this.setState({
        AdditionalRecquirements: resp["additionalRequirement"],
      });
    }
    if (resp["haveAccessibilityFeature"] !== null) {
      // this.state.about = resp["aboutYourSelf"];
      _this.setState({
        accessibilityFeatures: resp["haveAccessibilityFeature"],
      });
    }
    if (resp["minAge"] !== null) {
      // this.state.about = resp["aboutYourSelf"];
      _this.setState({
        minimumAge: resp["minAge"],
      });
    }

    // console.log("saved ans", this.state.savedAnswers);
  }

  onChangeMinimumAge = (e) => {
    this.setState({
      minimumAge: e.target.value,
    });
  };

  handleAccessibilityFeaturesChange(e) {
    console.log("change", e.target.value);
    if (e.target.value === "false") {
      currentState.setState({
        accessibilityFeatures: false,
      });
    } else {
      currentState.setState({
        accessibilityFeatures: true,
      });
    }
  }

  handleExpectChange(e) {
    currentState.setState({
      peopleExpecet: e.target.value,
    });
  }
  handleSkillLevelChange(e) {
    currentState.setState({
      skillLevel: e.target.value,
    });
  }

  onChangeAdditionalRecquirements = (e) => {
    let targetValue = e.target.value;
    this.setState({
      AdditionalRecquirements: targetValue,
    });
    // console.log("about", this.state.about);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};

    let experience_id = this.state.experience_id;
    let url = "experience/" + experience_id + "/guest-requirements/";

    params["activityLevelExpectation"] = this.state.peopleExpecet;
    params["additionalRequirement"] = this.state.AdditionalRecquirements;
    params["haveAccessibilityFeature"] = this.state.accessibilityFeatures;
    params["minAge"] = this.state.minimumAge;
    params["skillRequired"] = this.state.skillLevel;

    console.log("params", params);

    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem(
            "experienceDetails",
            JSON.stringify(response.data)
          );
          this.setState({
            experience_id: response.data.id,
            // redirect: "/experiencemanagement/ideatheme",
            user_id: response.data.host.id,
          });
          console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };
  nextBtnClick = (e) => {
    this.setState({
      experience_id: this.state.experience_id,
      redirect: "/experiencemanagement/groupsize",
      user_id: this.state.user_id,
    });
  };

  render() {
    // const { cities } = this.state;
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              experience_id: this.state.experience_id,
              user_id: this.state.user_id,
              hostId: this.state.hostId,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    return (
      <Fragment>
        <Breadcrumb parent="Experience Management / Guest Requirements" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Who can attend your experience?</h5>
                </div>
                <div className="card-body">
                  <form className="form theme-form" id="locationForm">
                    <div className="form-group row">
                      <label
                        htmlFor="minimumAge"
                        className="col-sm-3 col-form-label"
                      >
                        Minimum age
                      </label>
                      <div className="col-sm-9">
                        <select
                          value={this.state.minimumAge}
                          className="form-control digits"
                          id="minimumAge"
                          onChange={this.onChangeMinimumAge}
                        >
                          <option value="">Please select minimum age</option>
                          <option value="18">18</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="accessibilityFeatures"
                        className="col-sm-3 col-form-label"
                      >
                        Does your experience have any accessibility features?
                      </label>
                      <div className="col-sm-9">
                        <div className="form-group m-t-15 custom-radio-ml">
                          <div className="radio radio-primary">
                            <input
                              id="radio11"
                              type="radio"
                              name="accessibilityFeatures"
                              value={false}
                              checked={
                                this.state.accessibilityFeatures ? false : true
                              }
                              onChange={this.handleAccessibilityFeaturesChange}
                            />
                            <label htmlFor="radio11">Not right now</label>
                          </div>
                          <div className="radio radio-secondary">
                            <input
                              id="radio22"
                              type="radio"
                              name="accessibilityFeatures"
                              value={true}
                              checked={
                                this.state.accessibilityFeatures ? true : false
                              }
                              onChange={this.handleAccessibilityFeaturesChange}
                            />
                            <label htmlFor="radio22">Yes, it does</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="peopleExpecet"
                        className="col-sm-3 col-form-label"
                      >
                        What activity level should people expect?
                      </label>
                      <div className="col-sm-9">
                        <div className="form-group m-t-15 custom-radio-ml">
                          <div className="radio radio-primary">
                            <input
                              id="peopleExpecetradio11"
                              type="radio"
                              name="peopleExpecet"
                              value={"Light"}
                              checked={
                                this.state.peopleExpecet === "Light"
                                  ? true
                                  : false
                              }
                              onChange={this.handleExpectChange}
                            />
                            <label htmlFor="peopleExpecetradio11">Light</label>
                          </div>
                          <div className="radio radio-secondary">
                            <input
                              id="peopleExpecetradio22"
                              type="radio"
                              name="peopleExpecet"
                              value={"Moderate"}
                              checked={
                                this.state.peopleExpecet === "Moderate"
                                  ? true
                                  : false
                              }
                              onChange={this.handleExpectChange}
                            />
                            <label htmlFor="peopleExpecetradio22">
                              Moderate
                            </label>
                          </div>
                          <div className="radio radio-secondary">
                            <input
                              id="peopleExpecetradio33"
                              type="radio"
                              name="peopleExpecet"
                              value={"Strenuous"}
                              checked={
                                this.state.peopleExpecet === "Strenuous"
                                  ? true
                                  : false
                              }
                              onChange={this.handleExpectChange}
                            />
                            <label htmlFor="peopleExpecetradio33">
                              Strenuous
                            </label>
                          </div>
                          <div className="radio radio-secondary">
                            <input
                              id="peopleExpecetradio44"
                              type="radio"
                              name="peopleExpecet"
                              value={"Extreme"}
                              checked={
                                this.state.peopleExpecet === "Extreme"
                                  ? true
                                  : false
                              }
                              onChange={this.handleExpectChange}
                            />
                            <label htmlFor="peopleExpecetradio44">
                              Extreme
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="skillLevel"
                        className="col-sm-3 col-form-label"
                      >
                        What skill level is recquired?
                      </label>
                      <div className="col-sm-9">
                        <div className="form-group m-t-15 custom-radio-ml">
                          <div className="radio radio-primary">
                            <input
                              id="skillLevelradio11"
                              type="radio"
                              name="skillLevel"
                              value={"Beginner"}
                              checked={
                                this.state.skillLevel === "Beginner"
                                  ? true
                                  : false
                              }
                              onChange={this.handleSkillLevelChange}
                            />
                            <label htmlFor="skillLevelradio11">Beginner</label>
                          </div>
                          <div className="radio radio-secondary">
                            <input
                              id="skillLevelradio22"
                              type="radio"
                              name="skillLevel"
                              value={"Intermediate"}
                              checked={
                                this.state.skillLevel === "Intermediate"
                                  ? true
                                  : false
                              }
                              onChange={this.handleSkillLevelChange}
                            />
                            <label htmlFor="skillLevelradio22">
                              Intermediate
                            </label>
                          </div>
                          <div className="radio radio-secondary">
                            <input
                              id="skillLevelradio33"
                              type="radio"
                              name="skillLevel"
                              value={"Advanced"}
                              checked={
                                this.state.skillLevel === "Advanced"
                                  ? true
                                  : false
                              }
                              onChange={this.handleSkillLevelChange}
                            />
                            <label htmlFor="skillLevelradio33">Advanced</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Additional requirements (optional))
                      </label>
                      <div className="col-sm-9">
                        <textarea
                          className="form-control"
                          rows="5"
                          cols="5"
                          placeholder="About"
                          value={this.state.AdditionalRecquirements}
                          onChange={this.onChangeAdditionalRecquirements}
                        ></textarea>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="btn btn-warning mr-1"
                        // onClick={() => {
                        //   this.setState({
                        //     redirect: "/propertiesmanagement/addproperties",
                        //   });
                        // }}
                      >
                        Back
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        onClick={this.handleSubmit}
                      >
                        Update
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={this.nextBtnClick}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {this.state.loading ? (
            <FullPageLoader loading={this.state.loading} />
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default GuestRequirements;
