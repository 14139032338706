import { LOGIN, SET_PROFILE } from "../../constant/actionTypes";
import { getUser, saveUser, saveToken } from "../../services/storage.service";

const INIT_STATE = {
	profile: {},
	token: null,
	login: false,
};
//saveUser(INIT_STATE);
const authReducer = (state = INIT_STATE, action) => {
	//debugger;
	state = getUser();
	switch (action.type) {
		case SET_PROFILE:
            saveUser(action.payload);
            saveToken(action.payload.token);
			var _state= {
				...state,profile: action.payload.profile,
					token: action.payload.token
            };
            return _state
		default:
			return state;
	}
};

export default authReducer;
