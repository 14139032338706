import React, { Component, Fragment } from "react";
import apiService from "../../apiservices/apiservice";
import Breadcrumb from "../common/breadcrumb";
// import FullPageLoader from "../common/FullPageLoader/fullpageloader";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

class GuestRequirements extends Component {
  state = {
    isAdditionalRequirements: false,
    govtIssueIdCardRequire: false, 
    recommendByOtherWithNonNegativeReviewRequire: false,
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
  };
  componentDidMount() {
    if (this.state.property_id !== undefined) {
      this.getEditData();
    }
  }
  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("propDetails"));
    // console.log("propD", propDetails);

    if (resp["govtIssueIdCardRequire"]) {
      this.setState({
        govtIssueIdCardRequire: resp["govtIssueIdCardRequire"],
      });
    }

    if (resp["recommendByOtherWithNonNegativeReviewRequire"]) {
      this.setState({
        recommendByOtherWithNonNegativeReviewRequire:
          resp["recommendByOtherWithNonNegativeReviewRequire"],
      });
    }
    if (
      resp["govtIssueIdCardRequire"] ||
      resp["recommendByOtherWithNonNegativeReviewRequire"]
    ) {
      this.setState({
        isAdditionalRequirements: true,
      });
    }
  }
  isShowAdditionalRequirements = () => {
    this.setState({
      isAdditionalRequirements: true,
    });
  };

  handleChange(e) {
    let isChecked = e.target.checked;
    // do whatever you want with isChecked value
    if (isChecked) {
      this.setState({
        govtIssueIdCardRequire: true,
      });
    } else {
      this.setState({
        govtIssueIdCardRequire: false,
      });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let property_id = this.state.property_id;
    let _this = this;
    let url = "properties/" + property_id + "/guest-requirements";
    let params = {};

    params["govtIssueIdCardRequire"] = this.state.govtIssueIdCardRequire;

    params["recommendByOtherWithNonNegativeReviewRequire"] = this.state.recommendByOtherWithNonNegativeReviewRequire;

    apiService
        .update_method(params, url)
        .then((response) => {
          console.log("response", response);
          if (response && response.status === 200) {
            toast.success("Updated Successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            localStorage.setItem("propDetails",JSON.stringify(response.data));
            this.setState({
              property_id:response.data.id,
              // redirect: "/propertiesmanagement/houserules",
              user_id:response.data.user.id
            });
            // console.log(response);
          } else {
            toast.error(response.error_description, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((error) => {
          // loginState.closeLoader();
          toast.error("Exception Occured!!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // console.log(error);
        });
  };
  nextBtnClick = (e) => {
    this.setState({
      redirect: "/propertiesmanagement/houserules",
      property_id:this.state.property_id,
      user_id: this.state.user_id
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/nameplace",
      user_id: this.state.user_id
    });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom : this.state.callFrom,
            },
          }}
        />
      );
    }
    const {
      recommendByOtherWithNonNegativeReviewRequire,
      govtIssueIdCardRequire,
      loading,
    } = this.state;
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Guest Requirements" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>What hurraayy requires from every guests</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <div className="row">
                          <label
                            htmlFor="place_type_id"
                            className="col-sm-12 col-form-label"
                          >
                            To make everyone's life easier and safer all guests
                            will provide:
                          </label>
                          <div className="col-sm-12">
                            <label className="d-block" htmlFor="chk-ani">
                              <input
                                className="checkbox_animated"
                                id="chk-ani"
                                type="checkbox"
                                defaultChecked
                              />
                              Email address
                            </label>
                            <label className="d-block" htmlFor="chk-ani1">
                              <input
                                className="checkbox_animated"
                                id="chk-ani1"
                                type="checkbox"
                                defaultChecked
                              />
                              Confirmed phone number
                            </label>
                            <label className="d-block" htmlFor="chk-ani2">
                              <input
                                className="checkbox_animated"
                                id="chk-ani2"
                                type="checkbox"
                                defaultChecked
                              />
                              Payment information
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="row">
                          <label
                            htmlFor="place_type_id"
                            className="col-sm-12 col-form-label"
                          >
                            Prior to booking each guest will have to:
                          </label>
                          <div className="col-sm-12">
                            <label className="d-block" htmlFor="chk-ani">
                              <input
                                className="checkbox_animated"
                                id="chk-ani"
                                type="checkbox"
                                defaultChecked
                              />
                              Agree to your House Rules
                            </label>
                            <label className="d-block" htmlFor="chk-ani1">
                              <input
                                className="checkbox_animated"
                                id="chk-ani1"
                                type="checkbox"
                                defaultChecked
                              />
                              Message you about their trip
                            </label>
                            <label className="d-block" htmlFor="chk-ani2">
                              <input
                                className="checkbox_animated"
                                id="chk-ani2"
                                type="checkbox"
                                defaultChecked
                              />
                              Let you know how many guests are coming
                            </label>
                            <label className="d-block" htmlFor="chk-ani2">
                              <input
                                className="checkbox_animated"
                                id="chk-ani2"
                                type="checkbox"
                                defaultChecked
                              />
                              Confirm their check-in time if they’re arriving
                              within 2 days
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-3">
                        <button
                          className="btn btn-outline-warning"
                          type="button"
                          onClick={() => this.isShowAdditionalRequirements()}
                        >
                          <i className="fa fa-plus"></i>
                          &nbsp; Add additional requirements
                        </button>
                      </div>
                    </div>
                    {this.state.isAdditionalRequirements ? (
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <label
                            className="d-block"
                            htmlFor="govtIssueIdCardRequire"
                          >
                            <input
                              className="checkbox_animated"
                              id="govtIssueIdCardRequire"
                              type="checkbox"
                              checked={
                                this.state.govtIssueIdCardRequire === true
                                  ? true
                                  : false
                              }
                              onChange={(e) => this.handleChange(e)}
                              // defaultChecked
                            />
                            Government-issued ID submitted to Hurraayy
                          </label>
                          <label>
                            Too many requirements can lead to fewer bookings!
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default GuestRequirements;
