import React, { Component,Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import apiService from "../../../apiservices/apiservice";
import { ToastContainer, toast } from "react-toastify";
import FullPageLoader from "../../../components/common/FullPageLoader/fullpageloader";
import { Button } from 'reactstrap';

var currentState = "";
const testArray = [];
class AddRole extends Component{

    constructor(props) {
        super(props);
        this.state = {
          resourceList: [],
          loading: false,
          buttonNameChanged:false
        }
        currentState = this;
    }

    showLoader(){
        this.setState({loading:true});
    }

    closeLoader(){
        this.setState({loading:false});
    }

    componentDidMount() {
        apiService.getResources()
        .then((response) => {
            //this.hideLoader();
            if (response && response.status === 200) {
                toast.success(response.data.length+" Resource found", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                console.log(response);
                this.setState({
                    resourceList: response.data
                });
            }
        })
        .catch((error) => {
            //this.hideLoader();
            toast.error("Exception Occured!!", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            console.log(error);
        });
        
    }

    resetForm = () => { 
        document.getElementById("roleCreateForm").reset();
        this.setState({buttonNameChanged:false});
    }

    handleCheckChange(e) {
        //e.preventDefault();
        let isChecked = e.target.checked;
        if(isChecked){
            var checkValue = e.target.value;
            const obj = {'checkedValue': checkValue}
            var isExist = currentState.CheckValueExist(checkValue);
            if(!isExist){
                testArray.push(obj);
            }
            else{
                toast.error("Item Already Selected!!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
            
            console.log(testArray);   
        }  
        else{
            var uncheckValue = e.target.value;
            var index = currentState.findSelectedIndex(uncheckValue);
            testArray.splice(index, 1);
        }
    }

    findSelectedIndex(pTcValue) {
        if (testArray.length > 0) {
            for (var i = 0; i < testArray.length; i += 1) {
                if (testArray[i]["checkedValue"] === pTcValue) {
                    return i;
                }

            }
        }
        return -1;
    }

    CheckValueExist(value){
        var isExist = false;
        for(var i = 0; i < testArray.length; i++){
            if(value === testArray[i].checkedValue){
                isExist = true;
                break;
            }
            else{
                isExist = false;
                continue;
            }
        }
        return isExist;
    }

    handleSubmit(e){
        e.preventDefault();
        currentState.showLoader();
        // let roleId_value = document.getElementById("roleID").value;
        let roleName_value = document.getElementById("roleName").value;
        let description_value = document.getElementById("description").value; 
        let resourceIds_value = this.state.checkedValues;
        
        const data={
            name : roleName_value,
            description: description_value,
            resourceIds:resourceIds_value
        }
        console.log(JSON.stringify(data));

        // if(roleId_value !== ""){
        //     apiService
        //     .updateUser(data, userId_value)
        //     .then((response) => {
        //         currentState.closeLoader();
        //         if (response && response.status === 200) {
        //             toast.success("Information Updated Successfully", {
        //                 position: toast.POSITION.BOTTOM_RIGHT,
        //             });
        //             console.log(response);
        //         }
        //     })
        //     .catch((error) => {
        //         currentState.closeLoader();
        //         toast.error("Exception Occured!!", {
        //             position: toast.POSITION.BOTTOM_RIGHT,
        //         });
        //         console.log(error);
        //     });
        // }
        // else{
        //     apiService
        //     .createUser(data)
        //     .then((response) => {
        //         currentState.closeLoader();
        //         if (response && response.status === 201) {
        //             document.getElementById("userId").value = response.data.id;
        //             this.setState({buttonNameChanged:true});
        //             toast.success("User Created Successfully", {
        //                 position: toast.POSITION.BOTTOM_RIGHT,
        //             });
        //             console.log(response);
        //         }
        //     })
        //     .catch((error) => {
        //         currentState.closeLoader();
        //         toast.error("Exception Occured!!", {
        //             position: toast.POSITION.BOTTOM_RIGHT,
        //         });
        //         console.log(error);
        //     });
        // }

        
    };

    render() {
        return(
            <Fragment>
                <Breadcrumb parent="Security / Add Role"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Add New Role</h5>
                                </div>
                                <form className="form theme-form"
                                    onSubmit={this.handleSubmit}
                                    id="roleCreateForm">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label">Role ID</label>
                                                    <div className="col-sm-9 input-group">
                                                        <input 
                                                            className="form-control input-air-primary"
                                                            type="text"
                                                            id="roleID"
                                                            placeholder="Auto Generate"
                                                            // onChange={this.userDetails}
                                                            // readOnly
                                                            
                                                        />
                                                        <div className="input-group-prepend" onClick={this.userDetails}>
                                                                <Button className="input-group-text" id="inputGroupPrepend">
                                                                    <i className = "fa fa-search"></i>
                                                                </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label">Role Name</label>
                                                    <div className="col-sm-9">
                                                        <input 
                                                            className="form-control"
                                                            type="text"
                                                            id="roleName"
                                                            placeholder="Enter Role Name Here"
                                                            required/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label">Description</label>
                                                    <div className="col-sm-9">
                                                        <textarea 
                                                            className="form-control"
                                                            rows="5"
                                                            cols="5"
                                                            id="description"
                                                            placeholder="Enter Description Here"></textarea>
                                                    </div>
                                                </div>
                                                <ToastContainer />
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label">Permissions</label>
                                                    <div className="col-sm-9">
                                                        <div className="row">
                                                            {this.state.resourceList.map((resource) =>{
                                                                return(
                                                                    <label>
                                                                        <input className="checkbox_animated"
                                                                            type="checkbox"
                                                                            key={resource.id}
                                                                            id={resource.id} 
                                                                            value={resource.id}
                                                                            title={resource.name}
                                                                            onChange={this.handleCheckChange}
                                                                        /> 
                                                                        <span className="label-text">{resource.name} </span>
                                                                    </label>
                                                                    
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    </div>
                                <div className="card-footer">
                                    <div className="col-sm-9 offset-sm-3">
                                        <button 
                                            type="submit"
                                            className={this.state.buttonNameChanged ? 'btn btn-success mr-1' : 'btn btn-primary mr-1'}
                                        >
                                          {this.state.buttonNameChanged? 'Update':'Submit' }
                                        </button>
                                        <input className="btn btn-light" type="reset" defaultValue="Cancel" onClick={this.resetForm} />
                                    </div>
                                </div>
                                {this.state.loading ? <FullPageLoader loading={this.state.loading}/> : null}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
    
};
export default AddRole;