import React, { Fragment, useEffect,useState } from "react";
import Countup from "react-countup";
import moment from "moment";
import {
	getStatByDateRange,
	getBookingTrendByDateRange,
	getUserTrendByDateRange,
	getRetentionRate,
} from "../../../services/dashboar.service";

const BudgetComponent = () => {

    const [retention, setRetention] = useState({
        retentionRate: 0,
        startCount: 0,
        endCount: 0,
        diffrence: 0,
    });
    const [selectedValue, setSelectedValue] = useState("500000");

    useEffect(() => {
        getStats(selectedValue);
    }, []);

    const getStats = async () => {
        var endDate = moment(new Date()).format("yyyy-MM-DD");
        var startDate = moment(endDate).subtract(selectedValue, "days").format("yyyy-MM-DD");
        getRetentionRate(startDate, endDate).then((res) => {
            if (res.status === 200) {
                setRetention({
                    retentionRate: res.data.rate,
                    startCount: res.data.startCount,
                    endCount: res.data.endCount,
                    diffrence: res.data.diffrence,
                });
            }
            else { 
                setRetention({
                    retentionRate: 0,
                    startCount: 0,
                    endCount: 0,
                    diffrence: 0,
                });
            }
        });
    };

    const onChangeGetStats = (e) => {
        setSelectedValue(e.target.value);
        var endDate = moment(new Date()).format("yyyy-MM-DD");
        var startDate = moment(endDate).subtract(selectedValue, "days").format("yyyy-MM-DD");
        getRetentionRate(startDate, endDate).then((res) => {
            if (res.status === 200) {
                setRetention({
                    retentionRate: res.data.rate,
                    startCount: res.data.startCount,
                    endCount: res.data.endCount,
                    diffrence: res.data.diffrence,
                });
            }
            else { 
                setRetention({
                    retentionRate: 0,
                    startCount: 0,
                    endCount: 0,
                    diffrence: 0,
                });
            }
        });
    }
	return (
		<Fragment>
			<div className='tab-content' id='tab-2'>
				<div className='d-flex justify-content-end'>
					<div className='select2-drpdwn-project select-options pb-3'>
						<select
							className='form-control form-control-primary btn-square'
							name='select'
							onChange={(e) => {
								onChangeGetStats(e);
							}}
						>
							<option value='500000' selected>
								All
							</option>
							<option value='7'>Last 7 days</option>
							<option value='30'>Last 30 days</option>
							<option value='365'>Last 365 days</option>
						</select>
					</div>
				</div>
				<div className='row'>
					<div className='col-xl-3 col-sm-6'>
						<div className='card'>
							<div className='card-body p-3'>
								<div className='media'>
									<h5 className='mb-0'>Retantion rate</h5>
								</div>
								<div className='project-widgets text-center'>
									<h1 className='font-primary counter'>
                                        <Countup end={retention.retentionRate} />
                                        <span>%</span>
									</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default BudgetComponent;
