import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { ToastContainer, toast } from "react-toastify";
import apiService from "../../apiservices/apiservice";
import { Redirect } from "react-router-dom";

class GuestBooks extends Component {
  state = {
    property_id: this.props.location.state.property_id,
    user_id: this.props.location.state.user_id,
    callFrom: this.props.location.callFrom,
    id: 0,
    noticeBeforeGuestArrive: "",
    checkInFrom: "",
    checkInTo: "",
    timeGuestCanBook: "",
    howFarAdvanceGuestCanBook: "-1",
    manuallyReviewAndApproveRequests: true,
    maxStayNight: "",
    minStayNight: "",
  };
  componentDidMount() {
    console.log("guestbook propid", this.state.property_id);
    if (this.state.property_id !== undefined) {
      this.getEditData();
    }
    // this.getEditData();
  }
  getEditData() {
    let _this = this;
    var resp = JSON.parse(localStorage.getItem("propDetails"));
    let data = resp["propertyAvailabilitySetting"];
    if(data !== null){
        if (data["howFarAdvanceGuestCanBook"]) {
            this.setState({
              howFarAdvanceGuestCanBook: data["howFarAdvanceGuestCanBook"],
            });
          }
          this.setState({
            noticeBeforeGuestArrive: data["noticeBeforeGuestArrive"],
          });
          this.setState({
            timeGuestCanBook: data["timeGuestCanBook"],
          });
          this.setState({
            checkInFrom: data["checkInFrom"],
          });
          this.setState({
            checkInTo: data["checkInTo"],
          });
          this.setState({
            id: data["id"],
          });
    }
        
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let property_id = this.state.property_id;
    let _this = this;
    let url = "properties/" + property_id + "/availability-settings";
    let params = {};
        const {noticeBeforeGuestArrive, checkInFrom, checkInTo, timeGuestCanBook, id} = this.state;
        params["noticeBeforeGuestArrive"] = noticeBeforeGuestArrive;
        params["checkInFrom"] = checkInFrom;
        params["checkInTo"] = checkInTo;
        params["timeGuestCanBook"] = timeGuestCanBook;
        params["howFarAdvanceGuestCanBook"] =this.state.howFarAdvanceGuestCanBook;
        params["manuallyReviewAndApproveRequests"] = "";
        params["maxStayNight"] = "";
        params["minStayNight"] = "";
        params["id"] = id;

    console.log("param", params);
    apiService
      .update_method(params, url)
      .then((response) => {
        console.log("response", response);
        if (response && response.status === 200) {
          toast.success("Updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem("propDetails", JSON.stringify(response.data));
          this.setState({
            property_id: response.data.id,
            // redirect: "/propertiesmanagement/propertylist",
            user_id: response.data.user.id,
          });
          // console.log(response);
        } else {
          toast.error(response.error_description, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        // loginState.closeLoader();
        toast.error("Exception Occured!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // console.log(error);
      });
  };

  nextBtnClick = (e) => {
    this.setState({
        redirect: "/propertiesmanagement/gueststay",
        property_id:this.state.property_id,
      user_id: this.state.user_id
    });
  };
  backBtnClick = (e)=>{
    this.setState({
      property_id:this.state.property_id,
      redirect: "/propertiesmanagement/guestnotification",
      user_id: this.state.user_id
    });
  }
  onChangeHowFar = (e) => {
    this.setState({
      howFarAdvanceGuestCanBook: e.target.value,
    });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: {
              property_id: this.state.property_id,
              user_id: this.state.user_id,
              callFrom: this.state.callFrom,
            },
          }}
        />
      );
    }
    const { availability_a1, availability_a2, loading } = this.state;
    return (
      <Fragment>
        <Breadcrumb parent="Property Management / Add Calendar" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>How far in advance can guests book?</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group row">
                      {/* <label
                            htmlFor="place_type_id"
                            className="col-sm-3 col-form-label"
                          >
                            Have you rented out your place before?
                          </label> */}
                      <div className="col-sm-12">
                        <select
                          value={this.state.howFarAdvanceGuestCanBook}
                          className="form-control digits"
                          id="place_type_id"
                          onChange={this.onChangeHowFar}
                          
                        >
                          <option value="-1">Any time</option>
                          <option value="90">3 months in advance</option>
                          <option value="180">6 months in advance</option>
                          <option value="270">9 months in advance</option>
                          <option value="365">1 year</option>
                          <option value="0">
                            Dates unavailable by default
                          </option>
                        </select>
                      </div>
                      <p>
                        <span className="text-primary">Tip:</span> You can allow
                        to book 6 months in advance.
                      </p>
                    </div>
                    
                  </form>
                </div>
                <div className="card-footer">
                  <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <button
                          type="submit"
                          className="btn btn-warning mr-1"
                          onClick={this.backBtnClick}
                          // onClick={() => {
                          //   this.setState({
                          //     redirect: "/propertiesmanagement/addproperties",
                          //   });
                          // }}
                        >
                          Back
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-success mr-1"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          onClick={this.nextBtnClick}
                        >
                          Next
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer/>
      </Fragment>
    );
  }
}

export default GuestBooks;
