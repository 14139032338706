import React, { Component } from 'react'
// import {BoxLoading} from 'react-loadingg';
import { css } from "@emotion/core";
// import ClipLoader from "react-spinners/ClipLoader";
import HashLoader from "react-spinners/HashLoader"
// import logo from "../../../assets/images/hurraayyspinner.gif";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class FullPageLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: this.props.loading
        };
    }
    render(){
        return(
            <div className="fp-container">
                <div className="fp-loader">
                    <div className="loader-box">
                            {/* <div className="loader">
                                <div className="line"></div>
                                <div className="line"></div>
                                <div className="line"></div>
                                <div className="line"></div>
                            </div> */}
                            {/* <BoxLoading/> */}
                            {/* <ClipLoader
                                css={override}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}  
                            /> */}
                            <HashLoader
                                css={override}
                                size={50}
                                color={"#FFA200"}
                                loading={this.state.loading}  
                            />
                            {/* <img src={logo} alt="loading..."/> */}
                    </div>
                </div>
            </div>
        );
    }
};

export default FullPageLoader;